import axios from 'axios'


const state = {
	termBanks: [],
	termBank: {
		"terms": []
	},
	termBankRes: {},
	formattedTerms: [],
	numberOfTerms: 0
}

const getters = {
	stateTermBanks: state => state.termBanks,
	stateTermBank: state => state.termBank,
	stateTermBankRes: state => state.termBankRes,
	stateFormattedTerms: state => state.formattedTerms,
	stateNumberOfTerms: state => state.numberOfTerms
}

const actions = {
	async createTermBank({dispatch, commit}, payload) {
		const termBank = {"name": payload.name}
		const terms = payload.terms
		let {data} = await axios.post('/termbanks/', termBank)
		commit('setTermBankRes', data)
		const termbankId = data.id
		let termList = new Array()
		for (const term of terms) {
			const termItem = {
				"term": term.term,
				"familiarity": term.familiarity,
				"rank": term.rank,
				"ratio": term.ratio,
				"termbank_id": termbankId
			}
			termList.push(termItem)
		}
		await dispatch('addTerms', termList)
		await dispatch('getTermBanks')
	},
	async getTermBanks({commit}) {
		let {data} = await axios.get('/termbanks/')
		commit('setTermBanks', data)
	},
	async getTermBank({commit}, id) {
		let {data} = await axios.get(`/termbanks/${id}`)
		commit('setTermBank', data)
	},
	async updateTermBankName({dispatch}, {id, name}) {
		const payload = {"name": name}
		await axios.put(`/termbanks/${id}`, payload)
		await dispatch('getTermBanks')
	},
	async formatTerms({commit}, terms) {
		const payload = {"terms": terms}
		let {data} = await axios.post('/format_terms/', payload)
		commit('setFormattedTerms', data)
	},
	async addTerms({commit}, payload) {
		let {data} = await axios.post('/add_terms/', payload)
		commit('setNumberOfTerms', data.length)
	},
	async getLISTerms() {
		let {data} = await axios.get('/lis_terms/')
		return data
	},
	async getExpTerms() {
		let {data} = await axios.get('/experiment_terms/')
		return data
	},
	async deleteTermBank({dispatch}, id) {
		await axios.delete(`/termbanks/${id}`)
		await dispatch('getTermBanks')
	},
	clearTermBanks({commit}) {
		commit('setTermBanks', [])
	},
	clearTermBank({commit}) {
		commit('setTermBank', {})
	},
	clearFormattedTerms({commit}) {
		commit('setFormattedTerms', [])
		commit('setNumberOfTerms', 0)
	}
}

const mutations = {
	setTermBanks(state, termBanks) {
		state.termBanks = termBanks
	},
	setTermBank(state, termBank) {
		state.termBank = termBank
	},
	setTermBankRes(state, res) {
		state.termBankRes = res
	},
	setFormattedTerms(state, formattedTerms) {
		state.formattedTerms = formattedTerms
	},
	setNumberOfTerms(state, number) {
		state.numberOfTerms = number
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
