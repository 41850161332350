<template>
	<div>
		<v-alert
			:value="alert"
			dense
			outlined
			text
			prominent
			type="error"
			transition="scale-transition"
		>
			エラーが発生しました。ログアウト後再度ログインしてください。
		</v-alert>
		<v-data-table
			:headers="headers"
			:items="users"
			:search="search"
			:items-per-page="10"
			:sort-by.sync="columnName"
			:sort-desc.sync="isDescending"
			:footer-props="{
				showFirstLastPage: true
			}"
			:loading="loading"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-container>
						<v-row>
							<v-spacer></v-spacer>
							<v-btn
								class="mr-5"
								@click="download"
								:disabled="loading || !users.length"
							>
								管理者をダウンロード
							</v-btn>
							<AdminNewAdmin/>
						</v-row>
					</v-container>
				</v-toolbar>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
			</template>
			<template v-slot:item.password="{ item }">
				<AdminPasswordField :item="item" />
			</template>
			<template v-slot:item.created_at="{ item }">
				{{ formatDate(item.created_at) }}
			</template>
			<template v-slot:item.memo="props">
				<v-edit-dialog
					large
					save-text="保存"
					cancel-text="キャンセル"
					@open="updateMemoOpen(props.item.memo)"
					@cancel="setAlert(false)"
					@save="updateMemo(props.item.id, editedMemo)"
				>
					<div>{{ props.item.memo }}</div>
					<template v-slot:input>
						<v-form ref="updateMemoForm">
							<v-text-field
								v-model="editedMemo"
								:rules="[rules.required, rules.max100chars]"
								label="Edit"
								single-line
								counter
								maxlength="100"
							></v-text-field>
						</v-form>
					</template>
				</v-edit-dialog>
			</template>
			<template v-slot:item.is_active="{ item }">
				<v-simple-checkbox
					v-model="item.is_active"
					v-ripple
					:disabled="loading"
					@input="updateActive(item)"
				></v-simple-checkbox>
			</template>
			<template v-slot:item.delete="{ item }">
				<AdminDeleteButton :deleteFunction="deleteUser" :item="item" :isAll="false" :isCritical="true" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminNewAdmin from '@/components/AdminNewAdmin'
import AdminDeleteButton from '@/components/AdminDeleteButton'
import AdminPasswordField from '@/components/AdminPasswordField'

export default {
	name: 'AdminAdmins',
	components: {
		AdminNewAdmin,
		AdminDeleteButton,
		AdminPasswordField
	},
	data () {
		return {
			columnName: 'id',
			isDescending: false,
			search: '',
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Name', value: 'username' },
				{ text: 'Password', value: 'password' },
				{ text: 'Memo', value: 'memo' },
				{ text: 'Created at', value: 'created_at' },
				{ text: 'Active', value: 'is_active' },
				{ text: 'Delete', value: 'delete' }
			],
			rules: {
				required: value => !!value || 'Required',
				max100chars: value => value.length <= 100 || 'Max 100 characters'
			},
			editedMemo: ''
		}
	},
	async created () {
		if (this.isAdminAuthenticated) {
			await this.getPlainAdmins()
		}
	},
	computed: {
		...mapGetters({
			users: 'statePlainAdmins',
			alert: 'stateAlert',
			loading: 'stateLoading',
			isAdminAuthenticated: 'isAdminAuthenticated'
		})
	},
	watch: {
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 1000)
			}
		}
	},
	methods: {
		...mapActions(['setLoading', 'setAlert', 'getPlainAdmins', 'updateUserActive', 'downloadUsers', 'deleteUser', 'checkAdmin', 'adminLogout', 'updateUserMemo']),
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		updateMemoOpen(memo) {
			this.ensureAdmin()
			this.setAlert(false)
			if (memo) {
				this.editedMemo = memo
			} else {
				this.editedMemo = ''
			}
		},
		async updateMemo(id, memo) {
			if (this.$refs.updateMemoForm.validate()) {
				this.setLoading(true)
				const payload = {
					"id": id,
					"memo": memo
				}
				await this.updateUserMemo(payload).then(() => {
					this.setLoading(false)
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
			} else {
				this.setAlert(true)
			}
		},
		async updateActive(item) {
			const payload = {
				"id": item.id,
				"is_active": item.is_active
			}
			this.setLoading(true)
			await this.updateUserActive(payload).then(() => {
				this.setLoading(false)
				this.setAlert(false)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		async download() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}

			if (this.isAdminAuthenticated) {
				this.setLoading(true)
				await this.downloadUsers({"role": 2, "usergroup_id": 0}).then((response) => {
					let blob = new Blob([response.data], { type: 'text/tsv' })
					let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
					let url = window.URL.createObjectURL(blob)
					let dllink = document.createElement('a')
					dllink.href = url
					dllink.setAttribute("download", filename)
					dllink.click()
					this.setLoading(false)
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
			}
		}
	}
}
</script>