<template>
	<div>
		<v-text-field
			v-model="search"
			append-icon="mdi-magnify"
			label="Search"
			single-line
			hide-details
		>
		</v-text-field>
		<v-data-table
			:headers="headers"
			:items="terms"
			:search="search"
			:items-per-page="10"
			:sort-by.sync="columnName"
			:sort-desc.sync="isDescending"
			:footer-props="{
				showFirstLastPage: true
			}"
			:loading="loading"
		>
			<template v-slot:item.term="{ item }">
				<AdminTermAnswers :id="item.id" :term="item.term" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import AdminTermAnswers from '@/components/AdminTermAnswers'
import { mapGetters } from 'vuex'

export default {
	name: 'TermsTable',
	props: ['terms'],
	components: {
		AdminTermAnswers
	},
	data () {
		return {
			columnName: 'rank',
			isDescending: false,
			search: '',
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Term', value: 'term' },
				{ text: 'Familiarity', value: 'familiarity' },
				{ text: 'Rank', value: 'rank' },
			],
		}
	},
	computed: {
		...mapGetters({ loading: 'stateLoading' }),
	},
}
</script>