import axios from 'axios'


const state = {
	motSurveyAnswered: [],
	motSurveyAnswers: []
}

const getters = {
	stateMotSurveyAnswered: state => state.motSurveyAnswered,
	stateMotSurveyAnswers: state => state.motSurveyAnswers
}

const actions = {
	async postMotSurveyAnswer({commit}, payload) {
		await axios.post('/stumotsurveyanswers/', payload)
		commit('appendMotSurveyAnswered', payload.test_id)
	},
	async getMotSurveyAnswers({commit}) {
		let {data} = await axios.get('/stumotsurveyanswers/')
		commit('setMotSurveyAnswers', data)
	},
	async deleteMotSurveyAnswer({dispatch}, id) {
		await axios.delete(`/stumotsurveyanswers/${id}`)
		await dispatch('getMotSurveyAnswers')
	},
	async downloadMotSurveyAnswers() {
		return await axios.get('/stumotsurveyanswers/download/', { responseType: 'blob' })
	},
	clearMotSurveyAnswered({commit}) {
		commit('setMotSurveyAnswered', [])
	},
	clearMotSurveyAnswers({commit}) {
		commit('setMotSurveyAnswers', [])
	}
}

const mutations = {
	setMotSurveyAnswers(state, survey) {
		state.motSurveyAnswers = survey
	},
	setMotSurveyAnswered(state, survey) {
		state.motSurveyAnswered = survey
	},
	appendMotSurveyAnswered(state, testId) {
		state.motSurveyAnswered.push(testId)
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
