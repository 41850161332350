import axios from 'axios'

const state = {
	publicSurveyAnswers: []
}

const getters = {
	statePublicSurveyAnswers: state => state.publicSurveyAnswers
}

const actions = {
	async getPublicSurveyAnswers({commit}, payload) {
		let {data} = await axios.get('/publicsurveyanswers/', payload)
		commit('setPublicSurveyAnswers', data)
	},
	async deletePublicSurveyAnswer({dispatch}, id) {
		await axios.delete(`/publicsurveyanswers/${id}`)
		await dispatch('getPublicSurveyAnswers')
	},
	async downloadPublicSurveyAnswers(_, payload) {
		if (!payload.dateFrom && !payload.dateTo) {
			return await axios.get('/publicsurveyanswers/download/', { responseType: 'blob' })
		}
		if (!payload.dateFrom) {
			return await axios.get(`/publicsurveyanswers/download/?date_to=${payload.dateTo}`, { responseType: 'blob' })
		}
		if (!payload.dateTo) {
			return await axios.get(`/publicsurveyanswers/download/?date_from=${payload.dateFrom}`, { responseType: 'blob' })
		}
		if (payload.dateFrom && payload.dateTo) {
			return await axios.get(`/publicsurveyanswers/download/?date_from=${payload.dateFrom}&date_to=${payload.dateTo}`, { responseType: 'blob' })
		}
	},
	clearPublicSurveyAnswers({commit}) {
		commit('setPublicSurveyAnswers', [])
	}
}

const mutations = {
	setPublicSurveyAnswers(state, items) {
		state.publicSurveyAnswers = items
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}