import axios from 'axios'


const state = {
	tests: [],
	publishedTests: [],
	groupTests: [],
	test: {
		"sets": []
	},
	testRes: {},
	testSetAnswered: []
}

const getters = {
	stateTests: state => state.tests,
	statePublishedTests: state => state.publishedTests,
	stateGroupTests: state => state.groupTests,
	stateTest: state => state.test,
	stateTestRes: state => state.testRes,
	stateTestSetAnswered: state => state.testSetAnswered
}

const actions = {
	async createTest({dispatch, commit}, test) {
		let {data} = await axios.post('/tests/', test)
		commit('setTestRes', data)
		await dispatch('getTests')
	},
	async getTests({commit}) {
		let {data} = await axios.get('/tests/')
		commit('setTests', data)
	},
	async getGroupTests({commit}, id) {
		let {data} = await axios.get(`/tests/?usergroup_id=${id}`)
		commit('setGroupTests', data)
	},
	async getPublishedTests({commit}) {
		let {data} = await axios.get('/tests/published/')
		commit('setPublishedTests', data)
	},
	async getTest({commit}, id) {
		let {data} = await axios.get(`/tests/${id}`)
		commit('setTest', data)
	},
	async getPublishedTest({commit}, id) {
		let {data} = await axios.get(`/tests/published/${id}`)
		commit('setTest', data)
	},
	async updateTestName({dispatch}, {id, name}) {
		const payload = {"name": name}
		await axios.put(`/tests/${id}/name`, payload)
		await dispatch('getTests')
	},
	async updateTestPublished({dispatch}, {id, is_published}) {
		const payload = {"is_published": is_published}
		await axios.put(`/tests/${id}/published`, payload)
		await dispatch('getTests')
	},
	async deleteTest({dispatch}, id) {
		await axios.delete(`/tests/${id}`)
		await dispatch('getTests')
	},
	appendTestSetAnswered({commit}, setId) {
		commit('appendTestSetAnswered', setId)
	},
	clearTestSetAnswered({commit}) {
		commit('setTestSetAnswered', [])
	},
	clearTests({commit}) {
		commit('setTests', [])
	},
	clearTest({commit}) {
		const test = {
			"sets": []
		}
		commit('setTest', test)
	},
	clearPublishedTests({commit}) {
		commit('setPublishedTests', [])
	},
	clearGroupTests({commit}) {
		commit('setGroupTests', [])
	}
}

const mutations = {
	setTests(state, tests) {
		state.tests = tests
	},
	setPublishedTests(state, tests) {
		state.publishedTests = tests
	},
	setGroupTests(state, tests) {
		state.groupTests = tests
	},
	setTest(state, test) {
		state.test = test
	},
	setTestRes(state, test) {
		state.testRes = test
	},
	appendTestSetAnswered(state, set_id) {
		state.testSetAnswered.push(set_id)
	},
	setTestSetAnswered(state, sets) {
		state.testSetAnswered = sets
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}
