<template>
	<v-dialog
		v-model="dialog"
		width="600"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="primary"
				:dark="isDark"
				:light="isDisabled"
				class="mb-2"
				v-bind="attrs"
				v-on="on"
				:disabled="isDisabled"
				@click.stop="ensureAdmin"
			>
				新しいターム集
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="text-h5">新しいターム集</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="newTermBankForm">
					<v-container>
						<v-row><v-col>
							<v-text-field
								v-model="editedTermBank.name"
								label="ターム集の名前"
								:rules="[rules.required, rules.max20chars]"
								counter
								maxlength="20"
							></v-text-field>
						</v-col></v-row>
						<v-row><v-col>
							<v-textarea
								v-model="editedTermBank.terms"
								label="ターム（形式: term<TAB>familiarity<TAB>rank<TAB>ratio<CR>）"
								:rules="[rules.required]"
								:error-messages="errors"
								@keydown.tab="forbidTab($event)"
								@keyup.tab="replaceSpacing()"
							></v-textarea>
						</v-col></v-row>
						<v-row>
							<v-spacer></v-spacer>
							<v-col>
								<v-btn @click="loadExpTerms">
									実証実験用語彙をロード
								</v-btn>
							</v-col>
							<v-col>
								<v-btn @click="loadLISTerms">
									図書館情報学重要語彙をロード
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="close"
				>
					キャンセル
				</v-btn>
				<v-btn
					color="blue darken-1"
					text
					:loading="loading"
					@click="save"
				>
					保存
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>{{ termBankRes.name }}</v-card-title>
				<v-card-text>
					{{ numberOfTerms }}個のタームを登録しました。
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'AdminNewTermBank',
	data () {
		return {
			dialog: false,
			resDialog: false,
			newTermBank: {
				name: '',
				terms: ''
			},
			editedTermBank: {
				name: '',
				terms: ''
			},
			rules: {
				required: value => !!value || 'Required',
				max20chars: value => value.length <= 20 || 'Max 20 characters'
			},
			errors: []
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			loading: 'stateLoading',
			termBankRes: 'stateTermBankRes',
			formattedTerms: 'stateFormattedTerms',
			numberOfTerms: 'stateNumberOfTerms',
			isAdminAuthenticated: 'isAdminAuthenticated'
		}),
		isDark() {
			if (!this.loading && this.admin) {
				return true
			} else {
				return false
			}
		},
		isDisabled() {
			if (this.loading || !this.admin) {
				return true
			} else {
				return false
			}
		}
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.$refs.newTermBankForm.resetValidation()
				this.$nextTick(() => {
					this.editedTermBank = Object.assign({}, this.newTermBank)
				})
				this.setLoading(false)
			}
			if (value === true) {
				this.setAlert(false)
				this.errors = []
			}
		},
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 1000)
			}
		}
	},
	methods: {
		...mapActions(['createTermBank', 'formatTerms', 'clearFormattedTerms', 'setLoading', 'setAlert', 'getLISTerms', 'getExpTerms', 'checkAdmin', 'adminLogout']),
		close() {
			this.dialog = false
			this.setLoading(false)
		},
		async save() {
			if (this.$refs.newTermBankForm.validate()) {
				this.setLoading(true)
				await this.formatTerms(this.editedTermBank.terms).then(() => {
					this.errors = []
				}).catch(err => {
					this.setLoading(false)
					this.errors = ['Wrong input format']
					this.clearFormattedTerms()
					console.error(err)
				})
				this.setLoading(false)
				if (this.formattedTerms.length) {
					this.setLoading(true)
					const payload = {
						"name": this.editedTermBank.name,
						"terms": this.formattedTerms
					}
					await this.createTermBank(payload).then(() => {
						this.setAlert(false)
					}).catch(err => {
						this.setLoading(false)
						this.setAlert(true)
						console.error(err)
					})
					this.setLoading(false)
					this.close()
					this.resDialog = true
				}
			}
		},
		async loadLISTerms() {
			let terms = await this.getLISTerms()
			this.editedTermBank.name = '図書館情報学基本語彙'
			this.editedTermBank.terms = terms.join('\n')
		},
		async loadExpTerms() {
			let terms = await this.getExpTerms()
			this.editedTermBank.name = '実証実験用語彙'
			this.editedTermBank.terms = terms.join('\n')
		},
		closeResDialog() {
			this.resDialog = false
			this.clearFormattedTerms()
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		forbidTab(e) {
			e.preventDefault()
		},
		replaceSpacing() {
			this.editedTermBank.terms += "\t"
		}
	}
}
</script>