const state = {
	loading: false,
	alert: false,
}

const getters = {
	stateLoading: state => state.loading,
	stateAlert: state => state.alert,
}

const actions = {
	setLoading({commit}, payload) {
		commit('setLoading', payload)
	},
	setAlert({commit}, payload) {
		commit('setAlert', payload)
	}
}

const mutations = {
	setLoading(state, payload) {
		state.loading = payload
	},
	setAlert(state, payload) {
		state.alert = payload
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}