<template>
	<v-dialog
		v-model="dialog"
		width="600"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="primary"
				:dark="!loading"
				:light="loading"
				class="mb-2"
				v-bind="attrs"
				v-on="on"
				:disabled="loading"
				@click.stop="ensureAdmins"
			>
				新しいユーザーグループ
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="text-h5">新しいユーザーグループ</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="newUserGroupForm">
					<v-container>
						<v-row><v-col>
							<v-text-field
								v-model="editedUserGroup.name"
								label="ユーザーグループ名（必須）"
								:rules="[rules.required, rules.max20chars]"
								:error-messages="grouperrors"
								:error-count="1"
								counter
								maxlength="20"
							></v-text-field>
						</v-col></v-row>
						<v-row><v-col>
							<v-select
								v-model="editedUserGroup.tests"
								:items="tests"
								item-text="name"
								item-value="id"
								label="テスト"
								chips
								multiple
							></v-select>
						</v-col></v-row>
						<v-row><v-col>
							<v-textarea
								v-model="editedUserGroup.users"
								label="ユーザー（形式: username[<TAB>password]<CR>）"
								:error-messages="errors"
								:error-count="1"
								@keydown.tab="forbidTab($event)"
								@keyup.tab="replaceSpacing()"
							></v-textarea>
						</v-col></v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="close"
				>
					キャンセル
				</v-btn>
				<v-btn
					color="blue darken-1"
					text
					:loading="loading"
					@click="save"
				>
					保存
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>新しいユーザーグループ</v-card-title>
				<v-card-text>
					{{ userGroupRes.name }} を作成しました。<br/>
					{{ numberOfUsers }}名のユーザーを追加しました。<br/><br/>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminNewUserGroup',
	data () {
		return {
			dialog: false,
			resDialog: false,
			newUserGroup: {
				name: '',
				tests: [],
				users: ''
			},
			editedUserGroup: {
				name: '',
				tests: [],
				users: ''
			},
			rules: {
				required: value => !!value || 'Required',
				max20chars: value => value.length <= 20 || 'Max 20 characters'
			},
			errors: [],
			grouperrors: []
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin',
			loading: 'stateLoading',
			formattedUsers: 'stateFormattedUsers',
			users: 'stateUsers',
			numberOfUsers: 'stateNumberOfUsers',
			tests: 'stateTests',
			userGroupRes: 'stateUserGroupRes',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated'
		})
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.$refs.newUserGroupForm.resetValidation()
				this.$nextTick(() => {
					this.editedUserGroup = Object.assign({}, this.newUserGroup)
					this.clearFormattedUsers()
				})
				this.setLoading(false)
			}
			if (value === true) {
				this.setAlert(false)
				this.errors = []
				this.grouperrors = []
			}
		},
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 2000)
			}
		}
	},
	methods: {
		...mapActions(['addUsers', 'setLoading', 'setAlert', 'formatUsers', 'clearFormattedUsers', 'checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout', 'createUserGroup', 'clearUserGroup', 'clearUserGroupRes', 'getUserGroups', 'clearUsersRes', 'clearNumberOfUsers', 'getMyUsers']),
		close() {
			this.dialog = false
			this.setLoading(false)
		},
		async save() {
			if (this.$refs.newUserGroupForm.validate()) {
				if (this.editedUserGroup.users.length == 0)
				{
					this.setLoading(true)
					const payload = {
						"name": this.editedUserGroup.name,
						"tests": this.editedUserGroup.tests
					}
					await this.createUserGroup(payload).then(() => {
						this.grouperrors = []
					}).catch((err) => {
						this.setLoading(false)
						this.grouperros = ['An error occured. Please try again later.']
						this.clearUserGroup()
						console.error(err)
					})

					this.setLoading(false)
					this.close()
					this.resDialog = true

				} else {

					this.setLoading(true)

					const formatPayload = {
						"users": this.editedUserGroup.users,
					}
					await this.formatUsers(formatPayload).then(() => {
						this.errors = []
					}).catch((err) => {
						this.setLoading(false)
						this.errors = ['Wrong input: ' + err.response.data.detail]
						this.clearFormattedUsers()
					})
					this.setLoading(false)
					if (this.formattedUsers.length) {
						this.setLoading(true)

						const groupPayload = {
							"name": this.editedUserGroup.name,
							"tests": this.editedUserGroup.tests
						}
						await this.createUserGroup(groupPayload).then(() => {
							this.grouperrors = []
						}).catch((err) => {
							this.setLoading(false)
							this.grouperros = ['An error occured. Please try again later.']
							console.error(err)
							this.clearUserGroup()
						})

						const payload = {
							"users": this.formattedUsers,
							"usergroup_id": this.userGroupRes.id
						}
						await this.addUsers(payload).then(() => {
							this.setAlert(false)
						}).catch(err => {
							this.setLoading(false)
							this.setAlert(true)
							console.error(err)
						})
						this.setLoading(false)
						this.close()
						this.resDialog = true
						if (this.admin) {
							this.getMyUsers(0)
						} else {
							this.getMyUsers(this.plainadmin.id)
						}
					}
				}
			}
		},
		closeResDialog() {
			this.resDialog = false
			this.clearFormattedUsers()
			this.clearUserGroupRes()
			this.clearUsersRes()
			this.clearNumberOfUsers()
		},
		async ensureAdmins() {
			if (this.admin) {
				this.ensureAdmin()
			} else {
				this.ensurePlainAdmin()
			}
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		async ensurePlainAdmin() {
			if (this.isPlainAdminAuthenticated) {
				await this.checkPlainAdmin().catch(() => {
					this.plainAdminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		forbidTab(e) {
			e.preventDefault()
		},
		replaceSpacing() {
			this.editedUserGroup.users += "\t"
		}
	}
}
</script>