<template>
	<v-container>
		<v-row>
			<v-col>
				<div class="mt-5">
					<h1>
						基本情報
					</h1>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-alert
					:value="alert"
					dense
					outlined
					text
					prominent
					type="error"
					transition="scale-transition"
				>
					回答を記録できませんでした。
				</v-alert>
				<v-form ref="surveyForm">
					<v-container class="mx-auto col-lg-10 col-md-10 col-sm-10 col-xs-10">
						<v-row>
							<v-select
								v-model="form.university"
								label="大学"
								:items="universities"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row class="mot-survey-form">
							<v-select
								v-model="form.grade"
								label="学年"
								:items="grades"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row class="mot-survey-form">
							<v-select
								v-model="form.gender"
								label="性別"
								:items="genders"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row class="mot-survey-form">
							<v-select
								v-model="form.reading"
								label="一ヶ月の読書冊数"
								:items="readings"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row class="mot-survey-form">
							<v-select
								v-model="form.courses"
								label="履修済み/履修中の科目（あてはまるもの全て）"
								:items="curriculum.courses"
								item-text="name"
								item-value="id"
								chips
								multiple
							></v-select>
						</v-row>
						<v-row class="mot-survey-form">
							<v-select
								v-model="form.certificates"
								label="司書以外の資格（あてはまるもの全て）"
								hint="司書以外で、あなたが取得しようとしている資格を教えてください"
								persistent-hint
								:items="certificates"
								item-text="text"
								item-value="value"
								chips
								multiple
							>
								<template v-slot:item="{ item, attrs, on}">
									<v-list-item v-bind="attrs" v-on="on">
										<v-checkbox
											:value="form.certificates.includes(item.value)"
											style="display: inline-block;"
										/>
										<v-list-item-title>
											{{ item.text }}
											<span v-if="item.value == 3" class="note">（学校司書ではありません）</span>
										</v-list-item-title>
									</v-list-item>
								</template>
							</v-select>
						</v-row>
						<v-row class="mot-survey-form-large">
							<v-select
								v-model="form.motivation"
								label="司書資格取得の意欲"
								hint="司書資格取得の意欲について、あなたに最も当てはまるものを教えてください"
								persistent-hint
								:items="motivations"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							>
								<template v-slot:item="{ item, attrs, on }">
									<v-list-item v-bind="attrs" v-on="on">
										<v-list-item-title class="text-wrap">
											{{ item.text }}
										</v-list-item-title>
									</v-list-item>
								</template>
							</v-select>
						</v-row>
						<v-row class="mot-survey-form-large">
							<v-select
								v-model="form.motivation_change"
								label="履修動機の変化"
								hint="司書課程履修開始から現在にかけて、最も強い履修動機が変化したかどうかを教えてください"
								persistent-hint
								:items="motivation_changes"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row class="mot-survey-form-large" v-if="form.motivation_change === 1">
							<v-textarea
								v-model="form.motivation_change_reasons"
								label="履修動機の変化の理由"
								hint="上記質問に「変化した」と答えた方は、その理由を簡単に教えてください"
								persistent-hint
								:rules="rules.max150chars"
								counter
								maxlength="150"
								rows="3"
							></v-textarea>
						</v-row>
						<v-row class="mot-survey-form-large">
							<v-text-field
								v-model="form.identifier"
								label="誕生日4桁+携帯電話番号下4桁"
								hint="あなたの誕生日4桁と携帯電話番号の下4桁をつなげ、半角数字8桁で入力してください"
								persistent-hint
								:rules="[rules.required, rules.identifier, rules.birthday]"
								placeholder="例：07294869"
								maxlength="8"
							></v-text-field>
						</v-row>
						<v-row class="mot-survey-form-large" v-if="form.university == 3 || form.university == 6">
							<v-select
								v-model="form.interview"
								label="インタビュー調査への協力"
								:items="interviews"
								item-text="text"
								item-value="value"
								hint="司書課程の履修動機に関するインタビュー調査に協力していただけるかどうかを教えてください。インタビュー調査は所要時間2時間程度で、謝礼があります"
								:rules="[rules.required]"
								persistent-hint
							></v-select>
						</v-row>
						<v-row class="mot-survey-form-large" v-if="form.interview === 1">
							<v-text-field
								v-model="form.email"
								label="メールアドレス"
								hint="上記質問に「はい」と答えた方は、メールアドレスを教えてください"
								persistent-hint
								:rules="form.interview === 1 ? [rules.required, rules.email] : []"
								placeholder="例：example@termmator.com"
								type="email"
							></v-text-field>
						</v-row>
						<v-row class="mt-15">
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-2"
								dark
								depressed
								x-large
								:loading="loading"
								@click="post"
							>回答する</v-btn>
							<v-spacer></v-spacer>
						</v-row>
					</v-container>
				</v-form>
			</v-col>
		</v-row>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>回答を記録しました</v-card-title>
				<v-card-text>
					<v-alert
						dense
						outlined
						text
						class="mt-10"
						type="success"
					>
						次へ進んでください。
					</v-alert>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 1.2em;
}

.mot-survey-form {
	margin-top: 0.6em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserStudentMotiveSurvey',
	data () {
		return {
			form: {
				university: null,
				grade: null,
				gender: null,
				reading: null,
				certificates: [],
				motivation: null,
				motivation_change: null,
				motivation_change_reasons: '',
				courses: [],
				interview: null,
				email: '',
				identifier: ''
			},
			rules: {
				required: value => !!value || '必須',
				max150chars: value => value.length <= 150 || '最大150字',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || '正しいメールアドレスを入力してください'
				},
				identifier: value => {
					const pattern = /^\d{8}$/
					return pattern.test(value) || '半角数字8桁を入力してください'
				},
				birthday: value => {
					const pattern = /^(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])\d{4}$/
					return pattern.test(value) || '誕生日を正しく入力してください'
				}
			},
			universities: [
				{ text: '上田女子短期大学', value: 1 },
				{ text: "熊本学園大学", value: 2 },
				{ text: "相模女子大学", value: 3 },
				{ text: "実践女子大学短期大学部", value: 4 },
				{ text: "白百合女子大学", value: 5 },
				{ text: "都留文科大学", value: 6 },
				{ text: "東京学芸大学", value: 12 },
				{ text: "東京大学", value: 7 },
				{ text: "南山大学", value: 8 },
				{ text: "文教大学", value: 9 },
				{ text: "明治大学", value: 10 },
				{ text: "早稲田大学", value: 11 },
				{ text: "大阪学院大学", value: 13},
			],
			grades: [
				{ text: '学部1年', value: 1 },
				{ text: '学部2年', value: 2 },
				{ text: '学部3年', value: 3 },
				{ text: '学部4年', value: 4 },
				{ text: '修士1年', value: 5 },
				{ text: '修士2年', value: 6 },
				{ text: '博士1年', value: 7 },
				{ text: '博士2年', value: 8 },
				{ text: '博士3年', value: 9 },
				{ text: 'その他', value: 99 },
			],
			genders: [
				{ text: '男', value: 1 },
				{ text: '女', value: 2 },
				{ text: 'その他', value: 3 },
				{ text: '回答しない', value: 4 },
			],
			readings: [
				{ text: "0冊", value: 1 },
				{ text: "1〜2冊", value: 2 },
				{ text: "3〜5冊", value: 3 },
				{ text: "6冊以上", value: 4 },
			],
			certificates: [
				{ text: "教職", value: 1 },
				{ text: "学芸員", value: 2 },
				{ text: "司書教諭", value: 3 },
				{ text: "社会教育主事", value: 4 },
			],
			motivations: [
				{ text: "他の科目の履修を犠牲にしてでも、司書資格を取得するつもりである", value: 1 },
				{ text: "他に取りたい資格や優先したいことがなければ、司書資格を取得するつもりである", value: 2 },
				{ text: "授業が面白くなければ／課題が大変なら、途中で資格取得はやめるかもしれない", value: 3 },
				{ text: "とりあえず課程登録はしたが、司書資格を取得する気はあまりない", value: 4 },
			],
			motivation_changes: [
				{ text: "変化した", value: 1 },
				{ text: "変化していない", value: 2 },
				{ text: "分からない", value: 3 },
			],
			interviews: [
				{ text: "はい", value: 1 },
				{ text: "いいえ", value: 2 },
			],
			resDialog: false
		}
	},
	async created () {
		this.setAlert(false)
		await this.getPublishedTest(this.testId)
		await this.getCurriculum(this.test.curriculum_id)
	},
	computed: {
		...mapGetters({
			curriculum: 'stateCurriculum',
			userId: 'stateUserId',
			loading: 'stateLoading',
			alert: 'stateAlert',
			test: 'stateTest',
			testAnswer: 'stateTestAnswer',
		}),
		testId() {
			return this.$route.params.id
		}
	},
	methods: {
		...mapActions(['getCurriculum', 'getPublishedTest', 'setLoading', 'setAlert', 'postMotSurveyAnswer']),
		async post() {
			if (this.$refs.surveyForm.validate()) {

				this.setLoading(true)
				if (this.form.interview == null) {
					this.form.interview = 2
				}
				const payload = {
					'university': this.form.university,
					'grade': this.form.grade,
					'gender': this.form.gender,
					'reading': this.form.reading,
					'certificates': this.form.certificates,
					'motivation': this.form.motivation,
					'motivation_change': this.form.motivation_change,
					'motivation_change_reasons': this.form.motivation_change_reasons,
					'courses': this.form.courses,
					'interview': this.form.interview,
					'email': this.form.email,
					'identifier': this.form.identifier,
					'user_id': this.userId,
					'test_id': this.testId,
					'testanswer_id': this.testAnswer.id
				}
				await this.postMotSurveyAnswer(payload).then(() => {
					this.setAlert(false)
					this.resDialog = true
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
				this.setLoading(false)
			}
		},
		closeResDialog() {
			this.resDialog = false
			this.$router.push({ name: 'Test', params: {id: this.testId }})
		}
	}
}
</script>