import vuetify from './plugins/vuetify'
import axios from 'axios'
import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'


const username = process.env.VUE_APP_BASIC_AUTH_USERNAME
const password = process.env.VUE_APP_BASIC_AUTH_PASSWORD

const basic_token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64')

axios.defaults.productionTip = true
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.headers.common['Authorization'] = `Basic ${basic_token}`
axios.defaults.withCredentials = true

Vue.config.productionTip = false


new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
