<template>
	<v-card>
		<v-card-title>{{ title }}</v-card-title>
		<v-card-text>
			<span class="text-h3" v-if="loading">
				<v-progress-circular indeterminate></v-progress-circular>
			</span>
			<span class="text-h3" v-else>{{ items.length }}</span>
		</v-card-text>
		<v-card-actions>
			<span
				class="text-caption text-left"
				v-if="items.length && !loading"
			>
				Updated at<br/>
				{{ getUpdateDate(items) }}
			</span>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				:to="pageLink"
			>
				Go
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'AdminDashboardCard',
	props: ['title', 'items', 'pageLink'],
	computed: {
		...mapGetters({
			loading: 'stateLoading'
		})
	},
	methods: {
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		getUpdateDate(arr) {
			let date = arr.slice().sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())[0].created_at
			return this.formatDate(date)
		}
	}
}
</script>