<template>
	<v-dialog
		v-model="dialog"
		width="800"
		scrollable
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="blue-grey"
				dark
				v-bind="attrs"
				v-on="on"
				@click="clickGetCurriculum(item.id)"
			>{{ item.number_of_courses }}</v-btn>
		</template>
		<v-card>
			<v-card-title>
				{{ item.name }}
				<v-spacer></v-spacer>
				<div v-if="clipboard">
					<v-tooltip
						left
						:color="tooltipColor"
						v-model="tooltipShow"
					>
						<template v-slot:activator="{ attrs }">
							<v-btn
								icon
								color="blue"
								v-bind="attrs"
								@click="copyCourses"
							>
								<v-icon>mdi-content-copy</v-icon>
							</v-btn>
						</template>
						<span>{{ tooltipMessage }}</span>
					</v-tooltip>
				</div>
			</v-card-title>
			<v-card-text>
				<CoursesTable :courses="curriculum.courses"/>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="close"
				>OK</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CoursesTable from '@/components/CoursesTable'

export default {
	name: 'AdminViewCurriculum',
	props: ['item'],
	components: {
		CoursesTable
	},
	data () {
		return {
			dialog: false,
			clipboard: false,
			tooltipShow: false,
			tooltipColor: 'success',
			tooltipMessage: 'Courses copied!',
		}
	},
	created () {
		if (navigator.clipboard) {
			this.clipboard = true
		}
	},
	computed: {
		...mapGetters({ curriculum: 'stateCurriculum' })
	},
	methods: {
		...mapActions(['getCurriculum']),
		close() {
			this.dialog = false
		},
		async clickGetCurriculum(id) {
			await this.getCurriculum(id)
		},
		async copyCourses() {
			let courses_list = []
			for (const course of this.curriculum.courses) {
				courses_list.push(course.name)
			}
			const courses = courses_list.join('\n')
			if (!this.clipboard) {
				return
			} else {
				await navigator.clipboard.writeText(courses).then(() => {
					this.tooltipColor = 'success'
					this.tooltipMessage = 'Courses copied!'
					this.tooltipShow = true
					setTimeout(() => { this.tooltipShow = false }, 1000)
				}).catch(err => {
					this.tooltipColor = 'error'
					this.tooltipMessage = 'Courses not copied'
					this.tooltipShow = true
					setTimeout(() => { this.tooltipShow = false }, 1000)
					console.error(err)
				})
			}
		}
	}
}
</script>