import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import localForage from 'localforage'

import sets from './modules/sets'
import answers from './modules/answers'
import terms from './modules/terms'
import scores from './modules/scores'
import notifications from './modules/notifications'
import admin from './modules/admin'
import plainadmin from './modules/plainadmin'
import termbanks from './modules/termbanks'
import tests from './modules/tests'
import curriculums from './modules/curriculums'
import courses from './modules/courses'
import users from './modules/users'
import usergroups from './modules/usergroups'
import survey from './modules/survey'
import libsurvey from './modules/libsurvey'
import motsurvey from './modules/motsurvey'
import alumnisurvey from './modules/alumnisurvey'
import scales from './modules/scales'
import scaleanswers from './modules/scaleanswers'
import scalescores from './modules/scalescores'
import testanswers from './modules/testanswers'
import publicsurveyanswers from './modules/publicsurveyanswers'
import adminpublicsurveyanswers from './modules/adminpublicsurveyanswers'


Vue.use(Vuex)

const vuexLocalForage = new VuexPersistence({
	storage: localForage,
	asyncStorage: false,
	reducer: state => ({
		sets: state.sets,
		answers: state.answers,
		terms: state.terms,
		scores: state.scores,
		notifications: state.notifications,
		termbanks: state.termbanks,
		tests: state.tests,
		curriculums: state.curriculums,
		courses: state.courses,
		usergroups: state.usergroups,
		survey: state.survey,
		libsurvey: state.libsurvey,
		motsurvey: state.motsurvey,
		alumnisurvey: state.alumnisurvey,
		scales: state.scales,
		scalescores: state.scalescores,
		testanswers: state.testanswers,
		adminpublicsurveyanswers: state.adminpublicsurveyanswers
	})
})

const vuexLocalStorage = new VuexPersistence({
	storage: window.localStorage,
	reducer: state => ({
		users: state.users,
		admin: state.admin,
		plainadmin: state.plainadmin,
		publicsurveyanswers: state.publicsurveyanswers,
		scaleanswers: state.scaleanswers,
	})
})

export default new Vuex.Store({
	modules: {
		sets,
		answers,
		terms,
		scores,
		notifications,
		admin,
		plainadmin,
		termbanks,
		tests,
		curriculums,
		courses,
		users,
		usergroups,
		survey,
		libsurvey,
		motsurvey,
		alumnisurvey,
		scales,
		scaleanswers,
		scalescores,
		testanswers,
		publicsurveyanswers,
		adminpublicsurveyanswers
	},
	plugins: [vuexLocalForage.plugin, vuexLocalStorage.plugin]
})
