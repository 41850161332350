<template>
	<v-col class="mx-auto col-12 col-xl-8 col-lg-10 col-md-8 col-sm-10 col-xs-12">
		<div class="user">
			<router-view></router-view>
		</div>
	</v-col>
</template>

<script>
export default {
	name: 'User',
}
</script>
