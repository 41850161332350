import axios from 'axios'


const state = {
	scores: [],
	score: null
}

const getters = {
	stateScores: state => state.scores,
	stateScore: state => state.score
}

const actions = {
	async createScoreSingleSet(_, payload) {
		let {data} = await axios.post('/scores/', payload)
		return data
	},
	async createScore({commit}, payload) {
		let {data} = await axios.post('/scores/', payload)
		commit('setScore', data)
	},
	async getScores({commit}) {
		let {data} = await axios.get('/scores/')
		commit('setScores', data)
	},
	async deleteScore({dispatch}, id) {
		await axios.delete(`/scores/${id}`)
		await dispatch('getScores')
	},
	clearScores({commit}) {
		commit('setScores', [])
	},
	clearScore({commit}) {
		commit('setScore', null)
	}
}

const mutations = {
	setScores(state, scores) {
		state.scores = scores
	},
	setScore(state, score) {
		state.score = score
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}