import axios from 'axios'

const state = {
	publicSurveyAnswered: false,
	publicSurveyAccessed: false,
	publicSurveyAnsweredId: null,
	Q1: null,
	Q2: [],
	Q3: null,
	Q4: null,
	Q4other: "",
	Q5: [],
	Q5other: "",
	Q6: [],
	Q7: null,
	Q8: null,
	Q9: "",
	Q10: "",
	matrixItemAnswers: [],
	matrixItemNotAnswered: [],
	matrixEmptyItemAnswers: [],
	publicSurveyStartAt: null,
}

const getters = {
	statePublicSurveyAnswered: state => state.publicSurveyAnswered,
	statePublicSurveyAccessed: state => state.publicSurveyAccessed,
	statePublicSurveyAnsweredId: state => state.publicSurveyAnsweredId,
	stateQ1: state => state.Q1,
	stateQ2: state => state.Q2,
	stateQ3: state => state.Q3,
	stateQ4: state => state.Q4,
	stateQ4other: state => state.Q4other,
	stateQ5: state => state.Q5,
	stateQ5other: state => state.Q5other,
	stateQ6: state => state.Q6,
	stateQ7: state => state.Q7,
	stateQ8: state => state.Q8,
	stateQ9: state => state.Q9,
	stateQ10: state => state.Q10,
	stateMatrixItemAnswers: state => state.matrixItemAnswers,
	stateMatrixItemNotAnswered: state => state.matrixItemNotAnswered,
	statePublicSurveyStartAt: state => state.publicSurveyStartAt,
	stateMatrixEmptyItemAnswers: state => state.matrixEmptyItemAnswers,
}

const actions = {
	async postPublicSurveyAnswer({commit}, payload) {
		let {data} = await axios.post('/publicsurveyanswers/', payload)
		commit('setPublicSurveyAnsweredId', data.id)
	},
	async updatePublicSurveyAnswer(_, payload) {
		let comment = {"comment": payload.comment}
		await axios.put(`/publicsurveyanswers/${payload.id}`, comment)
	},
	setPublicSurveyAnswered({commit}, payload) {
		commit('setPublicSurveyAnswered', payload)
	},
	setPublicSurveyAccessed({commit}, payload) {
		commit('setPublicSurveyAccessed', payload)
	},
	setPublicSurveyAnsweredId({commit}, payload) {
		commit('setPublicSurveyAnsweredId', payload)
	},
	clearPublicSurveyAnswers({commit}) {
		commit('setQ1', null)
		commit('setQ2', [])
		commit('setQ3', null)
		commit('setQ4', null)
		commit('setQ4other', "")
		commit('setQ5', [])
		commit('setQ5other', "")
		commit('setQ6', [])
		commit('setQ7', null)
		commit('setQ8', null)
		commit('setQ9', "")
		commit('setQ10', "")
		commit('setPublicSurveyAnswered', false)
		commit('setPublicSurveyStartAt', null)
	},
	setPublicSurveyStartAt({commit}, startAt) {
		commit('setPublicSurveyStartAt', startAt)
	},
	setQ1({commit}, answer) {
		commit('setQ1', answer)
	},
	setQ2({commit}, answer) {
		commit('setQ2', answer)
	},
	setQ3({commit}, answer) {
		commit('setQ3', answer)
	},
	setQ4({commit}, answer) {
		commit('setQ4', answer)
	},
	setQ4other({commit}, answer) {
		commit('setQ4other', answer)
	},
	setQ5({commit}, answer) {
		commit('setQ5', answer)
	},
	setQ5other({commit}, answer) {
		commit('setQ5other', answer)
	},
	setQ6({commit}, answer) {
		commit('setQ6', answer)
	},
	setQ7({commit}, answer) {
		commit('setQ7', answer)
	},
	setQ8({commit}, answer) {
		commit('setQ8', answer)
	},
	setQ9({commit}, answer) {
		commit('setQ9', answer)
	},
	setQ10({commit}, answer) {
		commit('setQ10', answer)
	},
	setPublicSurveyAnswer({commit}, answer) {
		commit('setPublicSurveyAnswer', answer)
	},
	addMatrixItemAnswer({commit}, answer) {
		commit('addMatrixItemAnswer', answer)
	},
	setMatrixItemNotAnswered({commit}, items) {
		commit('setMatrixItemNotAnswered', items)
	},
	setMatrixEmptyItemAnswers({commit}, items) {
		commit('setMatrixEmptyItemAnswers', items)
	},
	addMatrixEmptyItemAnswer({commit}, item) {
		commit('addMatrixEmptyItemAnswer', item)
	},
	deleteMatrixItem({commit}, item) {
		commit('deleteMatrixItem', item)
	},
	clearMatrixItemAnswers({commit}) {
		commit('clearMatrixItemAnswers')
	},
	clearMatrixItemNotAnswered({commit}) {
		commit('clearMatrixItemNotAnswered')
	},
	clearMatrixEmptyItemAnswers({commit}) {
		commit('setMatrixEmptyItemAnswers', [])
	}
}

const mutations = {
	addMatrixItemAnswer(state, answer) {
		let index = state.matrixItemAnswers.findIndex((obj => obj.id === answer.id))
		if (index === -1) {
			let notIndex = state.matrixItemNotAnswered.indexOf(answer.id)
			state.matrixItemAnswers.push(answer)
			state.matrixItemNotAnswered.splice(notIndex, 1)
		} else {
			if ((answer.value_1 == false || answer.value_1 == null) && (answer.value_2 == false || answer.value_2 == null) && (answer.value_3 == false || answer.value_3 == null) && (answer.value_4 == false || answer.value_4 == null) && (answer.value_5 == false || answer.value_5 == null)) {
				state.matrixItemAnswers.splice(index, 1)
				state.matrixItemNotAnswered.push(answer.id)
				state.matrixItemNotAnswered.sort((a, b) => a - b)
			} else {
				state.matrixItemAnswers.splice(index, 1)
				state.matrixItemAnswers.push(answer)
			}
		}
	},
	deleteMatrixItem(state, item) {
		let index = state.matrixItemAnswers.findIndex((obj => obj.id === item.id))
		if (index !== -1) {
			state.matrixItemAnswers.splice(index, 1)
			state.matrixItemAnswers.forEach(element => {
				if (element.id > item.id) {
					element.id = element.id - 1
				}
			})
		}
		index = state.matrixItemNotAnswered.indexOf(item.id)
		if (index !== -1) {
			state.matrixItemNotAnswered.splice(index, 1)
			state.matrixItemNotAnswered.forEach(element => {
				if (element > item.id) {
					let thisIndex = state.matrixItemNotAnswered.indexOf(element)
					state.matrixItemNotAnswered[thisIndex] = element - 1
				}
			})
			state.matrixItemNotAnswered.sort((a, b) => a - b)
		}
		index = state.matrixEmptyItemAnswers.findIndex((obj => obj.id === item.id))
		if (index !== -1) {
			state.matrixEmptyItemAnswers.splice(index, 1)
			state.matrixEmptyItemAnswers.forEach(element => {
				if (element.id > item.id) {
					element.id = element.id - 1
				}
			})
		}
	},
	setMatrixItemNotAnswered(state, items) {
		for (let i = 0; i < items.length; i++) {
			state.matrixItemNotAnswered.push(items[i].id)
		}
	},
	setMatrixEmptyItemAnswers(state, items) {
		state.matrixEmptyItemAnswers = items
	},
	addMatrixEmptyItemAnswer(state, item) {
		state.matrixEmptyItemAnswers.push(item)
	},
	setPublicSurveyAnswer(state, answer) {
		state.publicSurveyAnswer = answer
	},
	setPublicSurveyAccessed(state, accessed) {
		state.publicSurveyAccessed = accessed
	},
	setPublicSurveyAnswered(state, answered) {
		state.publicSurveyAnswered = answered
	},
	setPublicSurveyAnsweredId(state, id) {
		state.publicSurveyAnsweredId = id
	},
	setPublicSurveyStartAt(state, startAt) {
		state.publicSurveyStartAt = startAt
	},
	setQ1(state, answer) {
		state.Q1 = answer
	},
	setQ2(state, answer) {
		state.Q2 = answer
	},
	setQ3(state, answer) {
		state.Q3 = answer
	},
	setQ4(state, answer) {
		state.Q4 = answer
	},
	setQ4other(state, answer) {
		state.Q4other = answer
	},
	setQ5(state, answer) {
		state.Q5 = answer
	},
	setQ5other(state, answer) {
		state.Q5other = answer
	},
	setQ6(state, answer) {
		state.Q6 = answer
	},
	setQ7(state, answer) {
		state.Q7 = answer
	},
	setQ8(state, answer) {
		state.Q8 = answer
	},
	setQ9(state, answer) {
		state.Q9 = answer
	},
	setQ10(state, answer) {
		state.Q10 = answer
	},
	clearMatrixItemAnswers(state) {
		state.matrixItemAnswers = []
	},
	clearMatrixItemNotAnswered(state) {
		state.matrixItemNotAnswered = []
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}