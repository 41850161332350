var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1200","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue-grey","dark":""},on:{"click":function($event){return _vm.clickGetAnswers(_vm.item.id)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.item.sets.length))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[(_vm.item.score)?_c('span',[_vm._v(" Score: "+_vm._s(_vm.item.score)+" (Familiarity: "+_vm._s(_vm.item.familiarity)+") ")]):(_vm.item.score_alt)?_c('span',[_vm._v(" Score: "+_vm._s(_vm.item.score_alt)+" (Familiarity: "+_vm._s(_vm.item.familiarity_alt)+") ")]):_vm._e(),_c('v-spacer'),(_vm.clipboard)?_c('div',[_c('v-tooltip',{attrs:{"left":"","color":_vm.tooltipColor},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"icon":"","color":"blue"},on:{"click":_vm.copyAnswers}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,1751495271),model:{value:(_vm.tooltipShow),callback:function ($$v) {_vm.tooltipShow=$$v},expression:"tooltipShow"}},[_c('span',[_vm._v(_vm._s(_vm.tooltipMessage))])])],1):_vm._e()],1),_c('v-card-text',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.scoreAnswers,"search":_vm.search,"sort-by":_vm.columnName,"sort-desc":_vm.isDescending,"items-per-page":10,"footer-props":{
					showFirstLastPage: true
				},"loading":_vm.loading},on:{"update:sortBy":function($event){_vm.columnName=$event},"update:sort-by":function($event){_vm.columnName=$event},"update:sortDesc":function($event){_vm.isDescending=$event},"update:sort-desc":function($event){_vm.isDescending=$event}},scopedSlots:_vm._u([{key:"item.term",fn:function(ref){
				var item = ref.item;
return [_c('AdminTermAnswers',{attrs:{"id":item.term_id,"term":item.term}})]}},{key:"item.answer",fn:function(ref){
				var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.answer),"dark":""}},[_vm._v(" "+_vm._s(_vm.getAnswerText(item.answer))+" ")])]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" OK ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }