<template>
	<div v-if="test.survey_type == 1">
		<UserStudentSurvey/>
	</div>
	<div v-else-if="test.survey_type == 2">
		<UserLibrarianSurvey/>
	</div>
	<div v-else-if="test.survey_type == 3">
		<UserStudentMotiveSurvey/>
	</div>
	<div v-else>
		<UserAlumniMotiveSurvey/>
	</div>
</template>

<script>
import UserStudentSurvey from '@/components/UserStudentSurvey'
import UserLibrarianSurvey from '@/components/UserLibrarianSurvey'
import UserStudentMotiveSurvey from '@/components/UserStudentMotiveSurvey'
import UserAlumniMotiveSurvey from '@/components/UserAlumniMotiveSurvey'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserSurvey',
	components: {
		UserStudentSurvey,
		UserLibrarianSurvey,
		UserStudentMotiveSurvey,
		UserAlumniMotiveSurvey
	},
	created () {
		this.setAlert(false)
	},
	computed: {
		...mapGetters({ test: 'stateTest' })
	},
	methods: {
		...mapActions(['setAlert'])
	}
}
</script>