import axios from 'axios'


const state = {
    curriculums: [],
    curriculum: {
        "courses": []
    },
    curriculumRes: {},
    formattedCourses: [],
    numberOfCourses: 0
}

const getters = {
    stateCurriculums: state => state.curriculums,
    stateCurriculum: state => state.curriculum,
    stateCurriculumRes: state => state.curriculumRes,
    stateFormattedCourses: state => state.formattedCourses,
    stateNumberOfCourses: state => state.numberOfCourses
}

const actions = {
    async createCurriculum({dispatch, commit}, payload) {
        const curriculum = {"name": payload.name}
        const courses = payload.courses
        let {data} = await axios.post('/curriculums/', curriculum)
        commit('setCurriculumRes', data)
        const curriculumId = data.id
        let courseList = new Array()
        for (const course of courses) {
            const courseItem = {
                "name": course.name,
                "curriculum_id": curriculumId
            }
            courseList.push(courseItem)
        }
        await dispatch('addCourses', courseList)
        await dispatch('getCurriculums')
    },
    async getCurriculums({commit}) {
        let {data} = await axios.get('/curriculums/')
        commit('setCurriculums', data)
    },
    async getCurriculum({commit}, id) {
        let {data} = await axios.get(`/curriculums/${id}`)
        commit('setCurriculum', data)
    },
    async updateCurriculumName({dispatch}, {id, name}) {
        const payload = {"name": name}
        await axios.put(`/curriculums/${id}`, payload)
        await dispatch('getCurriculums')
    },
    async formatCourses({commit}, courses) {
        const payload = {"courses": courses}
        let {data} = await axios.post('/format_courses/', payload)
        commit('setFormattedCourses', data)
    },
    async addCourses({commit}, payload) {
        let {data} = await axios.post('/add_courses/', payload)
        commit('setNumberOfCourses', data.length)
    },
    async getLISCourses() {
        let {data} = await axios.get('/lis_courses/')
        return data
    },
    async deleteCurriculum({dispatch}, id) {
        await axios.delete(`/curriculums/${id}`)
        await dispatch('getCurriculums')
    },
    clearCurriculums({commit}) {
        commit('setCurriculums', [])
    },
    clearCurriculum({commit}) {
        commit('setCurriculum', {})
    },
    clearFormattedCourses({commit}) {
        commit('setFormattedCourses', [])
        commit('setNumberOfCourses', 0)
    }
}

const mutations = {
    setCurriculums(state, curriculums) {
        state.curriculums = curriculums
    },
    setCurriculum(state, curriculum) {
        state.curriculum = curriculum
    },
    setCurriculumRes(state, res) {
        state.curriculumRes = res
    },
    setFormattedCourses(state, formattedCourses) {
        state.formattedCourses = formattedCourses
    },
    setNumberOfCourses(state, number) {
        state.numberOfCourses = number
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}