<template>
	<v-dialog
		v-model="dialog"
		width="1000"
		scrollable
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="blue-grey"
				:dark="!loading && canClick"
				:light="loading || !canClick"
				v-bind="attrs"
				v-on="on"
				:disabled="loading || !canClick"
                @click="getGroupUsers(item.id)"
			>{{ item.users.length }}</v-btn>
		</template>
		<v-card>
			<v-card-title>
				{{ item.name }}のユーザー
			</v-card-title>
			<v-card-text>
				<v-data-table
                    :headers="headers"
                    :items="users"
                    :search="search"
                    :items-per-page="10"
                    :sort-by.sync="columnName"
                    :sort-desc.sync="isDescending"
                    :footer-props="{
                        showFirstLastPage: true
                    }"
                    :loading="loading"
                >
                    <template v-slot:top>
                        <v-toolbar flat>
                            <v-container>
                                <v-row>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="download"
                                        :disabled="loading || !users.length"
                                    >
                                        ユーザーをダウンロード
                                    </v-btn>
                                </v-row>
                            </v-container>
                        </v-toolbar>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        >
                        </v-text-field>
                    </template>
                    <template v-slot:item.password="{ item }">
                        <AdminPasswordField :item="item" />
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ formatDate(item.created_at) }}
                    </template>
                    <template v-slot:item.is_active="{ item }">
                        <v-simple-checkbox
                            v-model="item.is_active"
                            v-ripple
                            :disabled="loading || !canEdit(item)"
                            @input="updateActive(item)"
                        ></v-simple-checkbox>
                    </template>
                </v-data-table>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="close"
				>
					OK
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminPasswordField from '@/components/AdminPasswordField'

export default {
	name: 'AdminViewTests',
    components: {
		AdminPasswordField
	},
	props: ['item'],
	data () {
		return {
			isAll: false,
			dialog: false,
			columnName: 'id',
			isDescending: false,
            search: '',
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Name', value: 'username' },
				{ text: 'Password', value: 'password' },
				{ text: 'Number of answers', value: 'testanswers.length' },
				{ text: 'Created at', value: 'created_at' },
				{ text: 'Active', value: 'is_active' },
			]
		}
	},
	computed: {
		...mapGetters({
			users: 'stateGroupUsers',
			alert: 'stateAlert',
			loading: 'stateLoading',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated',
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin'
		}),
		canClick() {
			if (this.admin) {
				return true
			} else {
				if (this.isAll) {
					return false
				} else {
					if (this.item.admin_id == this.plainadmin.id) {
						return true
					} else {
						return false
					}
				}
			}
		}
	},
    watch: {
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 1000)
			}
		}
	},
	methods: {
        ...mapActions(['setLoading', 'setAlert', 'getUserUsers', 'updateUserActive', 'downloadUsers', 'deleteUser', 'checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout', 'getGroupUsers']),
		close() {
			this.dialog = false
		},
        formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		canEdit(user) {
			if (this.admin) {
				return true
			} else if (this.plainadmin.id == user.admin_id) {
				return true
			} else {
				return false
			}
		},
        async updateActive(item) {
			const payload = {
				"id": item.id,
				"is_active": item.is_active
			}
			this.setLoading(true)
			await this.updateUserActive(payload).then(() => {
				this.setLoading(false)
				this.setAlert(false)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		async download() {
			if (this.admin) {
				this.ensureAdmin()
			} else {
				this.ensurePlainAdmin()
			}

			if (this.admin || this.plainadmin) {
				this.setLoading(true)
				await this.downloadUsers({"role": 1, "usergroup_id": this.item.id}).then((response) => {
					let blob = new Blob([response.data], { type: 'text/tsv' })
					let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
					let url = window.URL.createObjectURL(blob)
					let dllink = document.createElement('a')
					dllink.href = url
					dllink.setAttribute("download", filename)
					dllink.click()
					this.setLoading(false)
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
			}
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		async ensurePlainAdmin() {
			if (this.isPlainAdminAuthenticated) {
				await this.checkPlainAdmin().catch(() => {
					this.plainAdminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}
</script>