var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{attrs:{"value":_vm.alert,"dense":"","outlined":"","text":"","prominent":"","type":"error","transition":"scale-transition"}},[_vm._v(" エラーが発生しました。ログアウト後再度ログインしてください。 ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userGroups,"search":_vm.search,"items-per-page":10,"sort-by":_vm.columnName,"sort-desc":_vm.isDescending,"footer-props":{
				showFirstLastPage: true
			},"loading":_vm.loading},on:{"update:sortBy":function($event){_vm.columnName=$event},"update:sort-by":function($event){_vm.columnName=$event},"update:sortDesc":function($event){_vm.isDescending=$event},"update:sort-desc":function($event){_vm.isDescending=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-container',[_c('v-row',[_c('v-spacer'),_c('AdminNewUserGroup')],1)],1)],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.name",fn:function(ref){
			var item = ref.item;
return [_c('AdminEditUserGroup',{attrs:{"item":item}})]}},{key:"item.tests.length",fn:function(ref){
			var item = ref.item;
return [_c('AdminViewTests',{attrs:{"item":item}})]}},{key:"item.users.length",fn:function(ref){
			var item = ref.item;
return [_c('AdminViewUsers',{attrs:{"item":item}})]}},{key:"item.created_at",fn:function(ref){
			var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.delete",fn:function(ref){
			var item = ref.item;
return [_c('AdminDeleteButton',{attrs:{"deleteFunction":_vm.deleteUserGroup,"item":item,"isAll":false,"isCritical":false}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }