<template>
	<div>
		<v-alert
			:value="alert"
			dense
			outlined
			text
			prominent
			type="error"
			transition="scale-transition"
		>
			エラーが発生しました。ログアウト後再度ログインしてください。
		</v-alert>
		<v-data-table
			:headers="headers"
			:items="curriculums"
			:search="search"
			:items-per-page="10"
			:sort-by.sync="columnName"
			:sort-desc.sync="isDescending"
			:footer-props="{
				showFirstLastPage: true
			}"
			:loading="loading"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-container>
						<v-row>
							<v-spacer></v-spacer>
							<AdminNewCurriculum/>
						</v-row>
					</v-container>
				</v-toolbar>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
			</template>
			<template v-slot:item.name="props">
				<v-edit-dialog
					large
					save-text="保存"
					cancel-text="キャンセル"
					@open="updateNameOpen(props.item.name)"
					@cancel="setAlert(false)"
					@save="updateName(props.item.id, editedName)"
					v-if="admin"
				>
					<div>{{ props.item.name }}</div>
					<template v-slot:input>
						<v-form ref="updateNameForm">
							<v-text-field
								v-model="editedName"
								:rules="[rules.required, rules.max20chars]"
								label="Edit"
								single-line
								counter
								maxlength="20"
							></v-text-field>
						</v-form>
					</template>
				</v-edit-dialog>
				<div v-else>{{ props.item.name }}</div>
			</template>
			<template v-slot:item.number_of_courses="{ item }">
				<AdminViewCurriculum :item="item"/>
			</template>
			<template v-slot:item.created_at="{ item }">
				{{ formatDate(item.created_at) }}
			</template>
			<template v-slot:item.delete="{ item }">
				<AdminDeleteButton :deleteFunction="deleteCurriculum" :item="item" :isAll="true" :isCritical="false" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminNewCurriculum from '@/components/AdminNewCurriculum.vue'
import AdminViewCurriculum from '@/components/AdminViewCurriculum.vue'
import AdminDeleteButton from '@/components/AdminDeleteButton.vue'

export default {
	name: 'AdminCurriculums',
	components: {
		AdminNewCurriculum,
		AdminViewCurriculum,
		AdminDeleteButton,
	},
	data () {
		return {
			columnName: 'id',
			isDescending: false,
			search: '',
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Name', value: 'name' },
				{ text: 'Number of courses', value: 'number_of_courses' },
				{ text: 'Created at', value: 'created_at' },
				{ text: 'Delete', value: 'delete' }
			],
			rules: {
				required: value => !!value || 'Required',
				max20chars: value => value.length <= 20 || 'Max 20 characters'
			},
			editedName: ''
		}
	},
	async created () {
		if (this.isAdminAuthenticated || this.isPlainAdminAuthenticated) {
			await this.getCurriculums()
		}
	},
	computed: {
		...mapGetters({
			alert: 'stateAlert',
			loading: 'stateLoading',
			curriculums: 'stateCurriculums',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated',
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin',
		})
	},
	watch: {
		alert (val) {
			if (val) {
				setTimeout(() => (this.alert = false), 5000)
			}
		}
	},
	methods: {
		...mapActions(['setLoading', 'setAlert', 'checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout', 'getCurriculums', 'updateCurriculumName', 'deleteCurriculum']),
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		updateNameOpen(name) {
			this.ensureAdmin()
			this.setAlert(false)
			this.editedName = name
		},
		async updateName(id, name) {
			if (this.$refs.updateNameForm.validate()) {
				this.setLoading(true)
				const payload = {
					"id": id,
					"name": name
				}
				await this.updateCurriculumName(payload).then(() => {
					this.setLoading(false)
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
			} else {
				this.setAlert(true)
			}
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		async ensurePlainAdmin() {
			if (this.isPlainAdminAuthenticated) {
				await this.checkPlainAdmin().catch(() => {
					this.plainAdminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}

</script>