<template>
	<v-dialog
		v-model="dialog"
		width="1200"
		scrollable
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="blue-grey"
				dark
				v-bind="attrs"
				v-on="on"
				@click="clickGetAnswers(item.id)"
			>{{ item.sets.length }}</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span v-if="item.score">
					Score: {{ item.score }} (Familiarity: {{ item.familiarity }})
				</span>
				<span v-else-if="item.score_alt">
					Score: {{ item.score_alt }} (Familiarity: {{ item.familiarity_alt }})
				</span>
				<v-spacer></v-spacer>
				<div v-if="clipboard">
					<v-tooltip
						left
						:color="tooltipColor"
						v-model="tooltipShow"
					>
						<template v-slot:activator="{ attrs }">
							<v-btn
								icon
								color="blue"
								v-bind="attrs"
								@click="copyAnswers"
							>
								<v-icon>mdi-content-copy</v-icon>
							</v-btn>
						</template>
						<span>{{tooltipMessage}}</span>
					</v-tooltip>
				</div>
			</v-card-title>
			<v-card-text>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
				<v-data-table
					:headers="headers"
					:items="scoreAnswers"
					:search="search"
					:sort-by.sync="columnName"
					:sort-desc.sync="isDescending"
					:items-per-page="10"
					:footer-props="{
						showFirstLastPage: true
					}"
					:loading="loading"
				>
					<template v-slot:item.term="{ item }">
						<AdminTermAnswers :id="item.term_id" :term="item.term" />
					</template>
					<template v-slot:item.answer="{ item }">
						<v-chip
							:color="getColor(item.answer)"
							dark
						>
							{{ getAnswerText(item.answer) }}
						</v-chip>
					</template>
				</v-data-table>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="close"
				>
					OK
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</template>

<script>
import AdminTermAnswers from '@/components/AdminTermAnswers'
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminAnswers',
	props: ['item'],
	components: {
		AdminTermAnswers
	},
	data () {
		return {
			dialog: false,
			columnName: 'rank',
			isDescending: false,
			search: '',
			headers: [
				{ text: 'ID', value: 'term_id' },
				{ text: 'Term', value: 'term' },
				{ text: 'Familiarity', value: 'term_familiarity'},
				{ text: 'Rank', value: 'term_rank' },
				{ text: 'Answer', value: 'answer' }
			],
			clipboard: false,
			tooltipShow: false,
			tooltipColor: 'success',
			tooltipMessage: 'Answers copied!'
		}
	},
	created () {
		if (navigator.clipboard) {
			this.clipboard = true
		}
	},
	computed: {
		...mapGetters({ scoreAnswers: 'stateScoreAnswers', loading: 'stateLoading' })
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.clearScoreAnswers()
			}
		}
	},
	methods: {
		...mapActions(['getScoreAnswers', 'clearScoreAnswers', 'setLoading']),
		async clickGetAnswers(id) {
			this.setLoading(true)
			await this.getScoreAnswers(id).then(() => {
				this.setLoading(false)
			}).catch(() => {
				this.setLoading(false)
			})
		},
		close() {
			this.dialog = false
		},
		getColor(value) {
			if (value === 1) return 'orange'
			else if (value === 2) return 'light-green'
			else if (value === 3) return 'green'
		},
		getAnswerText(value) {
			if (value === 1) return '全く知らない'
			else if (value === 2) return '見たり聞いたりしたことがある'
			else if (value === 3) return '簡潔に説明できる'
		},
		makeTSV(data) {
			const headings = Object.keys(data[0]).join('\t')
			const rows = data.reduce((acc, c) => {
				return acc.concat([Object.values(c).join('\t')])
			}, []).join('\n')
			return `${headings}\n${rows}`
		},
		async copyAnswers() {
			const keysToKeep = ['term', 'term_familiarity', 'term_rank', 'answer']
			const selectKeys = (array, keys) => array.map(o => keys.reduce((acc, curr) => {
				acc[curr] = o[curr]
				return acc
			}, {}))
			const reduced = selectKeys(this.scoreAnswers, keysToKeep)
			const tsv = this.makeTSV(reduced)
			if (!this.clipboard) {
				return
			} else {
				await navigator.clipboard.writeText(tsv).then(() => {
					this.tooltipColor = 'success'
					this.tooltipMessage = 'Answers copied!'
					this.tooltipShow = true
					setTimeout(() => {this.tooltipShow = false}, 1000)
				}).catch(err => {
					this.tooltipColor = 'error'
					this.tooltipMessage = 'Answers not copied'
					this.tooltipShow = true
					setTimeout(() => {this.tooltipShow = false}, 1000)
					console.error(err)
				})
			}
		}
	}
}

</script>
