import axios from 'axios'


const state = {
    scaleScores: [],
    scaleScore: null
}

const getters = {
    stateScaleScores: state => state.scaleScores,
    stateScaleScore: state => state.scaleScore
}

const actions = {
    async createScaleScore(_, payload) {
        let {data} = await axios.post('/scalescores/', payload)
        return data
    },
    async getScaleScores({commit}) {
        let {data} = await axios.get('/scalescores/')
        commit('setScaleScores', data)
    },
    clearScaleScores({commit}) {
        commit('setScaleScores', [])
    },
    clearScaleScore({commit}) {
        commit('setScaleScore', null)
    }
}

const mutations = {
    setScaleScores(state, scaleScores) {
        state.scaleScores = scaleScores
    },
    setScaleScore(state, scaleScore) {
        state.scaleScore = scaleScore
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
