import axios from 'axios'


const state = {
	answers: [],
	notAnswered: [],
	allAnswers: [],
	answeredSets: [],
	currentAnswerTermBank: null,
	answerScore: null,
	answerRatio: null,
	answerScoreAlt: null,
	answerRatioAlt: null,
	scoreAnswers: [],
	termAnswers: []
}

const getters = {
	stateAnswers: state => state.answers,
	stateNotAnswered: state => state.notAnswered,
	stateAllAnswers: state => state.allAnswers,
	stateAnsweredSets: state => state.answeredSets,
	stateCurrentAnswerTermBank: state => state.currentAnswerTermBank,
	stateAnswerScore: state => state.answerScore,
	stateAnswerRatio: state => state.answerRatio,
	stateAnswerScoreAlt: state => state.answerScoreAlt,
	stateAnswerRatioAlt: state => state.answerRatioAlt,
	stateScoreAnswers: state => state.scoreAnswers,
	stateTermAnswers: state => state.termAnswers
}

const actions = {
	addAnswer({commit}, answer) {
		commit('addAnswer', answer)
	},
	clearAnswers({commit}) {
		commit('setAnswers', [])
	},
	setNotAnswered({commit}, terms) {
		let termIds = terms.map((_, index) => index + 1)
		commit('setNotAnswered', termIds)
	},
	async postAnswersSingleSet({state}, request) {
		const set = request.set
		const scoreId = request.score_id
		let answers = []
		for (let answer of state.answers) {
			let obj = {
				'answer': answer.answer,
				'set_id': set.id,
				'term_id': answer.termId,
				'score_id': scoreId
			}
			answers.push(obj)
		}
		let payload = {
			'payload': {
				'id': scoreId
			},
			'answers': answers
		}
		await axios.post('/register_score/', payload)
	},
	async postAnswers({commit, state}, request) {
		const set = request.set
		const scoreId = request.score_id
		let answers = []
		for (let answer of state.answers) {
			let obj = {
				'answer': answer.answer,
				'set_id': set.id,
				'term_id': answer.termId,
				'score_id': scoreId
			}
			answers.push(obj)
		}
		let newAllAnswers = state.allAnswers.filter(n => n.set_id !== set.id)
		commit('setAllAnswers', newAllAnswers)
		commit('extendAllAnswers', answers)
		let allAnswers = []
		for (let answer of state.allAnswers) {
			let obj = {
				'answer': answer.answer,
				'set_id': answer.set_id,
				'term_id': answer.term_id,
				'score_id': scoreId
			}
			allAnswers.push(obj)
		}
		let payload = {
			'payload': {
				'id': scoreId
			},
			'answers': allAnswers
		}
		let {data} = await axios.post('/register_score/', payload)
		commit('setAnswerScore', data.score)
		commit('setAnswerRatio', data.ratio)
		commit('setAnswerScoreAlt', data.score_alt)
		commit('setAnswerRatioAlt', data.ratio_alt)
	},
	async getScoreAnswers({commit}, id) {
		let {data} = await axios.get(`/answers/?score=${id}`)
		commit('setScoreAnswers', data)
	},
	async getTermAnswers({commit}, id) {
		let {data} = await axios.get(`/answers/?term=${id}`)
		commit('setTermAnswers', data)
	},
	async downloadAnswers() {
		return await axios.get('/answers/download/', { responseType: 'blob' })
	},
	clearAnswerScore({commit}) {
		commit('setAnswerScore', null)
		commit('setAnswerRatio', null)
		commit('setAnswerScoreAlt', null)
		commit('setAnswerRatioAlt', null)
	},
	clearScoreAnswers({commit}) {
		commit('setScoreAnswers', [])
	},
	clearTermAnswers({commit}) {
		commit('setTermAnswers', [])
	},
	clearAllAnswers({commit}) {
		commit('setAllAnswers', [])
	},
	clearAnsweredSets({commit}) {
		commit('setAnsweredSets', [])
	},
	setAnsweredSets({commit}, sets) {
		commit('setAnsweredSets', sets)
	},
	appendAnsweredSets({commit}, set) {
		commit('appendAnsweredSets', set)
	},
	setCurrentAnswerTermBank({commit}, termbankId) {
		commit('setCurrentAnswerTermBank', termbankId)
	}
}

const mutations = {
	addAnswer(state, answer) {
		let index = state.answers.findIndex((obj => obj.termId === answer.termId))
		if (index === -1) {
			let notIndex = state.notAnswered.indexOf(answer.index)
			state.answers.push(answer)
			state.notAnswered.splice(notIndex, 1)
		} else {
			if (answer.answer === undefined) {
				state.answers.splice(index, 1)
				state.notAnswered.push(answer.index)
				state.notAnswered.sort((a, b) => a - b)
			} else {
				state.answers[index].answer = answer.answer
			}
		}
	},
	setAnswers(state, answers) {
		state.answers = answers
	},
	setNotAnswered(state, notAnswered) {
		state.notAnswered = notAnswered
	},
	setAnswerScore(state, score) {
		state.answerScore = score
	},
	setAnswerRatio(state, ratio) {
		state.answerRatio = ratio
	},
	setAnswerScoreAlt(state, score) {
		state.answerScoreAlt = score
	},
	setAnswerRatioAlt(state, ratio) {
		state.answerRatioAlt = ratio
	},
	setScoreAnswers(state, answers) {
		state.scoreAnswers = answers
	},
	setTermAnswers(state, answers) {
		state.termAnswers = answers
	},
	setCurrentAnswerTermBank(state, termbankId) {
		state.currentAnswerTermBank = termbankId
	},
	setAllAnswers(state, answers) {
		state.allAnswers = answers
	},
	extendAllAnswers(state, answers) {
		let temp = state.allAnswers
		state.allAnswers = temp.concat(answers)
	},
	setAnsweredSets(state, sets) {
		state.answeredSets = sets
	},
	appendAnsweredSets(state, set) {
		state.answeredSets.push(set)
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
