<template>
	<div>
		<v-alert
			:value="alert"
			dense
			outlined
			text
			prominent
			type="error"
			transition="scale-transition"
		>
			エラーが発生しました。ログアウト後再度ログインしてください。
		</v-alert>
		<v-data-table
			:headers="headers"
			:items="userGroups"
			:search="search"
			:items-per-page="10"
			:sort-by.sync="columnName"
			:sort-desc.sync="isDescending"
			:footer-props="{
				showFirstLastPage: true
			}"
			:loading="loading"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-container>
						<v-row>
							<v-spacer></v-spacer>
							<AdminNewUserGroup/>
						</v-row>
					</v-container>
				</v-toolbar>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
			</template>
            <template v-slot:item.name="{ item }">
                <AdminEditUserGroup :item="item" />
            </template>
            <template v-slot:item.tests.length="{ item }">
                <AdminViewTests :item="item" />
            </template>
			<template v-slot:item.users.length="{ item }">
                <AdminViewUsers :item="item" />
            </template>
			<template v-slot:item.created_at="{ item }">
				{{ formatDate(item.created_at) }}
			</template>
			<template v-slot:item.delete="{ item }">
				<AdminDeleteButton :deleteFunction="deleteUserGroup" :item="item" :isAll="false" :isCritical="false" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminNewUserGroup from '@/components/AdminNewUserGroup'
import AdminEditUserGroup from '@/components/AdminEditUserGroup'
import AdminDeleteButton from '@/components/AdminDeleteButton'
import AdminViewTests from '@/components/AdminViewTests'
import AdminViewUsers from '@/components/AdminViewUsers'

export default {
	name: 'AdminUserGroups',
	components: {
        AdminNewUserGroup,
        AdminEditUserGroup,
        AdminDeleteButton,
        AdminViewTests,
        AdminViewUsers
    },
	data () {
		return {
			columnName: 'id',
			isDescending: false,
			search: '',
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Name', value: 'name' },
				{ text: 'Tests', value: 'tests.length' },
				{ text: 'Users', value: 'users.length' },
				{ text: 'Created at', value: 'created_at' },
				{ text: 'Delete', value: 'delete' }
			]
		}
	},
	async created () {
		if (this.isAdminAuthenticated || this.isPlainAdminAuthenticated) {
            await this.getUserGroups()
		}
	},
	computed: {
		...mapGetters({
			alert: 'stateAlert',
			loading: 'stateLoading',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated',
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin',
            userGroups: 'stateUserGroups'
		})
	},
	watch: {
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 1000)
			}
		}
	},
	methods: {
		...mapActions(['setLoading', 'setAlert', 'getUserUsers', 'updateUserActive', 'deleteUserGroup', 'checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout', 'getUserGroups']),
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		canEdit(user) {
			if (this.admin) {
				return true
			} else if (this.plainadmin.id == user.admin_id) {
				return true
			} else {
				return false
			}
		},
		async updateActive(item) {
			const payload = {
				"id": item.id,
				"is_active": item.is_active
			}
			this.setLoading(true)
			await this.updateUserActive(payload).then(() => {
				this.setLoading(false)
				this.setAlert(false)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		async ensurePlainAdmin() {
			if (this.isPlainAdminAuthenticated) {
				await this.checkPlainAdmin().catch(() => {
					this.plainAdminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}
</script>