<template>
	<div class="mt-5">
		<v-row class="mx-auto col-lg-10 col-md-10 col-sm-10 col-xs-10">
			<v-col>
				<v-card
					color="grey lighten-3"
					class="d-flex align-center"
					height="50"
					width="100"
					flat
					@click="toPrev"
					v-if="!isFirst"
				>
					<div
						class="flex-grow-1 text-center"
					>
						前へ
					</div>
				</v-card>
			</v-col>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
			<v-spacer></v-spacer>
			<v-col>
				<v-card
					color="grey lighten-3"
					class="d-flex align-center"
					height="50"
					width="100"
					flat
					@click="toNext"
					:disabled="!isAnswered"
				>
					<div
						class="flex-grow-1 text-center"
					>
						<span v-if="!isLast">次へ</span>
						<span v-else>回答を送信</span>
					</div>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'NextPrevButton',
	props: {
		questions: Number
	},
	computed: {
		...mapGetters({
			Q1: 'stateQ1',
			Q2: 'stateQ2',
			Q3: 'stateQ3',
			Q4: 'stateQ4',
			Q4other: 'stateQ4other',
			Q5: 'stateQ5',
			Q5other: 'stateQ5other',
			Q6: 'stateQ6',
			Q7: 'stateQ7',
			Q8: 'stateQ8',
			Q9: 'stateQ9',
			Q10: 'stateQ10',
			answerId: 'statePublicSurveyAnsweredId',
			startAt: 'statePublicSurveyStartAt',
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
			matrixItemNotAnswered: 'stateMatrixItemNotAnswered',
			matrixItemAnswers: 'stateMatrixItemAnswers',
			userId: 'stateUserId',
		}),
		isDemo() {
			if (this.$route.matched[1].name == "JSLIS2024") {
				return true
			} else {
				return false
			}
		},
		q() {
			if (this.$route.params.q == undefined) {
				return 1
			} else {
				return Number(this.$route.params.q)
			}
		},
		test() {
			return this.$route.params.id
		},
		isFirst() {
			if (this.q == 1 || this.q == undefined) {
				return true
			} else {
				return false
			}
		},
		isLast() {
			if (this.q == this.questions) {
				return true
			} else {
				return false
			}
		},
		isAnswered() {
			if (this.isDemo) {
				return true
			}
			if (this.q == 1) {
				if (this.Q1 != null) {
					return true
				} else {
					return false
				}
			} else if (this.q == 2) {
				if (this.scaleItemNotAnswered.length == 0) {
					return true
				} else {
					return false
				}
			} else if (this.q == 3) {
				if (this.Q3 != null) {
					return true
				} else {
					return false
				}
			} else if (this.q == 4) {
				if (this.Q4 != null) {
					if (this.Q4 == 99) {
						if (this.Q4other != '' && this.Q4other != null) {
							return true
						} else {
							return false
						}
					} else {
						return true
					}
				} else {
					return false
				}
			} else if (this.q == 5) {
				if (this.Q5.includes(99)) {
					if (this.Q5other != '' && this.Q5other != null) {
						return true
					} else {
						return false
					}
				} else {
					return true
				}
			} else if (this.q == 6) {
				if (this.matrixItemNotAnswered.length == 0) {
					return true
				} else {
					return false
				}
			} else if (this.q == 7) {
				if (this.Q7 != null && this.Q8 != null && (this.Q9 != null && this.Q9 != "" && this.Q9 != undefined)) {
					return true
				} else {
					return false
				}
			} else {
				return true
			}
		}
	},
	methods: {
		...mapActions([
			'setQ2',
			'setQ6',
			'postPublicSurveyAnswer',
			'updatePublicSurveyAnswer',
			'setLoading',
			'setAlert',
			'setPublicSurveyAnswered'
		]),
		async answer () {
			this.setLoading(true)
			let answer = {
				"certificate_year": this.Q1,
				"mslc": this.Q2,
				"motivation": this.Q3,
				"occupation": this.Q4,
				"other_occupation": this.Q4other,
				"past_occupations": this.Q5,
				"other_past_occupation": this.Q5other,
				"learning_content": this.Q6,
				"gender": this.Q7,
				"age": this.Q8,
				"university": this.Q9,
				"start_at": this.startAt,
				"user_id": this.userId,
			}
			await this.postPublicSurveyAnswer(answer).then(() => {
				this.setLoading(false)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		async update () {
			this.setLoading(true)
			let payload = {
				"id": this.answerId,
				"comment": this.Q10
			}
			await this.updatePublicSurveyAnswer(payload).then(() => {
				this.setLoading(false)
				this.setPublicSurveyAnswered(true)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		toNext() {
			let link = '';
			let nq = this.q + 1;

			if (this.q == 2) {
				this.setQ2(this.scaleItemAnswers)
			}

			if (this.q == 6) {
				this.setQ6(this.matrixItemAnswers)
			}

			if (this.q == 7) {
				if (!this.isDemo) {
					this.answer()
				}
			}

			if (this.q == 8) {
				if (!this.isDemo) {
					this.update()
				}
			}

			if (this.q < this.questions) {
				if (this.isDemo) {
					link = `/jslis2024/q/${nq}`
				} else {
					link = `/survey/q/${nq}`
				}
			} else {
				if (this.isDemo) {
					link = `/jslis2024/result`
				} else {
					link = `/survey/result`
				}
			}
			this.$router.push({ path: link });
		},
		toPrev() {
			let link = '';
			let pq = this.q - 1;
			if (this.q != undefined && this.q != 1) {
				if (this.isDemo) {
					link = `/jslis2024/q/${pq}`
				} else {
					link = `/survey/q/${pq}`
				}
			}
			this.$router.push({ path: link });
		}
	}
}
</script>