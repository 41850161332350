<template>
	<v-app-bar
		light
		elevate-on-scroll
		app
	>
		<v-btn
				icon
				to="/"
				v-if="$route.name == 'ScaleJA' || $route.name == 'ScaleEN'"
			>
			<v-icon>mdi-arrow-u-left-top</v-icon>
		</v-btn>
		<v-btn
			icon
			disabled
			v-else
		></v-btn>
		<v-spacer></v-spacer>
		<div class="app-name">MSLC</div>
		<v-spacer></v-spacer>
		<InfoDialog/>
		<v-progress-linear
			:active="loading"
			:indeterminate="loading"
			absolute
			bottom
		></v-progress-linear>
	</v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import InfoDialog from "@/components/InfoDialog"

export default {
	name: 'UserAppBar',
	components: {
		InfoDialog
	},
	computed: {
		...mapGetters({'loading': 'stateLoading'})
	}
}
</script>

<style scoped>
.app-name {
	font-family: 'Sansita Swashed', sans-serif;
	font-size: 1.8em;
	color: #333;
}
</style>