<template>
<div>
	<v-row>
		<v-col>
			<v-card
				class="mx-auto"
				max-width="500"
				tile
				flat
			>
				<p class="mt-5 mb-5 text-center">
					上から順番にクリックし、<span v-if="test.survey_type === 4">質問</span><span v-else>問題</span>に回答してください。<br/>
				</p>
			</v-card>
		</v-col>
	</v-row>
	<v-row>
		<v-col>
			<div v-if="noSets && test.survey_type !== 4">
				問題が存在しません。<br/>
				時間をおいてもう一度お試しください。
			</div>
			<v-card
				class="mx-auto mb-5"
				max-width="400"
				flat
			>
				<v-btn
					color="blue darken-3"
					:dark="!isSetAnswered(set.id)"
					x-large
					elevation="0"
					class="mx-auto col-10 mb-5"
					:to="`/test/${testId}/set/${set.id}`"
					:disabled="isSetAnswered(set.id)"
					v-for="(set, index) in sets"
					:key="'set-' + index"
				>
					{{ set.name }}
				</v-btn>
				<v-btn
					color="blue"
					:dark="!isScaleAnswered(scale.id)"
					x-large
					elevation="0"
					class="mx-auto col-10 mb-5"
					:to="`/test/${testId}/scale/${scale.id}`"
					:disabled="isScaleAnswered(scale.id)"
					v-for="(scale, index) in scales"
					:key="'scale-' + index"
				>
					{{ scale.name }}
				</v-btn>
				<v-btn
					color="blue lighten-2"
					:dark="!isSurveyAnswered"
					x-large
					elevation="0"
					class="mx-auto col-10 mb-5"
					:to="`/test/${testId}/survey`"
					:disabled="isSurveyAnswered"
				>
					<span v-if="test.survey_type === 4">司書課程での学びとフェイスシート</span>
					<span v-else>フェイスシート</span>
				</v-btn>
			</v-card>
			<div v-if="error">
				<p class="mx-auto mt-2 mb-10">
					問題を取得できませんでした。<br/>
					時間をおいてもう一度お試しください。
				</p>
			</div>
		</v-col>
	</v-row>
	<v-row v-if="(noSets && test.survey_type !== 4) || error">
		<v-col>
			<v-btn
				to="/experiments"
				large
				depressed
				class="mt-16"
				width="330"
			>
				戻る
			</v-btn>
		</v-col>
	</v-row>
	<v-row v-if="isAllAnswered">
		<v-col>
			<v-card
				class="mx-auto"
				max-width="500"
				tile
				flat
			>
				<v-alert
					dense
					outlined
					text
					class="mt-10"
					type="success"
				>
					すべての回答が終了しました。<br/>ご協力ありがとうございました。
				</v-alert>
			</v-card>
			<v-btn
				large
				depressed
				class="mt-16"
				width="330"
				@click="logout"
				v-if="isExperimenting"
			>
				ログアウト
			</v-btn>
			<v-btn
				to="/experiments"
				large
				depressed
				class="mt-16"
				width="330"
				v-else
			>
				戻る
			</v-btn>
		</v-col>
	</v-row>
</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserTestSetsSets',
	data () {
		return {
			error: false,
			sets: [],
			scales: [],
			noSets: false,
			noTest: true,
		}
	},
	computed: {
		...mapGetters({
			test: 'stateTest',
			loading: 'stateLoading',
			surveyAnswered: 'stateSurveyAnswered',
			libSurveyAnswered: 'stateLibSurveyAnswered',
			motSurveyAnswered: 'stateMotSurveyAnswered',
			alumniSurveyAnswered: 'stateAlumniSurveyAnswered',
			testSetAnswered: 'stateTestSetAnswered',
			scaleAnswered: 'stateAnsweredScales',
			testAnswer: 'stateTestAnswer',
			userId: 'stateUserId'
		}),
		testId() {
			return this.$route.params.id
		},
		isSurveyAnswered() {
			if (this.surveyAnswered.indexOf(this.testId) >= 0) {
				return true
			} else if (this.libSurveyAnswered.indexOf(this.testId) >= 0) {
				return true
			} else if (this.motSurveyAnswered.indexOf(this.testId) >= 0) {
				return true
			} else if (this.alumniSurveyAnswered.indexOf(this.testId) >= 0) {
				return true
			} else {
				return false
			}
		},
		isAllAnswered() {
			if (this.isSurveyAnswered && this.testSetAnswered.length === this.sets.length && this.scaleAnswered.length === this.scales.length) {
				return true
			} else {
				return false
			}
		},
		isExperimenting() {
			const isExperimenting = process.env.VUE_APP_IS_EXPERIMENTING
			if (isExperimenting === 'true') {
				return true
			} else {
				return false
			}
		}
	},
	async created () {
		this.setLoading(true)
		if (!this.testAnswer) {
			await this.createTestAnswer({
				test_id: this.testId,
				user_id: this.userId
			})
		}
		await this.getPublishedTest(this.testId).then(() => {
			this.setLoading(false)
			this.error = false
			this.sets = this.test.sets
			this.scales = this.test.scales
			if (!this.sets.length) {
				this.noSets = true
			} else {
				this.noSets = false
			}
			this.noTest = false
		}).catch(err => {
			this.error = true
			this.setLoading(false)
			this.noSets = false
			this.noTest = true
			console.error(err)
		})
	},
	methods: {
		...mapActions([
			'getPublishedTest',
			'setLoading',
			'createTestAnswer',
			'userLogout',
		]),
		isSetAnswered(id) {
			if (this.testSetAnswered.indexOf(id) >= 0) {
				return true
			} else {
				return false
			}
		},
		isScaleAnswered(id) {
			if (this.scaleAnswered.indexOf(id) >= 0) {
				return true
			} else {
				return false
			}
		},
		logout() {
			this.userLogout().then(() => {
				this.$router.push('/')
			})
		}
	}
}
</script>