<template>
	<v-dialog
		v-model="dialog"
		max-width="400"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-hover v-slot="{ hover }">
				<v-btn
					v-bind="attrs"
					v-on="on"
					icon
					:color="hover ? 'error': ''"
					:disabled="loading || !canEdit"
					@click.stop="ensureAdmins"
				>
					<v-icon>mdi-delete</v-icon>
				</v-btn>
			</v-hover>
		</template>
		<v-card>
			<v-card-title></v-card-title>
			<v-card-text>
				<v-alert
					prominent
					outlined
					text
					type="warning"
				>
					本当に削除しますか？<br/>
					<span v-if="isCritical">関連するデータも削除されます。</span>
				</v-alert>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="close"
				>
					キャンセル
				</v-btn>
				<v-btn
					color="error"
					text
					@click="deleteItem"
				>
					削除
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminDeleteButton',
	props: ['deleteFunction', 'item', 'isAll', 'isCritical'],
	data () {
		return {
			dialog: false
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin',
			alert: 'stateAlert',
			loading: 'stateLoading',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated'
		}),
		canEdit() {
			if (this.admin) {
				return true
			} else {
				if (this.isAll) {
					return false
				} else {
					if (this.item.admin_id == this.plainadmin.id) {
						return true
					} else {
						return false
					}
				}
			}
		},
	},
	methods: {
		...mapActions(['setLoading', 'setAlert', 'checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout', 'getMyUsers']),
		close() {
			this.dialog = false
		},
		async deleteItem() {
			this.setLoading(true)
			await this.deleteFunction(this.item.id).then(() => {
				this.setAlert(false)
				this.setLoading(false)
				if (this.admin) {
					this.getMyUsers(0)
				} else {
					this.getMyUsers(this.plainadmin.id)
				}
				this.dialog = false
			}).catch(err => {
				this.setAlert(true)
				this.setLoading(false)
				console.error(err)
			})
		},
		async ensureAdmins() {
			if (this.admin) {
				this.ensureAdmin()
			} else {
				this.ensurePlainAdmin()
			}
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		async ensurePlainAdmin() {
			if (this.isPlainAdminAuthenticated) {
				await this.checkPlainAdmin().catch(() => {
					this.plainAdminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}
</script>