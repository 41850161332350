<template>
	<div>
		<v-alert
			:value="alert"
			dense
			outlined
			text
			prominent
			type="error"
			transition="scale-transition"
		>
			エラーが発生しました。ログアウト後再度ログインしてください。
		</v-alert>
		<v-data-table
			:headers="headers"
			:items="sets"
			:search="search"
			:items-per-page="10"
			:sort-by.sync="columnName"
			:sort-desc.sync="isDescending"
			:footer-props="{
				showFirstLastPage: true
			}"
			:loading="loading"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-spacer></v-spacer>
					<AdminNewSet/>
				</v-toolbar>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
			</template>
			<template v-slot:item.name="props">
				<v-edit-dialog
					large
					save-text="保存"
					cancel-text="キャンセル"
					@open="updateNameOpen(props.item.name)"
					@cancel="setAlert(false)"
					@save="updateName(props.item.id, editedName)"
					v-if="admin"
				>
					<div>{{ props.item.name }}</div>
					<template v-slot:input>
						<v-form ref="updateNameForm">
							<v-text-field
								v-model="editedName"
								:rules="[rules.required, rules.max20chars]"
								label="Edit"
								single-line
								counter
								maxlength="20"
							></v-text-field>
						</v-form>
					</template>
				</v-edit-dialog>
				<div v-else>{{ props.item.name }}</div>
			</template>
			<template v-slot:item.number_of_terms="{ item }">
				<AdminViewSet :item="item"/>
			</template>
			<template v-slot:item.created_at="{ item }">
				{{ formatDate(item.created_at) }}
			</template>
			<template v-slot:item.is_published="{ item }">
				<v-simple-checkbox
					v-model="item.is_published"
					v-ripple
					:disabled="loading || !admin"
					@input="updatePublished(item)"
				></v-simple-checkbox>
			</template>
			<template v-slot:item.delete="{ item }">
				<AdminDeleteButton :deleteFunction="deleteSet" :item="item" :isAll="true" :isCritical="true" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminNewSet from '@/components/AdminNewSet'
import AdminViewSet from '@/components/AdminViewSet'
import AdminDeleteButton from '@/components/AdminDeleteButton'

export default {
	name: 'AdminSets',
	components: {
		AdminNewSet,
		AdminViewSet,
		AdminDeleteButton
	},
	data () {
		return {
			columnName: 'id',
			isDescending: false,
			search: '',
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Name', value: 'name' },
				{ text: 'Number of terms', value: 'number_of_terms' },
				{ text: 'Created at', value: 'created_at' },
				{ text: 'Published', value: 'is_published' },
				{ text: 'Delete', value: 'delete' }
			],
			rules: {
				required: value => !!value || 'Required',
				max20chars: value => value.length <= 20 || 'Max 20 characters'
			},
			editedName: ''
		}
	},
	created () {
		if (this.isAdminAuthenticated) {
			this.getSets()
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			sets: 'stateSets',
			set: 'stateSet',
			res: 'stateSetRes',
			alert: 'stateAlert',
			loading: 'stateLoading',
			isAdminAuthenticated: 'isAdminAuthenticated'
		})
	},
	watch: {
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 1000)
			}
		}
	},
	methods: {
		...mapActions(['updateSetName', 'updateSetPublished', 'setLoading', 'setAlert', 'getSets', 'deleteSet']),
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		updateNameOpen(name) {
			this.ensureAdmin()
			this.setAlert(false)
			this.editedName = name
		},
		async updateName(id, name) {
			if (this.$refs.updateNameForm.validate()) {
				this.setLoading(true)
				const payload = {
					"id": id,
					"name": name
				}
				await this.updateSetName(payload).then(() => {
					this.setLoading(false)
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
			} else {
				this.setAlert(true)
			}
		},
		async updatePublished(item) {
			const payload = {
				"id": item.id,
				"is_published": item.is_published
			}
			this.setLoading(true)
			await this.updateSetPublished(payload).then(() => {
				this.setLoading(false)
				this.setAlert(false)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}
</script>
