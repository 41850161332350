<template>
	<v-text-field
		v-model="item.password"
		:type="show ? 'text' : 'password'"
		readonly
	>
		<template slot="append">
			<v-btn
				small
				icon
				:disabled="!canEdit(item)"
				@click="show = !show"
			>
				<v-icon>{{ show ? 'mdi-eye' : 'mdi-eye-off'}}</v-icon>
			</v-btn>
			<v-tooltip
				top
				:color="tooltipColor"
				v-model="tooltipShow"
			>
				<template v-slot:activator="{ attrs }">
					<v-btn
						small
						icon
						:disabled="!clipboard || !canEdit(item)"
						v-bind="attrs"
						@click="copyPassword(item.password)"
					>
						<v-icon>mdi-content-copy</v-icon>
					</v-btn>
				</template>
				<span>{{ tooltipMessage }}</span>
			</v-tooltip>
		</template>
	</v-text-field>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'AdminPasswordField',
	props: ['item'],
	data () {
		return {
			show: false,
			clipboard: false,
			tooltipShow: false,
			tooltipColor: 'success',
			tooltipMessage: 'Terms copied!'
		}
	},
	created () {
		if (navigator.clipboard) {
			this.clipboard = true
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin'
		})
	},
	methods: {
		copyPassword(password) {
			navigator.clipboard.writeText(password).then(() => {
				this.tooltipColor = 'success'
				this.tooltipMessage = 'Password copied!'
				this.tooltipShow = true
				setTimeout(() => {this.tooltipShow = false}, 1000)
			}).catch(err => {
				this.tooltipColor = 'error'
				this.tooltipMessage = 'Password not copied'
				this.tooltipShow = true
				setTimeout(() => {this.tooltipShow = false}, 1000)
				console.error(err)
			})
		},
		canEdit(user) {
			if (this.admin) {
				return true
			} else if (this.plainadmin.id == user.admin_id) {
				return true
			} else {
				return false
			}
		}
	}
}
</script>