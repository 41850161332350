<template>
	<div>
		<v-lazy
			v-model="isActive"
			:options="{
				threshold: 0.3
			}"
			min-height="200"
			transition="scroll-y-reverse-transition"
		>
		<v-card
			class="mx-auto"
			max-width="1200"
			tile
			flat
			:id="'card-' + index"
		>
			<v-card-title>
				<span class="index">{{ index }}</span>
				<v-spacer></v-spacer>
				<span class="text-h5 term">{{ term.term }}</span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-item-group
					v-model="answer"
					@change="selectAnswer(index)"
				>
					<v-container>
						<v-row>
							<v-col
								v-for="choice in choices"
								:key="choice.value"
								cols="12"
								lg="4"
							>
								<v-item
									v-slot="{ active, toggle }"
									:value="choice.value"
									:id="choice.value === 1 ? 'scroll-to-' + index : ''"
								>
									<v-card
										:color="active ? 'orange' : 'grey lighten-3'"
										class="d-flex align-center"
										:dark="active ? true : false"
										:light="active ? false: true"
										height="50"
										min-width="250"
										@click="toggle"
										flat
									>
										<div class="flex-grow-1 text-center">
											{{ choice.text }}
										</div>
									</v-card>
								</v-item>
							</v-col>
						</v-row>
					</v-container>
				</v-item-group>
				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
		</v-lazy>
		<v-divider></v-divider>
	</div>
</template>

<style scoped>
.term {
	font-family: "Helvetica Neue", "Helvetica", "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
	margin-left: -1em;
}
.index {
	font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
	font-size: 2em;
	font-weight: 600;
	color: lightgray;
}

.v-divider {
	border-width: 0.5px;
}

</style>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserAnswerCard',
	props: ['term', 'index'],
	data () {
		return {
			answer: undefined,
			choices: [
				{
					'value': 3,
					'text': '簡潔に説明できる'
				},
				{
					'value': 2,
					'text': '見たり聞いたりしたことがある'
				},
				{
					'value': 1,
					'text': '全く知らない'
				}
			],
			isActive: false,
			isMobile: false
		}
	},
	beforeDestroy() {
		if (typeof window === 'undefined') return
		window.removeEventListener('resize', this.onResize, { passive: true })
	},
	mounted() {
		this.onResize()
		window.addEventListener('resize', this.onResize, { passive: true })
	},
	computed: {
		...mapGetters({
			answers: 'stateAnswers',
			set: 'stateSet'
		})
	},
	methods: {
		...mapActions(['addAnswer']),
		selectAnswer(elementId) {
			const answer = {
				'termId': this.term.id,
				'answer': this.answer,
				"index": elementId
			}
			this.addAnswer(answer)

			if (this.answer !== undefined) {
				if (this.isMobile) {
					this.scrollTo(`scroll-to-${elementId}`)
				} else {
					if (elementId > 1) {
						this.scrollTo(`scroll-to-${elementId - 1}`)
					} else {
						this.scrollTo('desc')
					}
				}
			}
		},
		scrollTo(id) {
			smoothscroll.polyfill()
			document.getElementById(id).scrollIntoView({
				behavior: 'smooth'
			})
		},
		onResize () {
			this.isMobile = window.innerWidth < 1200
		}
	}
}
</script>
