<template>
	<v-dialog
		v-model="dialog"
		width="600"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="primary"
				:dark="!loading"
				:light="loading"
				class="mb-2"
				v-bind="attrs"
				v-on="on"
				:disabled="loading"
				@click.stop="ensureAdmins"
			>
				新しいテスト
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="text-h5">新しいテスト</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="newTestForm">
					<v-container>
						<v-row><v-col>
							<v-text-field
								v-model="editedTest.name"
								label="テスト名"
								:rules="[rules.required, rules.max20chars]"
								counter
								maxlength="20"
							></v-text-field>
						</v-col></v-row>
						<v-row><v-col>
							<v-select
								v-model="editedTest.curriculum_id"
								:items="curriculums"
								item-text="name"
								item-value="id"
								label="専門分野"
								:rules="[rules.required]"
							></v-select>
						</v-col></v-row>
						<v-row><v-col>
							<v-select
								v-model="editedTest.termbank_id"
								:items="termBanks"
								item-text="name"
								item-value="id"
								label="ターム集"
								:rules="[rules.required]"
							></v-select>
						</v-col></v-row>
						<v-row><v-col>
							<v-select
								v-model="editedTest.sets"
								:items="sets"
								item-text="name"
								item-value="id"
								label="セット"
								chips
								multiple
								:error-messages="errors"
							></v-select>
						</v-col></v-row>
						<v-row><v-col>
							<v-select
								v-model="editedTest.scale_id"
								label="調査票"
								:items="scales"
								item-text="name"
								item-value="id"
							></v-select>
						</v-col></v-row>
						<v-row><v-col>
							<v-select
								v-model="editedTest.survey_type"
								label="フェイスシートの種類"
								:items="surveyTypes"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-col></v-row>
						<v-row><v-col>
							<v-checkbox
								v-model="editedTest.is_published"
								label="公開"
							></v-checkbox>
						</v-col></v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="close"
				>
					キャンセル
				</v-btn>
				<v-btn
					color="blue darken-1"
					text
					:loading="loading"
					@click="save"
				>
					保存
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>{{ testRes.name }}</v-card-title>
				<v-card-text>
					テストを作成しました。
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminNewTest',
	data () {
		return {
			dialog: false,
			resDialog: false,
			newTest: {
				name: '',
				termbank_id: null,
				curriculum_id: null,
				sets: [],
				scale_id: null,
				survey_type: null,
				is_published: false
			},
			editedTest: {
				name: '',
				termbank_id: null,
				curriculum_id: null,
				sets: [],
				scale_id: null,
				survey_type: null,
				is_published: false
			},
			rules: {
				required: value => !!value || 'Required',
				max20chars: value => value.length <= 20 || 'Max 20 characters'
			},
			sets: [],
			errors: [],
			surveyTypes: [
				{ text: '学生用', value: 1},
				{ text: '司書用', value: 2},
				{ text: '学生動機調査用', value: 3},
				{ text: '卒業生動機調査用', value: 4},
				{ text: '卒業生学習内容調査用', value: 5},
			]
		}
	},
	async created () {
		if (this.isAdminAuthenticated || this.isPlainAdminAuthenticated) {
			await this.getScales()
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin',
			loading: 'stateLoading',
			testRes: 'stateTestRes',
			termBanks: 'stateTermBanks',
			curriculums: 'stateCurriculums',
			scales: 'stateScales',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated'
		})
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.$refs.newTestForm.resetValidation()
				this.$nextTick(() => {
					this.editedTest = Object.assign({}, this.newTest)
				})
				this.setLoading(false)
			}
			if (value === true) {
				this.getTermBanks()
				this.getCurriculums()
				this.setAlert(false)
				this.errors = []
			}
		},
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 2000)
			}
		},
		'editedTest.termbank_id': async function(value) {
			if (value !== null) {
				this.sets = await this.getSetsByTermBank(value)
			} else {
				this.sets = []
			}
		},
		'editedTest.sets': function(value) {
			if (value.length > 0) {
				this.errors = []
			}
		},
	},
	methods: {
		...mapActions(['createTest', 'setLoading', 'setAlert', 'getTermBanks', 'getCurriculums', 'getScales', 'getSetsByTermBank', 'checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout']),
		close() {
			this.dialog = false
			this.setLoading(false)
		},
		async save() {
			if (this.$refs.newTestForm.validate()) {
				if (this.editedTest.sets.length > 0 || this.editedTest.survey_type === 4) {
					this.errors = []
					this.setLoading(true)
					const test = {
						"name": this.editedTest.name,
						"sets": this.editedTest.sets,
						"scales": [this.editedTest.scale_id],
						"termbank_id": this.editedTest.termbank_id,
						"curriculum_id": this.editedTest.curriculum_id,
						"survey_type": this.editedTest.survey_type,
						"is_published": this.editedTest.is_published
					}
					await this.createTest(test).then(() => {
						this.setAlert(false)
					}).catch(err => {
						this.setLoading(false)
						this.setAlert(true)
						console.error(err)
					})
					this.setLoading(false)
					this.close()
					this.resDialog = true
				} else {
					this.errors = ['Required']
				}
			}
		},
		closeResDialog() {
			this.resDialog = false
		},
		async ensureAdmins() {
			if (this.admin) {
				await this.ensureAdmin()
			} else {
				await this.ensurePlainAdmin()
			}
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		async ensurePlainAdmin() {
			if (this.isPlainAdminAuthenticated) {
				await this.checkPlainAdmin().catch(() => {
					this.plainAdminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}
</script>