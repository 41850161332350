<template>
	<v-container class="mt-16">
		<v-row>
			<v-col>
				<AdminDashboardCard
					:title="`PulicSurveyAnswers`"
					:items="publicsurveyanswers"
					:pageLink="`/admin/publicsurveyanswers`"
				/>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminDashboardCard from '@/components/AdminDashboardCard'

export default {
	name: 'AdminDashboard',
	components: {
		AdminDashboardCard
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin',
			publicsurveyanswers: 'statePublicSurveyAnswers',
		})
	},
	async created () {
		this.setLoading(true)
		await this.update().then(() => {
			this.setLoading(false)
		}).catch(() => {
			this.setLoading(false)
		})
	},
	methods: {
		...mapActions(['setLoading', 'getPublicSurveyAnswers']),
		async update() {
			await this.getPublicSurveyAnswers()
		}
	}
}
</script>
