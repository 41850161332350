<template>
	<v-container>
		<v-row>
			<v-col>
				<v-card
					class="mx-auto"
					max-width="400"
					tile
					flat
				>
					<p class="mt-5 mb-5 text-center">
						以下の実験／調査に参加できます。<br/>
						問題を選んで回答を始めてください。<br/>
					</p>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<UserTests/>
			</v-col>
		</v-row>
		<v-row class="mt-16" v-if="!isExperimenting">
			<v-col>
				<v-btn
					large
					depressed
					class="mt-16"
					width="330"
					@click="logout"
				>
						ログアウト
				</v-btn>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import UserTests from '@/components/UserTests'

export default {
	name: 'UserExperiments',
	components: {
		UserTests
	},
	created () {
		this.clearSet()
		this.clearSets()
		this.clearTerms()
		this.clearPublishedSets()
		this.clearAnswers()
		this.clearAllAnswers()
		this.clearAnsweredSets()
		this.clearAnswerScore()
		this.setNotAnswered(this.set.terms)
		this.clearScore()
		this.clearScores()
		this.clearCourses()
		this.setLoading(false)
		this.setAlert(false)
		this.clearTestSetAnswered()
		this.clearSurveyAnswered()
		this.clearLibSurveyAnswered(),
		this.clearMotSurveyAnswered(),
		this.clearAlumniSurveyAnswered(),
		this.clearAnsweredScales(),
		this.clearScaleItemAnswers()
	},
	computed: {
		...mapGetters({ set: 'stateSet' }),
		isExperimenting() {
			const isExperimenting = process.env.VUE_APP_IS_EXPERIMENTING
			if (isExperimenting === 'true') {
				return true
			} else {
				return false
			}
		}
	},
	methods: {
		...mapActions(['clearSets', 'clearSet', 'clearTerms', 'clearPublishedSets', 'setLoading', 'setAlert', 'clearAllAnswers', 'clearAnsweredSets', 'clearAnswers', 'setNotAnswered', 'clearScore', 'clearScores', 'clearAnswerScore', 'userLogout', 'clearTestSetAnswered', 'clearSurveyAnswered', 'clearLibSurveyAnswered', 'clearCourses', 'clearMotSurveyAnswered', 'clearAlumniSurveyAnswered', 'clearAnsweredScales', 'clearScaleItemAnswers']),
		logout() {
			this.userLogout().then(() => {
				this.$router.push('/')
			})
		}
	}
}
</script>