import axios from 'axios'


const state = {
	userGroups: [],
    userGroup: {
        "tests": [],
        "users": []
    },
    userGroupRes: {
        "id": null,
        "name": ""
    }
}

const getters = {
    stateUserGroup: state => state.userGroup,
	stateUserGroups: state => state.userGroups,
    stateUserGroupRes: state => state.userGroupRes
}

const actions = {
	async createUserGroup({dispatch, commit}, payload) {
		let {data} = await axios.post('/usergroups/', payload)
		commit('setUserGroupRes', data)
		await dispatch('getUserGroups')
	},
	async getUserGroups({commit}) {
		let {data} = await axios.get('/usergroups/')
		commit('setUserGroups', data)
	},
	async getUserGroup({commit}, id) {
		let {data} = await axios.get(`/usergroups/${id}`)
		commit('setUserGroup', data)
	},
	async updateUserGroup({dispatch}, payload) {
        const newPayload = {
            "name": payload.name,
            "tests": payload.tests,
            "users": payload.users
        }
		await axios.put(`/usergroups/${payload.id}`, newPayload)
		await dispatch('getUserGroups')
	},
	async deleteUserGroup({dispatch}, id) {
		await axios.delete(`/usergroups/${id}`)
		await dispatch('getUserGroups')
	},
	clearUserGroups({commit}) {
		commit('setUserGroups', [])
	},
	clearUserGroup({commit}) {
		const payload = {
			"tests": [],
            "users": []
		}
		commit('setUserGroup', payload)
	},
    clearUserGroupRes({commit}) {
        commit('setUserGroupRes', {"id": null})
    }
}

const mutations = {
	setUserGroups(state, data) {
		state.userGroups = data
	},
	setUserGroup(state, data) {
		state.userGroup = data
	},
	setUserGroupRes(state, data) {
		state.userGroupRes = data
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}
