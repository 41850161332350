<template>
	<v-dialog
		v-model="dialog"
		width="600"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="primary"
				:dark="!loading"
				:light="loading"
				class="mb-2"
				v-bind="attrs"
				v-on="on"
				:disabled="loading"
				@click.stop="ensureAdmin"
			>
				新しい調査票
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="text-h5">新しい調査票</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="newScaleForm">
					<v-container>
						<v-row><v-col>
							<v-text-field
								v-model="editedScale.name"
								label="調査票名（必須）"
								:rules="[rules.required, rules.max20chars]"
								:error-messages="grouperrors"
								:error-count="1"
								counter
								maxlength="20"
							></v-text-field>
						</v-col></v-row>
						<v-row><v-col>
							<v-textarea
								v-model="editedScale.scaleitems"
								label="項目（改行区切り）"
								:error-messages="errors"
								:error-count="1"
							></v-textarea>
						</v-col></v-row>
						<v-row>
							<v-spacer></v-spacer>
							<v-col>
								<v-btn @click="loadLISScaleItems">
									MSLCをロード
								</v-btn>
							</v-col>
							<v-col>
								<v-btn @click="loadLISScaleItemsPast">
									MSLC（社会人向け）をロード
								</v-btn>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="close"
				>
					キャンセル
				</v-btn>
				<v-btn
					color="blue darken-1"
					text
					:loading="loading"
					@click="save"
				>
					保存
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>{{ scaleRes.name }}</v-card-title>
				<v-card-text>
					{{ numberOfScaleItems }}項目を追加しました。<br/><br/>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminNewScale',
	data () {
		return {
			dialog: false,
			resDialog: false,
			newScale: {
				name: '',
				scaleitems: ''
			},
			editedScale: {
				name: '',
				scaleitems: ''
			},
			rules: {
				required: value => !!value || 'Required',
				max20chars: value => value.length <= 20 || 'Max 20 characters'
			},
			errors: [],
			grouperrors: []
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin',
			loading: 'stateLoading',
			formattedScaleItems: 'stateFormattedScaleItems',
			scaleitems: 'stateScaleItems',
			numberOfScaleItems: 'stateNumberOfScaleItems',
			scaleRes: 'stateScaleRes',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated'
		})
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.$refs.newScaleForm.resetValidation()
				this.$nextTick(() => {
					this.editedScale = Object.assign({}, this.newScale)
				})
				this.setLoading(false)
			}
			if (value === true) {
				this.setAlert(false)
				this.errors = []
				this.grouperrors = []
			}
		},
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 2000)
			}
		}
	},
	methods: {
		...mapActions(['addScaleItems', 'setLoading', 'setAlert', 'formatScaleItems', 'clearFormattedScaleItems', 'checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout', 'createScale', 'clearScale', 'clearScaleRes', 'getScales', 'formatScaleItems', 'getLISScaleItems', 'getLISScaleItemsPast']),
		close() {
			this.dialog = false
			this.setLoading(false)
		},
		closeResDialog() {
			this.resDialog = false
			this.clearFormattedScaleItems()
		},
		async save() {
			if (this.$refs.newScaleForm.validate()) {
				this.setLoading(true)
				await this.formatScaleItems(this.editedScale.scaleitems).then(() => {
					this.errors = []
				}).catch(err => {
					this.setLoading(false)
					this.errors = ['Wrong input format']
					this.clearFormattedScaleItems()
					console.error(err)
				})
				this.setLoading(false)
				if (this.formattedScaleItems.length) {
					this.setLoading(true)
					const payload = {
						"name": this.editedScale.name,
						"scaleitems": this.formattedScaleItems
					}
					await this.createScale(payload).then(() => {
						this.setAlert(false)
					}).catch(err => {
						this.setLoading(false)
						this.setAlert(true)
						console.error(err)
					})
					this.setLoading(false)
					this.close()
					this.resDialog = true
				}
			}
		},
		async loadLISScaleItems() {
			let scaleitems = await this.getLISScaleItems()
			this.editedScale.name = '司書課程の履修動機'
			this.editedScale.scaleitems = scaleitems.join('\n')
		},
		async loadLISScaleItemsPast() {
			let scaleitems = await this.getLISScaleItemsPast()
			this.editedScale.name = '司書課程の履修動機（社会人向け）'
			this.editedScale.scaleitems = scaleitems.join('\n')
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}

</script>