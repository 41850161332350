<template>
	<div>
		<v-app-bar
				:dark="isDark"
				elevate-on-scroll
				app
			>
				<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
				<v-toolbar-title>{{ $route.name }}</v-toolbar-title>
			</v-app-bar>

			<v-navigation-drawer
				v-model="drawer"
				app
			>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-h6">
							Menu
						</v-list-item-title>
					</v-list-item-content>
					<v-list-item-icon v-if="isAdminAuthenticated || isPlainAdminAuthenticate">
						<v-btn
							icon
							@click="logout"
						>
							<v-icon>mdi-logout</v-icon>
						</v-btn>
					</v-list-item-icon>
				</v-list-item>

				<v-divider></v-divider>

				<v-list
					nav
					dense
				>
					<v-list-item
						v-for="item in items"
						:key="item.title"
						:to="item.link"
						link
					>
						<v-list-item-icon>
							<v-icon>{{ item.icon }}</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ item.title }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item
						to="/admin/admins"
						link
						v-if="isVisible"
					>
						<v-list-item-icon>
							<v-icon>mdi-account-check</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Admins</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-divider></v-divider>
					<v-list-item to="/" link>
						<v-list-item-icon>
							<v-icon>mdi-arrow-u-left-top</v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>Home</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-navigation-drawer>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminNavBar',
	data: () => ({
		drawer: true,
		items: [
			{
				title: 'Dashboard',
				icon: 'mdi-view-dashboard',
				link: '/admin/dashboard',
			},
			{
				title: 'PublicSurveyAnswers',
				icon: 'mdi-text-box-outline',
				link: '/admin/publicsurveyanswers',
			},
		],
	}),
	computed: {
		...mapGetters({
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticate: 'isPlainAdminAuthenticated',
			admin: 'stateAdmin',
			plainAdmin: 'statePlainAdmin'
		}),
		isDark() {
			if (this.admin || (!this.admin && !this.plainAdmin)) {
				return true
			} else if (!this.admin && this.plainAdmin) {
				return false
			} else {
				return true
			}
		},
		isVisible() {
			if (this.admin) { return true } else { return false }
		}
	},
	methods: {
		...mapActions(['adminLogout', 'plainAdminLogout']),
		logout() {
			if (this.isAdminAuthenticated) {
					this.adminLogout().then(() => {
					this.$router.push('/admin/login')
				})
			} else if (this.isPlainAdminAuthenticate) {
				this.plainAdminLogout().then(() => {
					this.$router.push('/admin/login')
				})
			}
		}
	}
}
</script>
