<template>
	<div>
		<v-alert
			:value="alert"
			dense
			outlined
			text
			prominent
			type="error"
			transition="scale-transition"
		>
			エラーが発生しました。ログアウト後再度ログインしてください。
		</v-alert>
		<v-data-table
			:headers="headers"
			:items="libSurveyAnswers"
			:search="search"
      :custom-filter="filter"
			:item-per-page="10"
			:sort-by.sync="columnName"
			:sort-desc.sync="isDescending"
			:footer-props="{
				showFirstLastPage: true
			}"
			:loading="loading"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-container>
						<v-row>
							<v-spacer></v-spacer>
							<v-btn
								@click="download"
								:disabled="loading || !libSurveyAnswers.length || !admin"
							>
								結果をダウンロード
							</v-btn>
						</v-row>
					</v-container>
				</v-toolbar>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
			</template>
			<template v-slot:item.library_type="{ item }">
				{{ getLibraryTypeText(item.library_type) }}
			</template>
			<template v-slot:item.working_years="{ item }">
				{{ getWorkingYearsText(item.working_years) }}
			</template>
			<template v-slot:item.certification_state="{ item }">
				{{ getCertificationStateText(item.certification_state) }}
			</template>
			<template v-slot:item.test="{ item }">
				{{ tests.filter(v => v.id == item.test_id)[0].name }}
			</template>
			<template v-slot:item.user="{ item }">
				{{ users.filter(v => v.id == item.user_id)[0].username }}
			</template>
			<template v-slot:item.created_at="{ item }">
				{{ formatDate(item.created_at) }}
			</template>
			<template v-slot:item.delete="{ item }">
				<AdminDeleteButton :deleteFunction="deleteLibSurveyAnswer" :item="item" :isAll="true" :isCritical="true" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminDeleteButton from '@/components/AdminDeleteButton'

export default {
	name: 'AdminLibSurveyAnswers',
	components: {
		AdminDeleteButton
	},
	data () {
		return {
			search: '',
			columnName: 'id',
			isDescending: false,
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Library', value: 'library_type' },
				{ text: 'Working years', value: 'working_years' },
				{ text: 'Certification', value: 'certification_state' },
				{ text: 'Test', value: 'test' },
				{ text: 'User', value: 'user' },
				{ text: 'Created at', value: 'created_at' },
				{ text: 'Delete', value: 'delete' }
			]
		}
	},
	async created () {
		await this.getLibSurveyAnswers()
		await this.getUsers()
		await this.getTests()
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			libSurveyAnswers: 'stateLibSurveyAnswers',
			loading: 'stateLoading',
			alert: 'stateAlert',
			isAdminAuthenticated: 'isAdminAuthenticated',
			tests: 'stateTests',
			users: 'stateUsers',
		})
	},
	methods: {
		...mapActions(['getLibSurveyAnswers', 'downloadLibSurveyAnswers', 'deleteLibSurveyAnswer', 'getUsers', 'getTests', 'setLoading', 'setAlert', 'checkAdmin', 'adminLogout']),
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		getLibraryTypeText(value) {
			if (value === 1) return '公共図書館'
			else if (value === 2) return '大学図書館'
			else if (value === 3) return '学校図書館'
			else if (value === 4) return '専門図書館'
			else return 'その他'
		},
		getWorkingYearsText(value) {
			if (value === 1) return '0〜5年'
			else if (value === 2) return '6〜10年'
			else if (value === 3) return '11年以上'
			else return '回答しない'
		},
		getCertificationStateText(value) {
			if (value === 1) return '有'
			else return '無'
		},
    filter(value, search, item) {
      let inLibrary = RegExp(search, 'i').test(this.getLibraryTypeText(item.library_type))
      let inWorkingYears = RegExp(search, 'i').test(this.getWorkingYearsText(item.working_years))
      let inCert = RegExp(search, 'i').test(this.getCertificationStateText(item.certification_state))
      let inTest = RegExp(search, 'i').test(this.tests.filter(v => v.id == item.test_id)[0].name)
      let inUser = RegExp(search, 'i').test(this.users.filter(v => v.id == item.user_id)[0].username)

      return inLibrary || inWorkingYears || inCert || inTest || inUser
    },
		async download() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}

			if (this.isAdminAuthenticated) {
				this.setLoading(true)
				await this.downloadLibSurveyAnswers().then((response) => {
					let blob = new Blob([response.data], { type: 'text/tsv' })
					let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
					let url = window.URL.createObjectURL(blob)
					let dllink = document.createElement('a')
					dllink.href = url
					dllink.setAttribute('download', filename)
					dllink.click()
					this.setLoading(false)
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
			}
		}
	}
}
</script>
