<template>
	<div>
		<p class="mt-10 mb-10">
			ページが見つかりません。
		</p>
		<v-btn
			to="/"
			large
			depressed
			class="mt-16"
			width="330"
		>
			戻る
		</v-btn>
	</div>
</template>

<script>
export default {
	name: 'NotFound'
}
</script>

<style>

</style>