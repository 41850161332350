<template>
	<v-container>
		<v-row>
			<v-col>
				<div class="mt-5">
					<h1>
						基本情報
					</h1>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-alert
					:value="alert"
					dense
					outlined
					text
					prominent
					type="error"
					transition="scale-transition"
				>
					回答を記録できませんでした。
				</v-alert>
				<v-form ref="surveyForm">
					<v-container class="mx-auto col-lg-6 col-md-8 col-sm-8 col-xs-10">
						<v-row>
							<v-select
								v-model="form.libraryType"
								label="勤務先の館種"
								:items="libraryTypes"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row>
							<v-select
								v-model="form.workingYears"
								label="勤続年数"
								:items="workingYears"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row>
							<v-select
								v-model="form.certificationState"
								label="司書資格の有無"
								:items="certificationStates"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row class="mt-10">
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-2"
								dark
								depressed
								x-large
								:loading="loading"
								@click="post"
							>回答する</v-btn>
							<v-spacer></v-spacer>
						</v-row>
					</v-container>
				</v-form>
			</v-col>
		</v-row>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>回答を記録しました</v-card-title>
				<v-card-text>
					<v-alert
						dense
						outlined
						text
						class="mt-10"
						type="success"
					>
						次へ進んでください。
					</v-alert>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserStudentSurvey',
	data () {
		return {
			form: {
				libraryType: null,
				workingYears: null,
				certificationState: null,
			},
			rules: {
				required: value => !!value || '必須'
			},
			libraryTypes: [
				{ text: '公共図書館', value: 1 },
				{ text: '大学図書館', value: 2 },
				{ text: '学校図書館', value: 3 },
				{ text: '専門図書館', value: 4 },
				{ text: 'その他', value: 99 },
			],
			workingYears: [
				{ text: '0〜5年', value: 1 },
				{ text: '6〜10年', value: 2 },
				{ text: '11年以上', value: 3 },
			],
			certificationStates: [
				{ text: '有', value: 1 },
				{ text: '無', value: 2 },
			],
			resDialog: false
		}
	},
	async created () {
		this.setAlert(false)
	},
	computed: {
		...mapGetters({
			userId: 'stateUserId',
			loading: 'stateLoading',
			alert: 'stateAlert',
			testAnswer: 'stateTestAnswer'
		}),
		testId() {
			return this.$route.params.id
		}
	},
	methods: {
		...mapActions(['setLoading', 'setAlert', 'postLibSurveyAnswer']),
		async post() {
			if (this.$refs.surveyForm.validate()) {

				this.setLoading(true)
				const payload = {
					'library_type': this.form.libraryType,
					'working_years': this.form.workingYears,
					'certification_state': this.form.certificationState,
					'user_id': this.userId,
					'test_id': this.testId,
					'testanswer_id': this.testAnswer.id
				}
				await this.postLibSurveyAnswer(payload).then(() => {
					this.setAlert(false)
					this.resDialog = true
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
				this.setLoading(false)
			}
		},
		closeResDialog() {
			this.resDialog = false
			this.$router.push({ name: 'Test', params: {id: this.testId }})
		}
	}
}
</script>