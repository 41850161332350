<template>
	<div>
		<v-alert
			:value="alert"
			dense
			outlined
			text
			prominent
			type="error"
			transition="scale-transition"
		>
			エラーが発生しました。ログアウト後再度ログインしてください。
		</v-alert>
		<CoursesTable :courses="courses"/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import CoursesTable from '@/components/CoursesTable'

export default {
	name: 'AdminCourses',
	components: {
		CoursesTable
	},
	data () {
		return {
			columnName: 'id',
			isDescending: false,
			search: '',
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Name', value: 'name' },
				{ text: 'Created at', value: 'created_at' },
			],
		}
	},
	created () {
		if (this.isAdminAuthenticated || this.isPlainAdminAuthenticated) {
			this.getCourses()
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			courses: 'stateCourses',
			alert: 'stateAlert',
			loading: 'stateLoading',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated'
		}),
		isDark() {
			if (!this.loading && this.admin) {
				return true
			} else {
				return false
			}
		},
		isDisabled() {
			if (this.loading || !this.admin) {
				return true
			} else {
				return false
			}
		}
	},
	watch: {
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 1000)
			}
		}
	},
	methods: {
		...mapActions(['setLoading', 'setAlert', 'getCourses', 'registerCourses', 'checkAdmin', 'adminLogout']),
		async register() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}

			if (this.isAdminAuthenticated) {
				this.setAlert(false)
				this.setLoading(true)
				await this.registerCourses().catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
				this.setLoading(false)
			}
		}
	}
}
</script>