<template>
	<v-dialog
		v-model="dialog"
		width="800"
		scrollable
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="blue-grey"
				dark
				v-bind="attrs"
				v-on="on"
			>{{ item.number_of_sets }}</v-btn>
		</template>
		<v-card>
			<v-card-title>
				{{ item.name }}
			</v-card-title>
			<v-card-text>
				<v-data-table
					:headers="headers"
					:items="item.sets"
					:sort-by.sync="columnName"
					:sort-desc.sync="isDescending"
					:loading="loading"
				>
				<template v-slot:item.number_of_terms="{ item }">
					<AdminViewSet :item="item"/>
				</template>
				</v-data-table>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="close"
				>
					OK
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import AdminViewSet from '@/components/AdminViewSet'

export default {
	name: 'AdminViewTestSets',
	props: ['item'],
	components: {
		AdminViewSet
	},
	data () {
		return {
			dialog: false,
			columnName: 'id',
			isDescending: false,
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Name', value: 'name' },
				{ text: 'Number of terms', value: 'number_of_terms' }
			],
		}
	},
	computed: {
		...mapGetters({ loading: 'stateLoading' })
	},
	methods: {
		close() {
			this.dialog = false
		}
	}
}
</script>