<template>
	<v-dialog
		v-model="dialog"
		width="600"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="blue-grey"
				:dark="!loading && canEdit"
				:light="loading || !canEdit"
				v-bind="attrs"
				v-on="on"
				:disabled="loading || !canEdit"
				@click.stop="ensureAdmins"
			>
				{{ item.name }}
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="text-h5">{{ item.name }}</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="newUserGroupForm">
					<v-container>
						<v-row><v-col>
							<v-text-field
								v-model="editedUserGroup.name"
								label="ユーザーグループ名（必須）"
								:rules="[rules.required, rules.max20chars]"
								:error-messages="grouperrors"
								:error-count="1"
								counter
								maxlength="20"
							></v-text-field>
						</v-col></v-row>
						<v-row><v-col>
							<v-select
								v-model="editedUserGroup.tests"
								:items="tests"
								item-text="name"
								item-value="id"
								label="テスト"
								chips
								multiple
							></v-select>
						</v-col></v-row>
						<v-row><v-col>
							<v-select
								v-model="editedUserGroup.users"
								:items="users"
								item-text="username"
								item-value="id"
								label="ユーザー"
								chips
								multiple
							></v-select>
						</v-col></v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="close"
				>
					キャンセル
				</v-btn>
				<v-btn
					color="blue darken-1"
					text
					:loading="loading"
					@click="save"
				>
					保存
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>{{ item.name }}</v-card-title>
				<v-card-text>
					ユーザーグループを更新しました。<br/><br/>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminEditUserGroup',
	props: ['item'],
	data () {
		return {
			isAll: false,
			dialog: false,
			resDialog: false,
			editedUserGroup: {
				name: this.item.name,
				tests: this.item.tests,
				users: this.item.users
			},
			rules: {
				required: value => !!value || 'Required',
				max20chars: value => value.length <= 20 || 'Max 20 characters'
			},
			grouperrors: []
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin',
			loading: 'stateLoading',
			formattedUsers: 'stateFormattedUsers',
			users: 'stateMyUsers',
			numberOfUsers: 'stateNumberOfUsers',
			tests: 'stateTests',
			userGroupRes: 'stateUserGroupRes',
			userGroups: 'stateUserGroups',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated'
		}),
		canEdit() {
			if (this.admin) {
				return true
			} else {
				if (this.isAll) {
					return false
				} else {
					if (this.item.admin_id == this.plainadmin.id) {
						return true
					} else {
						return false
					}
				}
			}
		}
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.$refs.newUserGroupForm.resetValidation()
				const item = this.userGroups.find(x => x.id === this.item.id)
				this.$nextTick(() => {
					this.editedUserGroup = Object.assign({}, {
						"name": item.name,
						"tests": item.tests,
						"users": item.users
					})
				})
				this.setLoading(false)
			}
			if (value === true) {
				this.setAlert(false)
				this.grouperrors = []
			}
		},
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 2000)
			}
		}
	},
	methods: {
		...mapActions(['addUsers', 'setLoading', 'setAlert', 'formatUsers', 'clearFormattedUsers', 'checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout', 'createUserGroup', 'clearUserGroup', 'clearUserGroupRes', 'getUserGroups', 'clearUsersRes', 'clearNumberOfUsers', 'updateUserGroup', 'getMyUsers', 'getTests']),
		close() {
			this.dialog = false
			this.setLoading(false)
		},
		async save() {
			if (this.$refs.newUserGroupForm.validate()) {
				let testIds = []
				let userIds = []
				for (let test of this.editedUserGroup.tests) {
					if (typeof(test) === "number") {
						testIds.push(test)
					} else {
						testIds.push(test.id)
					}
				}
				for (let user of this.editedUserGroup.users) {
					if (typeof(user) === "number") {
						userIds.push(user)
					} else {
						userIds.push(user.id)
					}
				}
				this.setLoading(true)
				const payload = {
					"id": this.item.id,
					"name": this.editedUserGroup.name,
					"tests": testIds,
					"users": userIds
				}
				await this.updateUserGroup(payload).then(() => {
					this.grouperrors = []
					this.setAlert(false)
					this.setLoading(true)
					this.close()
					this.resDialog = true
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					this.grouperros = ['An error occured. Please try again later.']
					console.error(err)
				})
			}
			this.getUserGroups()
			if (this.admin) {
				this.getMyUsers(0)
			} else {
				this.getMyUsers(this.plainadmin.id)
			}
		},
		closeResDialog() {
			this.resDialog = false
			this.clearFormattedUsers()
			this.clearUserGroupRes()
			this.clearUsersRes()
			this.clearNumberOfUsers()
		},
		async ensureAdmins() {
			if (this.admin) {
				this.ensureAdmin()
			} else {
				this.ensurePlainAdmin()
			}
			if (this.admin) {
				this.getMyUsers(0)
			} else {
				this.getMyUsers(this.plainadmin.id)
			}
			this.getTests()
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		async ensurePlainAdmin() {
			if (this.isPlainAdminAuthenticated) {
				await this.checkPlainAdmin().catch(() => {
					this.plainAdminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}
</script>