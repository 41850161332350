<template>
	<div>
		<v-alert
			:value="alert"
			dense
			outlined
			text
			prominent
			type="error"
			transition="scale-transition"
		>
			エラーが発生しました。ログアウト後再度ログインしてください。
		</v-alert>
		<v-data-table
			:headers="headers"
			:items="users"
			:search="search"
			:items-per-page="10"
			:sort-by.sync="columnName"
			:sort-desc.sync="isDescending"
			:footer-props="{
				showFirstLastPage: true
			}"
			:loading="loading"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-container>
						<v-row>
							<v-spacer></v-spacer>
							<v-btn
								class="mr-5"
								@click="download"
								:disabled="loading || !myUsers.length"
							>
								ユーザーをダウンロード
							</v-btn>
							<AdminNewUsers/>
						</v-row>
					</v-container>
				</v-toolbar>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
			</template>
			<template v-slot:item.password="{ item }">
				<AdminPasswordField :item="item" />
			</template>
			<template v-slot:item.created_at="{ item }">
				{{ formatDate(item.created_at) }}
			</template>
			<template v-slot:item.is_active="{ item }">
				<v-simple-checkbox
					v-model="item.is_active"
					v-ripple
					:disabled="loading || !canEdit(item)"
					@input="updateActive(item)"
				></v-simple-checkbox>
			</template>
			<template v-slot:item.delete="{ item }">
				<AdminDeleteButton :deleteFunction="deleteUser" :item="item" :isAll="false" :isCritical="true" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminNewUsers from '@/components/AdminNewUsers'
import AdminDeleteButton from '@/components/AdminDeleteButton'
import AdminPasswordField from '@/components/AdminPasswordField'

export default {
	name: 'AdminUsers',
	components: {
		AdminNewUsers,
		AdminDeleteButton,
		AdminPasswordField
	},
	data () {
		return {
			columnName: 'id',
			isDescending: false,
			search: '',
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Name', value: 'username' },
				{ text: 'Password', value: 'password' },
				{ text: 'Number of answers', value: 'testanswers.length' },
				{ text: 'Created at', value: 'created_at' },
				{ text: 'Active', value: 'is_active' },
				{ text: 'Delete', value: 'delete' }
			]
		}
	},
	async created () {
		if (this.isAdminAuthenticated || this.isPlainAdminAuthenticated) {
			await this.getUserUsers()
			if (this.admin) {
				await this.getMyUsers(0)
			} else {
				await this.getMyUsers(this.plainadmin.id)
			}
		}
	},
	computed: {
		...mapGetters({
			users: 'stateUserUsers',
			alert: 'stateAlert',
			loading: 'stateLoading',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated',
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin',
			myUsers: 'stateMyUsers'
		})
	},
	watch: {
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 1000)
			}
		}
	},
	methods: {
		...mapActions(['setLoading', 'setAlert', 'getUserUsers', 'updateUserActive', 'downloadUsers', 'deleteUser', 'checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout', 'getMyUsers']),
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		canEdit(user) {
			if (this.admin) {
				return true
			} else if (this.plainadmin.id == user.admin_id) {
				return true
			} else {
				return false
			}
		},
		async updateActive(item) {
			const payload = {
				"id": item.id,
				"is_active": item.is_active
			}
			this.setLoading(true)
			await this.updateUserActive(payload).then(() => {
				this.setLoading(false)
				this.setAlert(false)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		async download() {
			if (this.admin) {
				this.ensureAdmin()
			} else {
				this.ensurePlainAdmin()
			}

			if (this.admin || this.plainadmin) {
				this.setLoading(true)
				await this.downloadUsers({"role": 1, "usergroup_id": 0}).then((response) => {
					let blob = new Blob([response.data], { type: 'text/tsv' })
					let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
					let url = window.URL.createObjectURL(blob)
					let dllink = document.createElement('a')
					dllink.href = url
					dllink.setAttribute("download", filename)
					dllink.click()
					this.setLoading(false)
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
			}
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		async ensurePlainAdmin() {
			if (this.isPlainAdminAuthenticated) {
				await this.checkPlainAdmin().catch(() => {
					this.plainAdminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}
</script>
