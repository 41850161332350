import axios from 'axios'


const state = {
	surveyAnswered: [],
	surveyAnswers: []
}

const getters = {
	stateSurveyAnswered: state => state.surveyAnswered,
	stateSurveyAnswers: state => state.surveyAnswers
}

const actions = {
	async postSurveyAnswer({commit}, payload) {
		await axios.post('/surveyanswers/', payload)
		commit('appendSurveyAnswered', payload.test_id)
	},
	async getSurveyAnswers({commit}) {
		let {data} = await axios.get('/surveyanswers/')
		commit('setSurveyAnswers', data)
	},
	async deleteSurveyAnswer({dispatch}, id) {
		await axios.delete(`/surveyanswers/${id}`)
		await dispatch('getSurveyAnswers')
	},
	async downloadSurveyAnswers() {
		return await axios.get('/surveyanswers/download/', { responseType: 'blob' })
	},
	clearSurveyAnswered({commit}) {
		commit('setSurveyAnswered', [])
	},
	clearSurveyAnswers({commit}) {
		commit('setSurveyAnswers', [])
	}
}

const mutations = {
	setSurveyAnswers(state, survey) {
		state.surveyAnswers = survey
	},
	setSurveyAnswered(state, survey) {
		state.surveyAnswered = survey
	},
	appendSurveyAnswered(state, testId) {
		state.surveyAnswered.push(testId)
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}