var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1000","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue-grey","dark":!_vm.loading && _vm.canClick,"light":_vm.loading || !_vm.canClick,"disabled":_vm.loading || !_vm.canClick},on:{"click":function($event){return _vm.getGroupTests(_vm.item.id)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.item.tests.length))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.item.name)+"のテスト ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tests,"search":_vm.search,"items-per-page":10,"sort-by":_vm.columnName,"sort-desc":_vm.isDescending,"footer-props":{
                        showFirstLastPage: true
                    },"loading":_vm.loading},on:{"update:sortBy":function($event){_vm.columnName=$event},"update:sort-by":function($event){_vm.columnName=$event},"update:sortDesc":function($event){_vm.isDescending=$event},"update:sort-desc":function($event){_vm.isDescending=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.name",fn:function(props){return [(_vm.canEdit(props.item))?_c('v-edit-dialog',{attrs:{"large":"","save-text":"保存","cancel-text":"キャンセル"},on:{"open":function($event){return _vm.updateNameOpen(props.item.name)},"cancel":function($event){return _vm.setAlert(false)},"save":function($event){return _vm.updateName(props.item.id, _vm.editedName)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-form',{ref:"updateNameForm"},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.max20chars],"label":"Edit","single-line":"","counter":"","maxlength":"20"},model:{value:(_vm.editedName),callback:function ($$v) {_vm.editedName=$$v},expression:"editedName"}})],1)]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(props.item.name))])]):_c('div',[_vm._v(_vm._s(props.item.name))])]}},{key:"item.number_of_sets",fn:function(ref){
                    var item = ref.item;
return [_c('AdminViewTestSets',{attrs:{"item":item}})]}},{key:"item.survey_type",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSurveyTypeText(item.survey_type))+" ")]}},{key:"item.created_at",fn:function(ref){
                    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.is_published",fn:function(ref){
                    var item = ref.item;
return [_c('v-simple-checkbox',{directives:[{name:"ripple",rawName:"v-ripple"}],attrs:{"disabled":_vm.loading || !_vm.canEdit(item)},on:{"input":function($event){return _vm.updatePublished(item)}},model:{value:(item.is_published),callback:function ($$v) {_vm.$set(item, "is_published", $$v)},expression:"item.is_published"}})]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" OK ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }