var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{attrs:{"value":_vm.alert,"dense":"","outlined":"","text":"","prominent":"","type":"error","transition":"scale-transition"}},[_vm._v(" エラーが発生しました。ログアウト後再度ログインしてください。 ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.scores,"search":_vm.search,"item-per-page":10,"sort-by":_vm.columnName,"sort-desc":_vm.isDescending,"footer-props":{
			showFirstLastPage: true
		},"loading":_vm.loading},on:{"update:sortBy":function($event){_vm.columnName=$event},"update:sort-by":function($event){_vm.columnName=$event},"update:sortDesc":function($event){_vm.isDescending=$event},"update:sort-desc":function($event){_vm.isDescending=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-container',[_c('v-row',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":_vm.loading || !_vm.scores.length || !_vm.admin},on:{"click":_vm.download}},[_vm._v(" 結果をダウンロード ")])],1)],1)],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.score",fn:function(ref){
		var item = ref.item;
return [(item.score)?_c('span',[_vm._v(" "+_vm._s(item.score)+" ("+_vm._s(Math.round((item.ratio + Number.EPSILON) * 100))+"%) ")]):_vm._e()]}},{key:"item.score_alt",fn:function(ref){
		var item = ref.item;
return [(item.score_alt)?_c('span',[_vm._v(" "+_vm._s(item.score_alt)+" ("+_vm._s(Math.round((item.ratio_alt + Number.EPSILON) * 100))+"%) ")]):_vm._e()]}},{key:"item.strict_score",fn:function(ref){
		var item = ref.item;
return [(item.strict_score)?_c('span',[_vm._v(" "+_vm._s(item.strict_score)+" ("+_vm._s(Math.round((item.strict_ratio + Number.EPSILON) * 100))+"%) ")]):_vm._e()]}},{key:"item.strict_score_alt",fn:function(ref){
		var item = ref.item;
return [(item.strict_score_alt)?_c('span',[_vm._v(" "+_vm._s(item.strict_score_alt)+" ("+_vm._s(Math.round((item.strict_ratio_alt + Number.EPSILON) * 100))+"%) ")]):_vm._e()]}},{key:"item.sets.length",fn:function(ref){
		var item = ref.item;
return [_c('AdminAnswers',{attrs:{"item":item}})]}},{key:"item.duration",fn:function(ref){
		var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTimeDiff(item, item.start_at, item.created_at))+" ")]}},{key:"item.created_at",fn:function(ref){
		var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.delete",fn:function(ref){
		var item = ref.item;
return [_c('AdminDeleteButton',{attrs:{"deleteFunction":_vm.deleteScore,"item":item,"isAll":true,"isCritical":true}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }