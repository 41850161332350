<template>
	<div class="mt-5">
		<div v-if="!error">
			<h1>
				<span>
					{{ scale.name }}
				</span>
			</h1>
			<v-card
				class="mx-auto"
				max-width="800"
				tile
				flat
				id="desc"
				v-if="scaleLength"
			>
				<p class="mt-10 mb-10 mr-10 ml-10 text-left" id="scroll-to-0" v-if="test.survey_type !== 4">
					{{ scaleLength }}個の項目それぞれについて、「よくはてはまる」「少しあてはまる」「どちらとも言えない」「あまりあてはまらない」「全くあてはまらない」の中から、あなたに最も当てはまるものを選んでください。<br/>
				</p>
				<p class="mt-10 mb-10 mr-10 ml-10 text-left" id="scroll-to-0" v-else>
					あなたが司書課程を履修した動機について、「よくはてはまる」「少しあてはまる」「どちらとも言えない」「あまりあてはまらない」「全くあてはまらない」の中からもっとも近いものをお選びください。図書館情報学の専門課程（筑波大学や慶應義塾大学など）を卒業された方は、課程を選んだ理由についてお答えください。<br/>
				</p>
			</v-card>
			<UserScaleAnswerCard
				v-for="(item, index) in scaleitemorders"
				:key="'card-' + item.scaleitem.id"
				:scaleitem="item.scaleitem"
				:index="index + 1"
			></UserScaleAnswerCard>
			<v-card
				class="mx-auto mt-10"
				max-width="900"
				tile
				flat
				v-if="notAnsweredId.length"
			>
				<p class="mb-5">未回答の項目があります。</p>
				<v-chip
					v-for="item in notAnsweredId"
					:key="'not-answered-' + item"
					class="ma-1"
					color="error"
					outlined
					link
					@click="scrollTo('scroll-to-' + (item - 1))"
				>
					{{ item }}
				</v-chip>
			</v-card>
			<v-dialog
				v-model="dialog"
				max-width="500"
				v-if="scaleLength"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="primary"
						x-large
						depressed
						class="mt-16 mb-10"
						width="150"
						:disabled="scaleItemNotAnswered.length !== 0"
						v-bind="attrs"
						v-on="on"
						@click="answer"
					>
						回答する
					</v-btn>
				</template>
				<v-card>
				<v-card-title>回答を記録しました</v-card-title>
				<v-card-text>
					<v-alert
						dense
						outlined
						text
						class="mt-10"
						type="success"
					>
						次へ進んでください。
					</v-alert>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="close"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
			</v-dialog>
		</div>
		<div v-else>
			<p class="mt-10 mb-10">
				問題が存在しないようです。
			</p>
		</div>
		<v-btn
			:to="`/test/${testId}`"
			large
			depressed
			class="mt-16"
			width="330"
			v-if="error || !scaleLength"
		>
			戻る
		</v-btn>
	</div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import { mapActions, mapGetters } from 'vuex'
import UserScaleAnswerCard from '@/components/UserScaleAnswerCard'

export default {
	name: 'UserScaleAnswer',
	components: {
			UserScaleAnswerCard
	},
	data () {
		return {
				error: false,
				dialog: false,
				scaleitemorders: [],
				startAt: null,
			}
	},
	computed: {
		...mapGetters({
			test: 'stateTest',
			scale: 'stateScale',
			scaleItemAnswered: 'stateScaleItemAnswered',
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
			answeredScales: 'stateAnsweredScales',
			loading: 'stateLoading',
			alert: 'stateAlert',
			userId: 'stateUserId',
			testAnswer: 'stateTestAnswer',
		}),
		testId() {
			return this.$route.params.test_id
		},
		scaleId() {
			return this.$route.params.scale_id
		},
		scaleLength() {
			return this.scaleitemorders.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		}
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.setAlert(false)
			}
		}
	},
	async created () {
		this.setLoading(true)
		this.clearScaleScore()
		await this.getScale(this.scaleId).then(() => {
			this.clearScaleItemAnswers()
			this.clearAnsweredScales()
			this.setScaleItemNotAnswered(this.scale.scaleitemorders)
			this.setLoading(false)
			this.scaleitemorders = this.scale.scaleitemorders
			this.startAt = Date.now()
		}).catch(err => {
			this.clearScale()
			this.error = true
			this.setLoading(false)
			console.error(err)
		})
	},
	methods: {
		...mapActions([
			'clearScale',
			'getScale',
			'clearScaleItemAnswers',
			'setScaleItemNotAnswered',
			'postScaleItemAnswers',
			'setLoading',
			'setAlert',
			'setAnsweredScales',
			'appendAnsweredScale',
			'clearAnsweredScales',
			'clearScaleItemAnswers',
			'createScaleScore',
			'clearScaleScore',
		]),
		async answer() {
			this.setLoading(true)
			const scaleScore = await this.createScaleScore(
				{
					test_id: this.testId,
					user_id: this.userId,
					testanswer_id: this.testAnswer.id
				}
			)
			const payload = {
				testId: this.testId,
				userId: this.userId,
				startAt: this.startAt,
				scaleScoreId: scaleScore.id
			}
			await this.postScaleItemAnswers(payload).then(() => {
				this.setLoading(false)
				this.appendAnsweredScale(this.scale.id)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		close() {
			this.dialog = false
			this.setAlert(false)
			this.$router.push({ name: 'Test', params: {id: this.testId }})
		},
		getNotAnsweredId() {
			return this.scaleItemNotAnswered
		},
		scrollTo(id) {
			smoothscroll.polyfill()
			document.getElementById(id).scrollIntoView({
				behavior: 'smooth'
			})
		}
	}
}
</script>
