import axios from 'axios'


const state = {
    testAnswers: [],
    testAnswer: null
}

const getters = {
    stateTestAnswers: state => state.testAnswers,
    stateTestAnswer: state => state.testAnswer
}

const actions = {
    async createTestAnswer({commit}, payload) {
        let {data} = await axios.post('/testanswers/', payload)
        commit('setTestAnswer', data)
    },
    async getTestAnswers({commit}) {
        let {data} = await axios.get('/testanswers/')
        commit('setTestAnswers', data)
    },
    clearTestAnswers({commit}) {
        commit('setTestAnswers', [])
    },
    clearTestAnswer({commit}) {
        commit('setTestAnswer', null)
    }
}

const mutations = {
    setTestAnswers(state, testAnswers) {
        state.testAnswers = testAnswers
    },
    setTestAnswer(state, testAnswer) {
        state.testAnswer = testAnswer
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
