<template>
	<v-container>
		<v-row>
			<v-col class="mx-auto col-xl-6 col-lg-6 col-md-8 col-sm-12 col-xs-12">
				<v-form ref="adminLoginForm">
					<v-container>
						<v-row>
							<v-col>
								<v-alert
									:value="alert"
									dense
									outlined
									text
									prominent
									type="error"
									transition="scale-transition"
								>
									ログインできませんでした。
								</v-alert>
							</v-col>
						</v-row>
						<v-row>
							<v-text-field
								label="Username"
								v-model="form.username"
								:rules="[rules.required]"
							></v-text-field>
						</v-row>
						<v-row>
							<v-text-field
								label="Password"
								v-model="form.password"
								:rules="[rules.required]"
								:type="show ? 'text' : 'password'"
								:append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
								@click:append="show = !show"
							></v-text-field>
						</v-row>
						<v-row class="mt-10">
							<v-btn
								block
								color="primary"
								@click="login"
								:loading="loading"
							>Login</v-btn>
						</v-row>
					</v-container>
				</v-form>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminLogin',
	data () {
		return {
			show: false,
			alert: false,
			form: {
				username: '',
				password: '',
			},
			rules: {
				required: value => !!value || 'Required'
			},
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			isAdminAuthenticated: 'isAdminAuthenticated',
			plainadmin: 'statePlainAdmin',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated',
			loading: 'stateLoading'
		})
	},
	watch: {
		alert(value) {
			if (value === true) {
				setTimeout(() => this.alert = false, 2500)
			}
		}
	},
	methods: {
		...mapActions(['adminLogin', 'setLoading', 'plainAdminLogin']),
		async login() {
			if (this.$refs.adminLoginForm.validate()) {
				this.setLoading(true)
				const User = new FormData()
				User.append('username', this.form.username)
				User.append('password', this.form.password)
				await this.adminLogin(User).then(() => {
					this.setLoading(false)
					let redirect = this.$route.query['redirect']
					if (redirect && redirect !== '/admin')
					{
						this.$router.push(redirect)
					} else {
						this.$router.push('/admin/dashboard')
					}
				}).catch((e) => {
					console.error(e)
				})
				await this.plainAdminLogin(User).then(() => {
					this.setLoading(false)
					let redirect = this.$route.query['redirect']
					if (redirect && redirect !== '/admin')
					{
						this.$router.push(redirect)
					} else {
						this.$router.push('/admin/dashboard')
					}
				}).catch(() => {
					this.setLoading(false)
					this.alert = true
				})
			}
		}
	}
}
</script>
