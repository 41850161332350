<template>
	<div>
		<v-alert
			:value="alert"
			dense
			outlined
			text
			prominent
			type="error"
			transition="scale-transition"
		>
			エラーが発生しました。ログアウト後再度ログインしてください。
		</v-alert>
		<v-data-table
			:headers="headers"
			:items="surveyAnswers"
			:search="search"
			:custom-filter="filter"
			:item-per-page="10"
			:sort-by.sync="columnName"
			:sort-desc.sync="isDescending"
			:footer-props="{
				showFirstLastPage: true
			}"
			:loading="loading"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-container>
						<v-row>
							<v-spacer></v-spacer>
							<v-btn
								@click="download"
								:disabled="loading || !surveyAnswers.length || !admin"
							>
								結果をダウンロード
							</v-btn>
						</v-row>
					</v-container>
				</v-toolbar>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
			</template>
			<template v-slot:item.grade="{ item }">
				{{ getGradeText(item.grade) }}
			</template>
			<template v-slot:item.gender="{ item }">
				{{ getGenderText(item.gender) }}
			</template>
			<template v-slot:item.courses="{ item }">
				<v-chip
					v-for="course in item.courses" :key="`course-${course.id}`"
					small
					class="mr-2 mt-1 mb-1"
				>
					{{ course.name }}
				</v-chip>
			</template>
			<template v-slot:item.test="{ item }">
				{{ tests.filter(v => v.id == item.test_id)[0].name }}
			</template>
			<template v-slot:item.user="{ item }">
				{{ users.filter(v => v.id == item.user_id)[0].username }}
			</template>
			<template v-slot:item.created_at="{ item }">
				{{ formatDate(item.created_at) }}
			</template>
			<template v-slot:item.delete="{ item }">
				<AdminDeleteButton :deleteFunction="deleteSurveyAnswer" :item="item" :isAll="true" :isCritical="true" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminDeleteButton from '@/components/AdminDeleteButton'

export default {
	name: 'AdminSurveyAnswers',
	components: {
		AdminDeleteButton
	},
	data () {
		return {
			search: '',
			columnName: 'id',
			isDescending: false,
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Univ.', value: 'university' },
				{ text: 'School', value: 'school' },
				{ text: 'Dept.', value: 'department' },
				{ text: 'Grade', value: 'grade' },
				{ text: 'Gender', value: 'gender' },
				{ text: 'Courses', value: 'courses' },
				{ text: 'Test', value: 'test' },
				{ text: 'User', value: 'user' },
				{ text: 'Created at', value: 'created_at' },
				{ text: 'Delete', value: 'delete' }
			]
		}
	},
	async created () {
		await this.getSurveyAnswers()
		await this.getUsers()
		await this.getTests()
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			surveyAnswers: 'stateSurveyAnswers',
			loading: 'stateLoading',
			alert: 'stateAlert',
			isAdminAuthenticated: 'isAdminAuthenticated',
			tests: 'stateTests',
			users: 'stateUsers',
		})
	},
	methods: {
		...mapActions(['getSurveyAnswers', 'downloadSurveyAnswers', 'deleteSurveyAnswer', 'getUsers', 'getTests', 'setLoading', 'setAlert', 'checkAdmin', 'adminLogout']),
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		getGradeText(value) {
			if (value === 1) return '学部1年'
			else if (value === 2) return '学部2年'
			else if (value === 3) return '学部3年'
			else if (value === 4) return '学部4年'
			else if (value === 5) return '修士1年'
			else if (value === 6) return '修士2年'
			else if (value === 7) return '博士1年'
			else if (value === 8) return '博士2年'
			else if (value === 9) return '博士3年'
			else return 'その他'
		},
		getGenderText(value) {
			if (value === 1) return '男'
			else if (value === 2) return '女'
			else if (value === 3) return 'その他'
			else return '回答しない'
		},
	filter(value, search, item) {
		let inUni = RegExp(search, 'i').test(item.university)
		let inSchool = RegExp(search, 'i').test(item.school)
		let inDept = RegExp(search, 'i').test(item.department)
		let inGrade = RegExp(search, 'i').test(this.getGradeText(item.grade))
		let inGender = RegExp(search, 'i').test(this.getGenderText(item.gender))
		let inTest = RegExp(search, 'i').test(this.tests.filter(v => v.id == item.test_id)[0].name)
		let inUser = RegExp(search, 'i').test(this.users.filter(v => v.id == item.user_id)[0].username)
		let inCourses = false;
		item.courses.forEach(course => {
			if (RegExp(search, 'i').test(course.name)) {
				inCourses = true
			}
		})

		return inUni || inSchool || inDept || inGrade || inGender || inCourses || inTest || inUser
	},
		async download() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}

			if (this.isAdminAuthenticated) {
				this.setLoading(true)
				await this.downloadSurveyAnswers().then((response) => {
					let blob = new Blob([response.data], { type: 'text/tsv' })
					let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
					let url = window.URL.createObjectURL(blob)
					let dllink = document.createElement('a')
					dllink.href = url
					dllink.setAttribute('download', filename)
					dllink.click()
					this.setLoading(false)
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
			}
		}
	}
}
</script>
