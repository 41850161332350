import axios from 'axios'


const state = {
    scales: [],
    scale: {
        "scaleitems": []
    },
    scaleRes: {},
    formattedScaleItems: [],
    numberOfScaleItems: 0
}

const getters = {
    stateScales: state => state.scales,
    stateScale: state => state.scale,
    stateScaleRes: state => state.scaleRes,
    stateFormattedScaleItems: state => state.formattedScaleItems,
    stateNumberOfScaleItems: state => state.numberOfScaleItems
}

const actions = {
    async createScale({dispatch, commit}, payload) {
        const scale = {"name": payload.name}
        const scaleitems = payload.scaleitems
        let {data} = await axios.post('/scales/', scale)
        commit('setScaleRes', data)
        const scaleId = data.id
        let scaleitemList = new Array()
        for (const scaleitem of scaleitems) {
            const scaleitemItem = {
                "text": scaleitem.text,
                "scales": [scaleId]
            }
            scaleitemList.push(scaleitemItem)
        }
        await dispatch('addScaleItems', scaleitemList)
        await dispatch('getScales')
    },
    async getScales({commit}) {
        let {data} = await axios.get('/scales/')
        commit('setScales', data)
    },
    async getScale({commit}, id) {
        let {data} = await axios.get(`/scales/${id}`)
        commit('setScale', data)
    },
    async updateScaleName({dispatch}, {id, name}) {
        const payload = {"name": name}
        await axios.put(`/scales/${id}`, payload)
        await dispatch('getScales')
    },
    async formatScaleItems({commit}, scaleitems) {
        const payload = {"scaleitems": scaleitems}
        let {data} = await axios.post('/format_scaleitems/', payload)
        commit('setFormattedScaleItems', data)
    },
    async addScaleItems({commit}, payload) {
        let {data} = await axios.post('/add_scaleitems/', payload)
        commit('setNumberOfScaleItems', data.length)
    },
    async getLISScaleItems() {
        let {data} = await axios.get('/lis_scaleitems/')
        return data
    },
    async getLISScaleItemsPast() {
        let {data} = await axios.get('/lis_scaleitems_past/')
        return data
    },
    async deleteScale({dispatch}, id) {
        await axios.delete(`/scales/${id}`)
        await dispatch('getScales')
    },
    clearScales({commit}) {
        commit('setScales', [])
    },
    clearScale({commit}) {
        commit('setScale', {})
    },
    clearFormattedScaleItems({commit}) {
        commit('setFormattedScaleItems', [])
        commit('setNumberOfScaleItems', 0)
    }
}

const mutations = {
    setScales(state, scales) {
        state.scales = scales
    },
    setScale(state, scale) {
        state.scale = scale
    },
    setScaleRes(state, scaleRes) {
        state.scaleRes = scaleRes
    },
    setFormattedScaleItems(state, formattedScaleItems) {
        state.formattedScaleItems = formattedScaleItems
    },
    setNumberOfScaleItems(state, number) {
        state.numberOfScaleItems = number
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
