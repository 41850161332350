<template>
	<v-container>
		<v-row>
			<v-col>
				<div class="mt-5">
					<h1>
						基本情報
					</h1>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-alert
					:value="alert"
					dense
					outlined
					text
					prominent
					type="error"
					transition="scale-transition"
				>
					回答を記録できませんでした。
				</v-alert>
				<v-form ref="surveyForm">
					<v-container class="mx-auto col-lg-6 col-md-8 col-sm-8 col-xs-10">
						<v-row>
							<v-text-field
								v-model="form.university"
								label="大学"
								:rules="[rules.required, rules.max50chars]"
								counter
								maxlength="50"
							></v-text-field>
						</v-row>
						<v-row>
							<v-text-field
								v-model="form.school"
								label="学部／研究科"
								:rules="[rules.required, rules.max50chars]"
								counter
								maxlength="50"
							></v-text-field>
						</v-row>
						<v-row>
							<v-text-field
								v-model="form.department"
								label="学科／専攻"
								:rules="[rules.required, rules.max50chars]"
								counter
								maxlength="50"
							></v-text-field>
						</v-row>
						<v-row>
							<v-select
								v-model="form.grade"
								label="学年"
								:items="grades"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row>
							<v-select
								v-model="form.gender"
								label="性別"
								:items="genders"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row>
							<v-select
								v-model="form.courses"
								label="履修済み/履修中の講義"
								:items="curriculum.courses"
								item-text="name"
								item-value="id"
								chips
								multiple
							></v-select>
						</v-row>
						<v-row class="mt-10">
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-2"
								dark
								depressed
								x-large
								:loading="loading"
								@click="post"
							>回答する</v-btn>
							<v-spacer></v-spacer>
						</v-row>
					</v-container>
				</v-form>
			</v-col>
		</v-row>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>回答を記録しました</v-card-title>
				<v-card-text>
					<v-alert
						dense
						outlined
						text
						class="mt-10"
						type="success"
					>
						次へ進んでください。
					</v-alert>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserStudentSurvey',
	data () {
		return {
			form: {
				university: '',
				school: '',
				department: '',
				grade: null,
				gender: null,
				courses: []
			},
			rules: {
				required: value => !!value || '必須',
				max50chars: value => value.length <= 50 || '最大50字'
			},
			grades: [
				{ text: '学部1年', value: 1 },
				{ text: '学部2年', value: 2 },
				{ text: '学部3年', value: 3 },
				{ text: '学部4年', value: 4 },
				{ text: '修士1年', value: 5 },
				{ text: '修士2年', value: 6 },
				{ text: '博士1年', value: 7 },
				{ text: '博士2年', value: 8 },
				{ text: '博士3年', value: 9 },
				{ text: 'その他', value: 99 },
			],
			genders: [
				{ text: '男', value: 1 },
				{ text: '女', value: 2 },
				{ text: 'その他', value: 3 },
				{ text: '回答しない', value: 4 },
			],
			resDialog: false
		}
	},
	async created () {
		this.setAlert(false)
		await this.getPublishedTest(this.testId)
		await this.getCurriculum(this.test.curriculum_id)
	},
	computed: {
		...mapGetters({
			curriculum: 'stateCurriculum',
			userId: 'stateUserId',
			loading: 'stateLoading',
			alert: 'stateAlert',
			test: 'stateTest',
			testAnswer: 'stateTestAnswer'
		}),
		testId() {
			return this.$route.params.id
		}
	},
	methods: {
		...mapActions(['getCurriculum', 'getPublishedTest', 'setLoading', 'setAlert', 'postSurveyAnswer']),
		async post() {
			if (this.$refs.surveyForm.validate()) {

				this.setLoading(true)
				const payload = {
					'university': this.form.university,
					'school': this.form.school,
					'department': this.form.department,
					'grade': this.form.grade,
					'gender': this.form.gender,
					'courses': this.form.courses,
					'user_id': this.userId,
					'test_id': this.testId,
					'testanswer_id': this.testAnswer.id
				}
				await this.postSurveyAnswer(payload).then(() => {
					this.setAlert(false)
					this.resDialog = true
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
				this.setLoading(false)
			}
		},
		closeResDialog() {
			this.resDialog = false
			this.$router.push({ name: 'Test', params: {id: this.testId }})
		}
	}
}
</script>