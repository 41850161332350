<template>
	<div>
		<h2 class="dim" v-if="!isResult">Q{{q}}/Q{{questions}}</h2>
		<v-form ref="surveyForm">
			<v-container class="mx-auto col-lg-12 col-md-10 col-sm-10 col-xs-10">
				<router-view></router-view>
			</v-container>
		</v-form>
		<NextPrevButton :questions="questions" v-if="!isResult" />
	</div>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 1.2em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 1em;
}

.mot-survey-form {
	margin-top: 0.6em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

.dim {
	color: #999
}

</style>

<script>
import { mapGetters, mapActions } from 'vuex'
import NextPrevButton from '@/components/demo/NextPrevButton'


export default {
	name: 'UserSurvey',
	components: {
		NextPrevButton
	},
	data () {
		return {
			questions: 8
		}
	},
	computed: {
		...mapGetters({
			test: 'stateTest',
			scale: 'stateScale',
			scaleItemAnswered: 'stateScaleItemAnswered',
			scaleItemNotAnswered: 'stateScaleItemNotAnswered',
			scaleItemAnswers: 'stateScaleItemAnswers',
			answeredScales: 'stateAnsweredScales',
		}),
		testId() {
			return this.$route.params.test_id
		},
		scaleLength() {
			return this.scaleitemorders.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		},
		q() {
			if (this.$route.params.q == undefined) {
				return 1
			} else {
				return Number(this.$route.params.q)
			}
		},
		isResult() {
			if (this.$route.name == "SurveyResult") {
				return true
			} else {
				return false
			}
		}
	},
	methods: {
		...mapActions([
			'clearScale',
			'getScale',
			'clearScaleItemAnswers',
			'setScaleItemNotAnswered',
			'postScaleItemAnswers',
			'setLoading',
			'setAlert',
			'setAnsweredScales',
			'appendAnsweredScale',
			'clearAnsweredScales',
			'clearScaleItemAnswers',
			'createScaleScore',
			'clearScaleScore',
		]),
		async answer() {
			this.setLoading(true)
			const scaleScore = await this.createScaleScore(
				{
					test_id: this.testId,
					user_id: this.userId,
					testanswer_id: this.testAnswer.id
				}
			)
			const payload = {
				testId: this.testId,
				userId: this.userId,
				startAt: this.startAt,
				scaleScoreId: scaleScore.id
			}
			await this.postScaleItemAnswers(payload).then(() => {
				this.setLoading(false)
				this.appendAnsweredScale(this.scale.id)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		getNotAnsweredId() {
			return this.scaleItemNotAnswered
		}
	}
}
</script>