<template>
	<div>
		<div v-if="terms.length === 0">
			<v-alert
				:value="alert"
				dense
				outlined
				text
				prominent
				type="error"
				transition="scale-transition"
			>
				エラーが発生しました。ログアウト後再度ログインしてください。
			</v-alert>
		</div>
		<TermsTable :terms="terms"/>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TermsTable from '@/components/TermsTable'

export default {
	name: 'AdminTerms',
	components: {
		TermsTable
	},
	created () {
		if (this.isAdminAuthenticated) {
			this.getTerms()
		}
	},
	computed: {
		...mapGetters({
			terms: 'stateTerms',
			loading: 'stateLoading',
			alert: 'stateAlert',
			isAdminAuthenticated: 'isAdminAuthenticated',
		}),
	},
	methods: {
		...mapActions(['setLoading', 'setAlert', 'getTerms']),
		async register() {
			this.setAlert(false)
			this.setLoading(true)
			await this.registerTerms().catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
			this.setLoading(false)
		}
	}
}
</script>
