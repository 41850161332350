import axios from 'axios'


const state = {
	users: [],
	userUsers: [],
	myUsers: [],
	plainadmins: [],
	groupUsers: [],
	user: null,
	userId: null,
	formattedUsers: [],
	numberOfUsers: 0,
	addedAdmin: ''
}

const getters = {
	stateUsers: state => state.users,
	stateGroupUsers: state => state.groupUsers,
	stateMyUsers: state => state.myUsers,
	stateUserUsers: state=>state.userUsers,
	statePlainAdmins: state=>state.plainadmins,
	stateFormattedUsers: state => state.formattedUsers,
	stateNumberOfUsers: state => state.numberOfUsers,
	stateUser: state => state.user,
	stateUserId: state => state.userId,
	isUserLoggedIn: state => !!state.user,
	stateAddedAdmin: state=> state.addedAdmin
}

const actions = {
	async addUser({dispatch, commit}, payload) {
		let userItem = {}
		if (payload.password != '') {
			userItem = {
				"username": payload.username,
				"password": payload.password,
				"memo": payload.memo,
				"is_active": payload.is_active,
				"role": 2
			}
		} else {
			userItem = {
				"username": payload.username,
				"memo": payload.memo,
				"is_active": payload.is_active,
				"role": 2
			}
		}
		let {data} = await axios.post('/users/', userItem)
		commit('setAddedAdmin', data.username)
		await dispatch('getPlainAdmins')
	},
	async addUsers({dispatch, commit}, payload) {
		const users = payload.users
		let userGroupId = []
		if (payload.usergroup_id !== null) {
			userGroupId = [payload.usergroup_id]
		}

		let userList = new Array()
		let userItem = {}
		for (const user of users) {
			if (user.password !== null) {
				userItem = {
					"username": user.username,
					"password": user.password,
					"usergroups": userGroupId
				}
			} else {
				userItem = {
					"username": user.username,
					"usergroups": userGroupId
				}
			}
			userList.push(userItem)
		}
		let {data} = await axios.post('/add_users/', userList)
		commit('setNumberOfUsers', data.length)
		await dispatch('getUserUsers')
		await dispatch('getUserGroups')
	},
	async formatUsers({commit}, payload) {
		let {data} = await axios.post('/format_users/', payload)
		commit('setFormattedUsers', data)
	},
	async getUsers({commit}) {
		let {data} = await axios.get('/users/')
		commit('setUsers', data)
	},
	async getMyUsers({commit}, id) {
		if (id > 0) {
			let {data} = await axios.get(`/users/?admin_id=${id}`)
			commit('setMyUsers', data)
		} else {
			let {data} = await axios.get('/users/')
			commit('setMyUsers', data)
		}
	},
	async getGroupUsers({commit}, id) {
		let {data} = await axios.get(`/users/?usergroup_id=${id}`)
		commit('setGroupUsers', data)
	},
	async getUserUsers({commit}) {
		let {data} = await axios.get('/users/?role=1')
		commit('setUserUsers', data)
	},
	async getPlainAdmins({commit}) {
		let {data} = await axios.get('/users/?role=2')
		commit('setPlainAdmins', data)
	},
	async updateUserActive({dispatch}, {id, is_active}) {
		const payload = {"is_active": is_active}
		await axios.put(`/users/${id}`, payload)
		await dispatch('getPlainAdmins')
		await dispatch('getUserUsers')
	},
	async updateUserMemo({dispatch}, payload) {
		const id = payload.id
		const newPayload = {"memo": payload.memo}
		await axios.put(`/users/${id}`, newPayload)
		await dispatch('getPlainAdmins')
		await dispatch('getUserUsers')
	},
	async userLogin({dispatch}, user) {
		await axios.post('/users/token', user)
		await dispatch('getUser')
	},
	async getUser({commit}) {
		let {data} = await axios.get('/users/me')
		commit('setUser', data.username)
		commit('setUserId', data.id)
	},
	async checkUser() {
		await axios.get('/users/me')
	},
	async downloadUsers(_, payload) {
		if (payload.usergroup_id > 0) {
			return await axios.get(`/users/download/?role=${payload.role}&usergroup_id=${payload.usergroup_id}`, { responseType: 'blob' })
		} else {
			return await axios.get(`/users/download/?role=${payload.role}`, { responseType: 'blob' })
		}
	},
	async deleteUser({dispatch}, id) {
		await axios.delete(`/users/${id}`)
		await dispatch('getPlainAdmins')
		await dispatch('getUserUsers')
		await dispatch('getUserGroups')
	},
	userLogout({commit}) {
		let user = null
		let userId = null
		commit('setUser', user)
		commit('setUserId', userId)
	},
	clearUsers({commit}) {
		commit('setUsers', [])
		commit('setPlainAdmins', [])
	},
	clearGroupUsers({commit}) {
		commit('setGroupUsers', [])
	},
	clearUsersRes({commit}) {
		commit('setNumberOfUsers', 0)
	},
	clearFormattedUsers({commit}) {
		commit('setFormattedUsers', [])
	},
	clearAddedAdmin({commit}) {
		commit('setAddedAdmin', '')
	},
	clearNumberOfUsers({commit}) {
		commit('setNumberOfUsers', 0)
	},
	clearMyUsers({commit}) {
		commit('setMyUsers', [])
	},
	clearUserUsers({commit}) {
		commit('setUserUsers', [])
	},
	clearPlainAdmins({commit}) {
		commit('setPlainAdmins', [])
	}
}

const mutations = {
	setAddedAdmin(state, user) {
		state.addedAdmin = user
	},
	setUsers(state, users) {
		state.users = users
	},
	setMyUsers(state, users) {
		state.myUsers = users
	},
	setGroupUsers(state, users) {
		state.groupUsers = users
	},
	setUserUsers(state, users) {
		state.userUsers = users
	},
	setPlainAdmins(state, users) {
		state.plainadmins = users
	},
	setFormattedUsers(state, formattedUsers) {
		state.formattedUsers = formattedUsers
	},
	setNumberOfUsers(state, number) {
		state.numberOfUsers = number
	},
	setUser(state, username) {
		state.user = username
	},
	setUserId(state, userId) {
		state.userId = userId
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}
