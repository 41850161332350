import axios from 'axios'


const state = {
	terms: []
}

const getters = {
	stateTerms: state => state.terms
}

const actions = {
	async getTerms({commit}) {
		let {data} = await axios.get('/terms/')
		commit('setTerms', data)
	},
	clearTerms({commit}) {
		commit('setTerms', [])
	}
}

const mutations = {
	setTerms(state, terms) {
		state.terms = terms
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}
