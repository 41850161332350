<template>
	<div>
		<v-lazy
			v-model="isActive"
			:options="{
				threshold: 0.3
			}"
			min-height="200"
			transition="scroll-y-reverse-transition"
		>
		<v-card
			class="mx-auto"
			max-width="1200"
			tile
			flat
			:id="'card-' + index"
		>
			<v-card-title>
				<span class="index">{{ index }}</span>
				<v-spacer></v-spacer>
				<span
					class="item"
					:class="$vuetify.breakpoint.lgAndUp ? 'text-h5' : 'text-h5 item-smallscreen'"
				>
					{{ scaleitem.text }}
				</span>
				<v-spacer></v-spacer>
			</v-card-title>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-item-group
					v-model="answer"
					@change="selectAnswer(index)"
				>
					<v-container>
						<v-row>
							<v-col
								v-for="choice in choices"
								:key="choice.value"
								:style="$vuetify.breakpoint.lgAndUp ? 'width: 20%; max-width: 20%; flex-basis: 20%;' : ''"
								cols="12"
							>
								<v-item
									v-slot="{ active, toggle }"
									:value="choice.value"
									:id="choice.value === 1 ? 'scroll-to-' + index : ''"
								>
									<v-card
										:color="active ? 'blue' : 'grey lighten-3'"
										class="d-flex align-center"
										:dark="active ? true : false"
										:light="active ? false: true"
										height="50"
										min-width="160"
										@click="toggle"
										flat
									>
										<div
											class="flex-grow-1 text-center"
											:class="$vuetify.breakpoint.lgAndUp ? 'text-body-2' : 'text-body-1'"
										>
											{{ choice.text }}
										</div>
									</v-card>
								</v-item>
							</v-col>
						</v-row>
					</v-container>
				</v-item-group>
				<v-spacer></v-spacer>
			</v-card-actions>
		</v-card>
		</v-lazy>
		<v-divider></v-divider>
	</div>
</template>

<style scoped>
/* vuetify lg min breakpoint: 1280 - 16     = 1264px */
/* vuetify lg max breakpoint: 1920 - 16 - 1 = 1903px */

@media (min-width: 1264px) and (max-width: 1903px) {
    .flex.lg5-custom {
        width: 20%;
        max-width: 20%;
        flex-basis: 20%;
    }
}

.item {
	font-family: "Helvetica Neue", "Helvetica", "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif !important;
	margin-left: 1em;
	margin-right: 1em;
	text-align: left;
}

.item-smallscreen {
	margin-left: 2em;
}

.index {
	font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif !important;
	font-size: 2em;
	font-weight: 600;
	color: lightgray;
}

.v-divider {
	border-width: 0.5px;
}

</style>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'UserScaleAnswerCard',
	props: ['scaleitem', 'index', 'lang'],
	data () {
		return {
			answer: undefined,
			choices_ja: [
				{
					'value': 5,
					'text': 'よくあてはまる'
				},
				{
					'value': 4,
					'text': '少しあてはまる'
				},
				{
					'value': 3,
					'text': 'どちらとも言えない'
				},
				{
					'value': 2,
					'text': 'あまりあてはまらない'
				},
				{
					'value': 1,
					'text': 'まったくあてはまらない'
				}
			],
			choices_en: [
				{
					'value': 5,
					'text': 'Strongly agree'
				},
				{
					'value': 4,
					'text': 'Somewhat agree'
				},
				{
					'value': 3,
					'text': 'Neither agree nor disagree'
				},
				{
					'value': 2,
					'text': 'Somewhat disagree'
				},
				{
					'value': 1,
					'text': 'Strongly disagree'
				}
			],
			isActive: false,
			isMobile: false
		}
	},
	beforeDestroy() {
		if (typeof window === 'undefined') return
		window.removeEventListener('resize', this.onResize, { passive: true })
	},
	mounted() {
		this.onResize()
		window.addEventListener('resize', this.onResize, { passive: true })
	},
	created () {
		if (!this.notAnswered.includes(this.index)) {
			let index = this.answers.findIndex(x => x.index == this.index)
			if (index !== -1) {
				let answer = this.answers[index]
				this.answer = answer.answer
			} else {
				this.appendScaleItemNotAnswered(this.index)
			}
		}
	},
	computed: {
		...mapGetters({
			answers: 'stateScaleItemAnswers',
			notAnswered: 'stateScaleItemNotAnswered',
			scale: 'stateScale'
		}),
		choices () {
			return this.lang === 'ja' ? this.choices_ja : this.choices_en
		}
	},
	methods: {
		...mapActions(['addScaleItemAnswer', 'appendScaleItemNotAnswered']),
		selectAnswer(elementId) {
			const answer = {
				'scaleItemId': this.scaleitem.id,
				'scaleItemText': this.scaleitem.text,
				'scaleItemFactorId': this.scaleitem.factor_id,
				'scaleItemFactorName': this.scaleitem.factor_name,
				'answer': this.answer,
				'index': elementId
			}
			this.addScaleItemAnswer(answer)

			if (this.answer !== undefined) {
				if (this.isMobile) {
					this.scrollTo(`scroll-to-${elementId}`)
				} else {
					if (elementId > 1) {
						this.scrollTo(`scroll-to-${elementId - 1}`)
					} else {
						this.scrollTo('desc')
					}
				}
			}
		},
		scrollTo(id) {
			smoothscroll.polyfill()
			document.getElementById(id).scrollIntoView({
				behavior: 'smooth'
			})
		},
		onResize () {
			this.isMobile = window.innerWidth < 1200
		}
	}
}
</script>
