<template>
	<v-container>
		<v-row>
			<v-col>
				<div class="mt-5">
					<h1>
						司書課程での学びとフェイスシート
					</h1>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-alert
					:value="alert"
					dense
					outlined
					text
					prominent
					type="error"
					transition="scale-transition"
				>
					回答を記録できませんでした。
				</v-alert>
				<v-form ref="surveyForm">
					<v-container class="mx-auto col-lg-10 col-md-10 col-sm-10 col-xs-10">
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">1. あなたが司書課程を履修した際の気持ちについて、もっとも近いものをお選びください。<span class="star">*</span></p>
						</v-row>
						<v-row class="mot-survey-form">
							<v-select
								v-model="form.motivation"
								label="司書資格取得の意欲"
								:items="motivations"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							>
								<template v-slot:item="{ item, attrs, on }">
									<v-list-item v-bind="attrs" v-on="on">
										<v-list-item-title class="text-wrap">
											{{ item.text }}
										</v-list-item-title>
									</v-list-item>
								</template>
							</v-select>
						</v-row>
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">2. 図書館に勤務されている方は勤務先の館種を、それ以外の方は業種をお選びください。複数の仕事をされている場合は、主な職種をお選びください。<span class="star">*</span></p>
						</v-row>
						<v-row class="mot-survey-form">
							<v-select
								v-model="form.occupation"
								label="現在の館種／業種"
								:items="occupations"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row class="mot-survey-form mot-survey-form-margin-bottom" v-if="form.occupation === 99">
							<v-text-field
								v-model="form.other_occupation"
								placeholder="例：教育"
								:rules="[rules.max100chars, rules.required]"
								counter
								maxlength="100"
								rows="3"
							>
								<template v-slot:label>
									「その他」と答えた方は、業種をご記入ください。<span class="star">*</span>
								</template>
							</v-text-field>
						</v-row>
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">3. 転職のご経験がある場合は、あてはまる館種または業種を全てお選びください（転職経験がない方は4に進んで下さい）。大学・短期大学の卒業後に就いたもののみとし、正規雇用・非正規雇用のいずれも含みますがボランティアは含みません。</p>
						</v-row>
						<v-row class="mot-survey-form">
							<v-select
								v-model="form.past_occupations"
								label="過去の館種／業種（あてはまるもの全て）"
								:items="occupations"
								item-text="text"
								item-value="value"
								chips
								multiple
							></v-select>
						</v-row>
						<v-row class="mot-survey-form mot-survey-form-margin-bottom" v-if="form.past_occupations.includes(99)">
							<v-text-field
								v-model="form.other_past_occupation"
								placeholder="例：金融"
								:rules="[rules.max100chars, rules.required]"
								counter
								maxlength="100"
								rows="3"
							>
								<template v-slot:label>
									回答に「その他」が含まれる方は、業種をご記入ください。<span class="star">*</span>
								</template>
							</v-text-field>
						</v-row>
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">4. あなたが司書課程で学んだことのうち、特に興味関心を惹かれたことを自由にお書きください。特にない場合は「なし」とお書きください。<span class="star">*</span></p>
						</v-row>
						<v-row class="mot-survey-form-small">
							<v-textarea
								v-model="form.interests"
								label=""
								:rules="[rules.max400chars, rules.required]"
								counter
								maxlength="400"
								rows="3"
								placeholder="例：資料の分類をする作業が面白かった。卒業論文に使う資料も同じように分類してみた。また、図書館や本の歴史を学べたのが良かった。"
							></v-textarea>
						</v-row>
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">5. あなたが司書課程で学んだことのうち、仕事に役立ったと思われることがあれば、自由にお書きください。転職のご経験がある場合は、どの館種または業種に役立ったのかも記載してください。特にない場合は「なし」とお書きください。<span class="star">*</span></p>
						</v-row>
						<v-row class="mot-survey-form-small">
							<v-textarea
								v-model="form.benefits"
								label=""
								:rules="[rules.max400chars, rules.required]"
								counter
								maxlength="400"
								rows="3"
								placeholder="例：高齢者サービスについて学んでいたので、高齢者に接する際の心構えができた。HTMLを学んだことが、ウェブサイトの発注の際に役立った。"
							></v-textarea>
						</v-row>
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">6. あなたの仕事に役立てるために、大学・短期大学で学びたかったと思われる事項があれば、司書課程に含まれるかどうかにかかわらず自由にお書きください。転職のご経験がある場合は、どの館種または業種に役立てるためかも記載してください。特にない場合は「なし」とお書きください。<span class="star">*</span></p>
						</v-row>
						<v-row class="mot-survey-form-small">
							<v-textarea
								v-model="form.desired"
								label=""
								:rules="[rules.max400chars, rules.required]"
								counter
								maxlength="400"
								rows="3"
								placeholder="例：公共図書館での業務に役立てるために、迷惑利用者にどのように対応すればよいのかを学びたかった。出版社での仕事に役立てるために電子書籍の作成方法を学びたかった。"
							></v-textarea>
						</v-row>
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">7. 性別<span class="star">*</span></p>
						</v-row>
						<v-row class="mot-survey-form">
							<v-select
								v-model="form.gender"
								label="性別"
								:items="genders"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">8. 年齢<span class="star">*</span></p>
						</v-row>
						<v-row class="mot-survey-form">
							<v-select
								v-model="form.age"
								label="年齢"
								:items="ages"
								item-text="text"
								item-value="value"
								:rules="[rules.required]"
							></v-select>
						</v-row>
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">9. 司書資格を取得した大学・短期大学<span class="star">*</span></p>
						</v-row>
						<v-row class="mot-survey-form-small">
							<v-text-field
								v-model="form.university"
								label=""
								persistent-hint
								:rules="[rules.max100chars, rules.required]"
								placeholder="例：〇〇大学"
								counter
								maxlength="100"
								rows="3"
							></v-text-field>
						</v-row>
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">10. 司書資格を取得した年（大学や短期大学の卒業年）（西暦）<span class="star">*</span></p>
						</v-row>
						<v-row class="mot-survey-form-small">
							<v-text-field
								v-model="form.certificate_year"
								label=""
								persistent-hint
								:rules="[rules.required, rules.year]"
								placeholder="例：2004"
								maxlength="4"
							></v-text-field>
						</v-row>
						<v-row class="mot-survey-form-large">
							<p class="text-subtitle-1 text-left">11. 司書資格や司書課程について、また、調査についてコメント等がありましたらご自由にお書きください。</p>
						</v-row>
						<v-row class="mot-survey-form-small">
							<v-textarea
								v-model="form.comment"
								label=""
								:rules="[rules.max400chars]"
								counter
								maxlength="400"
								rows="3"
							></v-textarea>
						</v-row>
						<v-row class="mt-15">
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-2"
								dark
								depressed
								x-large
								:loading="loading"
								@click="post"
							>回答する</v-btn>
							<v-spacer></v-spacer>
						</v-row>
					</v-container>
				</v-form>
			</v-col>
		</v-row>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>回答を記録しました</v-card-title>
				<v-card-text>
					<v-alert
						dense
						outlined
						text
						class="mt-10"
						type="success"
					>
						次へ進んでください。
					</v-alert>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<style scoped>
.mot-survey-form-large {
	margin-top: 1.2em;
}

.mot-survey-form-small {
	margin-top: -1em;
}

.mot-survey-form-margin-bottom {
	margin-bottom: 1em;
}

.mot-survey-form {
	margin-top: 0.6em;
}

.note {
	font-size: 0.9em;
	color: #777;
}

.star {
	color: red;
	font-size: 1.2em;
	margin-left: 0.2em;
}

</style>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserStudentMotiveSurvey',
	data () {
		return {
			form: {
				motivation: null,
				occupation: null,
				other_occupation: '',
				past_occupations: [],
				other_past_occupation: '',
				interests: '',
				benefits: '',
				desired: '',
				gender: null,
				age: null,
				university: '',
				certificate_year: '',
				comment: '',
			},
			rules: {
				required: value => !!value || '必須',
				max100chars: value => value.length <= 100 || '最大100字',
				max400chars: value => value.length <= 400 || '最大400字',
				email: value => {
					const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
					return pattern.test(value) || '正しいメールアドレスを入力してください'
				},
				year: value => {
					const pattern = /^\d{4}$/
					return pattern.test(value) && value > 1900 && value <= 2024 || '西暦年を正しく入力してください'
				},
			},
			ages: [
				{ text: '20代', value: 1 },
				{ text: '30代', value: 2 },
				{ text: '40代', value: 3 },
				{ text: '50代', value: 4 },
				{ text: '60代以降', value: 5 },
				{ text: '回答しない', value: 6 },
			],
			genders: [
				{ text: '男', value: 1 },
				{ text: '女', value: 2 },
				{ text: 'その他', value: 3 },
				{ text: '回答しない', value: 4 },
			],
			motivations: [
				{ text: "他の科目の履修を犠牲にしてでも、司書資格を取得するつもりであった", value: 1 },
				{ text: "他に取りたい資格や優先したいことがなければ、司書資格を取得するつもりであった", value: 2 },
				{ text: "授業が面白くなければ／課題が大変なら、途中で資格取得はやめるかもしれなかった", value: 3 },
				{ text: "とりあえず課程登録はしたが、司書資格を取得する気はあまりなかった", value: 4 },
			],
			occupations: [
				{ text: "公共図書館", value: 1 },
				{ text: "大学図書館", value: 2 },
				{ text: "学校図書館", value: 3 },
				{ text: "その他の図書館", value: 4 },
				{ text: "図書館関連企業（TRC、取次など）", value: 5 },
				{ text: "書店", value: 6 },
				{ text: "出版社", value: 7 },
				{ text: "情報（コンピュータ関連など）", value: 8 },
				{ text: "その他", value: 99 },
			],
			resDialog: false
		}
	},
	async created () {
		this.setAlert(false)
		await this.getPublishedTest(this.testId)
	},
	computed: {
		...mapGetters({
			userId: 'stateUserId',
			loading: 'stateLoading',
			alert: 'stateAlert',
			test: 'stateTest',
			testAnswer: 'stateTestAnswer',
		}),
		testId() {
			return this.$route.params.id
		}
	},
	methods: {
		...mapActions(['getPublishedTest', 'setLoading', 'setAlert', 'postAlumniSurveyAnswer']),
		async post() {
			if (this.$refs.surveyForm.validate()) {

				this.setLoading(true)
				if (this.form.interview == null) {
					this.form.interview = 2
				}
				const payload = {
					'motivation': this.form.motivation,
					'occupation': this.form.occupation,
					'other_occupation': this.form.other_occupation,
					'past_occupations': this.form.past_occupations,
					'other_past_occupation': this.form.other_past_occupation,
					'interests': this.form.interests,
					'benefits': this.form.benefits,
					'desired': this.form.desired,
					'gender': this.form.gender,
					'age': this.form.age,
					'university': this.form.university,
					'certificate_year': this.form.certificate_year,
					'comment': this.form.comment,
					'user_id': this.userId,
					'test_id': this.testId,
					'testanswer_id': this.testAnswer.id
				}
				await this.postAlumniSurveyAnswer(payload).then(() => {
					this.setAlert(false)
					this.resDialog = true
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
				this.setLoading(false)
			}
		},
		closeResDialog() {
			this.resDialog = false
			this.$router.push({ name: 'Test', params: {id: this.testId }})
		}
	}
}
</script>