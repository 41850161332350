<template>
	<v-dialog
		v-model="dialog"
		width="600"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="primary"
				:dark="isDark"
				:light="isDisabled"
				class="mb-2"
				v-bind="attrs"
				v-on="on"
				:disabled="isDisabled"
				@click.stop="ensureAdmin"
			>
				新しいセット
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="text-h5">新しいセット</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="newSetForm">
					<v-container>
						<v-row><v-col>
							<v-text-field
								v-model="editedSet.name"
								label="セット名"
								:rules="[rules.required, rules.max20chars]"
								counter
								maxlength="20"
							></v-text-field>
						</v-col></v-row>
						<v-row><v-col>
							<v-select
								v-model="editedSet.termbank_id"
								:items="termBanks"
								item-text="name"
								item-value="id"
								label="ターム集"
								:rules="[rules.required]"
							></v-select>
						</v-col></v-row>
						<v-row><v-col>
							<v-textarea
								v-model="editedSet.terms"
								label="ターム（改行区切り）"
								:rules="[rules.required]"
							></v-textarea>
						</v-col></v-row>
						<v-row><v-col>
							<v-checkbox
								v-model="editedSet.is_published"
								label="公開"
							></v-checkbox>
						</v-col></v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="close"
				>
					キャンセル
				</v-btn>
				<v-btn
					color="blue darken-1"
					text
					:loading="loading"
					@click="save"
				>
					保存
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>{{ setRes.name }}</v-card-title>
				<v-card-text>
					{{ setRes.number_of_terms }}個のタームを登録しました。<br/><br/>
					<div v-if="setRes.terms_not_added.length">
						登録されなかったターム：<br/>
						<ul>
							<li v-for="(term, index) in setRes.terms_not_added" :key="'terms-not-added-' + index">{{ term }}</li>
						</ul>
					</div>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminNewSet',
	data () {
		return {
			dialog: false,
			resDialog: false,
			newSet: {
				name: '',
				terms: '',
				termbank_id: null,
				is_published: false
			},
			editedSet: {
				name: '',
				terms: '',
				termbank_id: null,
				is_published: false
			},
			rules: {
				required: value => !!value || 'Required',
				max20chars: value => value.length <= 20 || 'Max 20 characters'
			}
		}
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			loading: 'stateLoading',
			setRes: 'stateSetRes',
			termBanks: 'stateTermBanks',
			isAdminAuthenticated: 'isAdminAuthenticated'
		}),
		isDark() {
			if (!this.loading && this.admin) {
				return true
			} else {
				return false
			}
		},
		isDisabled() {
			if (this.loading || !this.admin) {
				return true
			} else {
				return false
			}
		}
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.$refs.newSetForm.resetValidation()
				this.$nextTick(() => {
					this.editedSet = Object.assign({}, this.newSet)
				})
				this.setLoading(false)
			}
			if (value === true) {
				this.getTermBanks()
				this.setAlert(false)
			}
		},
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 2000)
			}
		}
	},
	methods: {
		...mapActions(['createSet', 'setLoading', 'setAlert', 'getTermBanks', 'checkAdmin', 'adminLogout']),
		close() {
			this.dialog = false
			this.setLoading(false)
		},
		async save() {
			if (this.$refs.newSetForm.validate()) {
				this.setLoading(true)
				const terms = this.makeTerms(this.editedSet.terms)
				const set = {
					"name": this.editedSet.name,
					"terms": terms,
					"is_published": this.editedSet.is_published,
					"termbank_id": this.editedSet.termbank_id
				}
				await this.createSet(set).then(() => {
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
				this.setLoading(false)
				this.close()
				this.resDialog = true
			}
		},
		closeResDialog() {
			this.resDialog = false
		},
		makeTerms(string) {
			const arr = string.split(/\r\n|\n/)
			return arr
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}
</script>