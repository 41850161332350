<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290"
        min-width="auto"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="picker"
                :placeholder="placeholder"
                :label="label"
                prepend-icon="mdi-calendar"
                v-bind="attrs"
                v-on="on"
                single-line
                @input="dateTyped"
            ></v-text-field>
        </template>
        <v-date-picker
            v-model="picker"
            no-title
            @click="menu = false">
        </v-date-picker>
    </v-menu>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: new Date().toISOString().substring(0, 10)
        },
        label: {
            type: String,
            default: "date"
        },
        placeholder: {
            type: String,
            default: "YYYY-MM-DD"
        }
    },
    data () {
        return {
            menu: false
        }
    },
    computed: {
        picker: {
            get () {
                return this.value
            },
            set (val) {
                this.menu = false
                this.$emit("input", val)
            }
        }
    },
    methods: {
        dateTyped (val) {
        if (!val || val.length !== 10 || !this.isValidDate(val)) {
            return
        } else {
            this.picker = val
        }
        },
        isValidDate (date) {
            return date.match(/^\d{4}-\d{2}-\d{2}$/) && !isNaN(Date.parse(date));
        }
    }
}

</script>