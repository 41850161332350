import axios from 'axios'


const state = {
    plainadmin: null
}

const getters = {
    isPlainAdminAuthenticated: state => !!state.plainadmin,
    statePlainAdmin: state => state.plainadmin
}

const actions = {
    async plainAdminLogin({dispatch}, plainadmin) {
        await axios.post('/plainadmin/token', plainadmin)
        await dispatch('getPlainAdmin')
    },
    async getPlainAdmin({commit}) {
        let {data} = await axios.get('/plainadmin/me')
        commit('setPlainAdmin', data)
    },
    async checkPlainAdmin() {
        await axios.get('/plainadmin/me')
    },
    plainAdminLogout({commit}) {
        let plainadmin = null
        commit('setPlainAdmin', plainadmin)
    }
}

const mutations = {
    setPlainAdmin(state, username) {
        state.plainadmin = username
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
