<template>
	<v-dialog
		v-model="dialog"
		width="1400"
		scrollable
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="blue-grey"
				dark
				v-bind="attrs"
				v-on="on"
				@click="clickGetAnswers(id)"
			>{{ term }}</v-btn>
		</template>
		<v-card>
			<v-card-title>{{ term }}</v-card-title>
			<v-card-text>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
				<v-data-table
					:headers="headers"
					:items="termAnswers"
					:search="search"
					:items-per-page="10"
					:footer-props="{
						showFirstLastPage: true
					}"
					:loading="loading"
				>
					<template v-slot:item.answer="{ item }">
						<v-chip
							:color="getColor(item.answer)"
							dark
						>
							{{ getAnswerText(item.answer) }}
						</v-chip>
					</template>
					<template v-slot:item.created_at="{ item }">
						{{ formatDate(item.created_at) }}
					</template>
				</v-data-table>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="close"
				>
					OK
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminTermAnswers',
	props: ['id', 'term'],
	data () {
		return {
			dialog: false,
			search: '',
			headers: [
				{ text: 'Term', value: 'term' },
				{ text: 'Set', value: 'set' },
				{ text: 'Score', value: 'score' },
				{ text: 'Score Alt', value: 'score_alt' },
				{ text: 'Familiarity', value: 'term_familiarity' },
				{ text: 'Rank', value: 'term_rank' },
				{ text: 'Answer', value: 'answer' },
				{ text: 'Created at', value: 'created_at' }
			]
		}
	},
	computed: {
		...mapGetters({ termAnswers: 'stateTermAnswers', loading: 'stateLoading' })
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.clearTermAnswers()
			}
		}
	},
	methods: {
		...mapActions(['getTermAnswers', 'clearTermAnswers', 'setLoading']),
		async clickGetAnswers(id) {
			this.setLoading(true)
			await this.getTermAnswers(id).then(() => {
				this.setLoading(false)
			}).catch(() => {
				this.setLoading(false)
			})
		},
		close() {
			this.dialog = false
		},
		getColor(value) {
			if (value === 1) return 'orange'
			else if (value === 2) return 'light-green'
			else if (value === 3) return 'green'
		},
		getAnswerText(value) {
			if (value === 1) return '全く知らない'
			else if (value === 2) return '見たり聞いたりしたことがある'
			else if (value === 3) return '簡潔に説明できる'
		},
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		}
	}
}
</script>