import axios from 'axios'


const state = {
	admin: null
}

const getters = {
	isAdminAuthenticated: state => !!state.admin,
	stateAdmin: state => state.admin,
}

const actions = {
	async adminLogin({dispatch}, admin) {
		await axios.post('/admin/token', admin)
		await dispatch('getAdmin')
	},
	async getAdmin({commit}) {
		let {data} = await axios.get('/admin/me')
		commit('setAdmin', data)
	},
	async checkAdmin() {
		await axios.get('/admin/me')
	},
	adminLogout({commit}) {
		let admin = null
		commit('setAdmin', admin)
	}
}

const mutations = {
	setAdmin(state, username) {
		state.admin = username
	}
}

export default {
	state,
	getters,
	actions,
	mutations
}
