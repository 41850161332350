import axios from 'axios'


const state = {
	sets: [],
	publishedSets: [],
	set: {
		"terms": []
	},
	setRes: {
		"terms_not_added": []
	},
}

const getters = {
	stateSets: state => state.sets,
	statePublishedSets: state => state.publishedSets,
	stateSet: state => state.set,
	stateSetRes: state => state.setRes,
}

const actions = {
	async createSet({dispatch, commit}, set) {
		let {data} = await axios.post('/sets/', set)
		commit('setSetRes', data)
		await dispatch('getSets')
	},
	async getSets({commit}) {
		let {data} = await axios.get('/sets/')
		commit('setSets', data)
	},
	async getPublishedSets({commit}) {
		let {data} = await axios.get('/sets/published/')
		commit('setPublishedSets', data)
	},
	async getSet({commit}, id) {
		let {data} = await axios.get(`/sets/${id}`)
		commit('setSet', data)
	},
	async getPublishedSet({commit}, id) {
		let {data} = await axios.get(`/sets/published/${id}`)
		commit('setSet', data)
	},
	async updateSetName({dispatch}, {id, name}) {
		const payload = {"name": name}
		await axios.put(`/sets/${id}/name`, payload)
		await dispatch('getSets')
	},
	async updateSetPublished({dispatch}, {id, is_published}) {
		const payload = {"is_published": is_published}
		await axios.put(`/sets/${id}/published`, payload)
		await dispatch('getSets')
	},
	async getSetsByTermBank({commit}, termBankId) {
        let {data} = await axios.get(`/sets/?termbank=${termBankId}`)
		commit('doNothing')
		return data
    },
	async deleteSet({dispatch}, id) {
		await axios.delete(`/sets/${id}`)
		await dispatch('getSets')
	},
	clearSets({commit}) {
		commit('setSets', [])
	},
	clearSet({commit}) {
		const set = {
			"terms": []
		}
		commit('setSet', set)
	},
	clearPublishedSets({commit}) {
		commit('setPublishedSets', [])
	}
}

const mutations = {
	setSets(state, sets) {
		state.sets = sets
	},
	setPublishedSets(state, sets) {
		state.publishedSets = sets
	},
	setSet(state, set) {
		state.set = set
	},
	setSetRes(state, res) {
		state.setRes = res
	},
	doNothing() {
		return 0
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}
