<template>
	<v-app>
		<div v-if="parentRouteName === 'Admin'">
			<AdminNavBar/>
		</div>
		<div v-if="parentRouteName === 'User'">
			<UserAppBar/>
		</div>
		<div v-if="parentRouteName === 'NotFound' || parentRouteName === null">
			<UserAppBar/>
		</div>

		<v-container class="mb-16">
			<v-row class="text-center">
				<v-main>
					<router-view></router-view>
				</v-main>
			</v-row>
		</v-container>
		<v-footer
			fixed
			v-if="this.$route.name !== 'Answer' && this.$route.name !== 'Survey' && this.$route.name !== 'TestAnswer' && this.$route.name !== 'SurveyHome' && this.$route.name !== 'Q1' && this.$route.name !== 'Q2' && this.$route.name !== 'Q3' && this.$route.name !== 'Q4' && this.$route.name !== 'Q5' && this.$route.name !== 'Q6' && this.$route.name !== 'Q7' && this.$route.name !== 'Q8' && this.$route.name !== 'ScaleJA' && this.$route.name !== 'ScaleEN'"
		>
			<v-col
				class="text-center body-2"
			>
				&copy; {{ new Date().getFullYear() }} Developed by <a href="https://zhuxinru.com/" target="_blank" class="researcher">Xinru Zhu</a>, co-researching with <a href="https://eblise.org/" target="_blank" class="researcher">EBLISE</a> team.
			</v-col>
		</v-footer>
	</v-app>
</template>

<style>
.v-application {
	font-family: "Roboto", "Helvetica Neue", "Helvetica", "Noto Sans JP", "Hiragino Sans", "Hiragino Kaku Gothic ProN", "Arial", "Yu Gothic", "Meiryo", sans-serif;
}
</style>

<script>
import AdminNavBar from '@/components/AdminNavBar'
import UserAppBar from '@/components/UserAppBar'

export default {
	name: 'App',
	components: {
		AdminNavBar,
		UserAppBar
	},
	computed: {
		parentRouteName() {
			if (this.$route.matched[0]) {
				return this.$route.matched[0].name
			} else {
				return null
			}
		}
	}
}
</script>

<style scoped>
.researcher {
	text-decoration: none;
}
</style>