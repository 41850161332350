<template>
	<div>
		<v-alert
			:value="alert"
			dense
			outlined
			text
			prominent
			type="error"
			transition="scale-transition"
		>
			エラーが発生しました。ログアウト後再度ログインしてください。
		</v-alert>
		<v-data-table
			:headers="headers"
			:items="scores"
			:search="search"
			:item-per-page="10"
			:sort-by.sync="columnName"
			:sort-desc.sync="isDescending"
			:footer-props="{
				showFirstLastPage: true
			}"
			:loading="loading"
		>
			<template v-slot:top>
				<v-toolbar flat>
					<v-container>
						<v-row>
							<v-spacer></v-spacer>
							<v-btn
								@click="download"
								:disabled="loading || !scores.length || !admin"
							>
								結果をダウンロード
							</v-btn>
						</v-row>
					</v-container>
				</v-toolbar>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
			</template>
			<template v-slot:item.score="{ item }">
				<span v-if="item.score">
					{{ item.score }} ({{ Math.round((item.ratio + Number.EPSILON) * 100) }}%)
				</span>
			</template>
			<template v-slot:item.score_alt="{ item }">
				<span v-if="item.score_alt">
					{{ item.score_alt }} ({{ Math.round((item.ratio_alt + Number.EPSILON) * 100) }}%)
				</span>
			</template>
			<template v-slot:item.strict_score="{ item }">
				<span v-if="item.strict_score">
					{{ item.strict_score }} ({{ Math.round((item.strict_ratio + Number.EPSILON) * 100) }}%)
				</span>
			</template>
			<template v-slot:item.strict_score_alt="{ item }">
				<span v-if="item.strict_score_alt">
					{{ item.strict_score_alt }} ({{ Math.round((item.strict_ratio_alt + Number.EPSILON) * 100) }}%)
				</span>
			</template>
			<template v-slot:item.sets.length="{ item }">
				<AdminAnswers :item="item" />
			</template>
			<template v-slot:item.duration="{ item }">
				{{ getTimeDiff(item, item.start_at, item.created_at) }}
			</template>
			<template v-slot:item.created_at="{ item }">
				{{ formatDate(item.created_at) }}
			</template>
			<template v-slot:item.delete="{ item }">
				<AdminDeleteButton :deleteFunction="deleteScore" :item="item" :isAll="true" :isCritical="true" />
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminAnswers from '@/components/AdminAnswers'
import AdminDeleteButton from '@/components/AdminDeleteButton'

export default {
	name: 'AdminScores',
	components: {
		AdminAnswers,
		AdminDeleteButton
	},
	data () {
		return {
			search: '',
			columnName: 'id',
			isDescending: false,
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Score', value: 'score' },
				{ text: 'Score alt', value: 'score_alt' },
				{ text: 'Strict score', value: 'strict_score' },
				{ text: 'Strict score alt', value: 'strict_score_alt' },
				{ text: 'Number of sets', value: 'sets.length' },
				{ text: 'Test', value: 'test.name' },
				{ text: 'User', value: 'user.username' },
				{ text: 'Duration (s)', value: 'duration' },
				{ text: 'Created at', value: 'created_at' },
				{ text: 'Delete', value: 'delete' }
			]
		}
	},
	async created () {
		await this.getScores()
	},
	computed: {
		...mapGetters({
			admin: 'stateAdmin',
			scores: 'stateScores',
			loading: 'stateLoading',
			alert: 'stateAlert',
			isAdminAuthenticated: 'isAdminAuthenticated',
		})
	},
	methods: {
		...mapActions(['getScores', 'downloadAnswers', 'setLoading', 'setAlert', 'deleteScore', 'checkAdmin', 'adminLogout']),
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		getTimeDiff(item, startAt, createdAt) {
			if (item.sets.length === 1) {
				if (startAt && createdAt) {
					let startAtDate = new Date(startAt)
					let createdAtDate = new Date(createdAt)
					return (createdAtDate - startAtDate) / 1000
				} else {
					return null
				}
			} else {
				return null
			}
		},
		async download() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}

			if (this.isAdminAuthenticated) {
				this.setLoading(true)
				await this.downloadAnswers().then((response) => {
					let blob = new Blob([response.data], { type: 'text/tsv' })
					let filename = response.headers['content-disposition'].split('filename=')[1].split(';')[0]
					let url = window.URL.createObjectURL(blob)
					let dllink = document.createElement('a')
					dllink.href = url
					dllink.setAttribute('download', filename)
					dllink.click()
					this.setLoading(false)
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
			}
		}
	}
}
</script>
