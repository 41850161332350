<template>
	<div class="mt-5">
		<div v-if="!error">
			<h1>
				<span>
					{{ set.name }}
				</span>
			</h1>
			<v-card
				class="mx-auto"
				max-width="800"
				tile
				flat
				id="desc"
				v-if="setLength"
			>
				<p class="mt-10 mb-10 mr-10 ml-10 text-left" id="scroll-to-0">
					{{ setLength }}個の項目それぞれについて、「簡潔に説明できる」「見たり聞いたりしたことがある」「全く知らない」の中から、あなたに当てはまるものを選んでください。<br/>
				</p>
			</v-card>
			<UserAnswerCard
				v-for="(term, index) in terms"
				:key="'card-' + term.id"
				:term="term"
				:index="index + 1"
			></UserAnswerCard>
			<v-card
				class="mx-auto mt-10"
				max-width="800"
				tile
				flat
				v-if="notAnsweredId.length"
			>
				<p class="mb-5">未回答の項目があります。</p>
				<v-chip
					v-for="item in notAnsweredId"
					:key="'not-answered-' + item"
					class="ma-1"
					color="error"
					outlined
					link
					@click="scrollTo('scroll-to-' + (item - 1))"
				>
					{{ item }}
				</v-chip>
			</v-card>
			<v-dialog
				v-model="dialog"
				max-width="500"
				v-if="setLength"
			>
				<template v-slot:activator="{ on, attrs }">
					<v-btn
						color="primary"
						x-large
						depressed
						class="mt-16 mb-10"
						width="150"
						:disabled="notAnswered.length !== 0"
						v-bind="attrs"
						v-on="on"
						@click="estimate"
					>
						語彙量を推定する
					</v-btn>
				</template>
				<v-card>
					<v-card-title class="text-h5">推定語彙量</v-card-title>
					<v-card-text class="mt-10 text-center">
						<!-- <v-alert
							dense
							outlined
							text
							type="error"
							v-if="alert"
						>
							想定していない回答パターンだったため、<br/>推定できませんでした。
						</v-alert> -->
						<v-progress-circular
							v-if="loading"
							:indeterminate="loading"
						></v-progress-circular>
						<div v-if="!loading && !alert">
							<v-alert
								dense
								outlined
								text
								type="info"
								v-if="answeredSets.length > 1"
							>
								{{ answeredSets.length }}つのセットから推定しています。
							</v-alert>
							<!-- <v-alert
								dense
								outlined
								text
								type="warning"
								v-if="answerScoreAlt"
							>
								想定していない回答パターンだったため、<br/>推定結果が正確ではない可能性があります。
							</v-alert> -->
							<span class="text-h4" v-if="answerScore">{{ answerScore }} 語</span>
							<span class="text-h4" v-else-if="answerScoreAlt">{{ answerScoreAlt }} 語</span><br/><br/>
							<span class="text-h6">
							あなたは図書館情報学重要語彙の<br/>
							<span v-if="answerRatio">{{ Math.round((answerRatio + Number.EPSILON) * 100) }}%</span>
							<span v-else-if="answerRatioAlt">{{ Math.round((answerRatioAlt + Number.EPSILON) * 100) }}%</span><br/>
							を知っています
							</span>
							<v-alert
								dense
								outlined
								text
								class="mt-10"
								type="success"
							>
								次へ進んでください。
							</v-alert>
						</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							text
							@click="close"
						>
							OK
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<div v-else>
			<p class="mt-10 mb-10">
				問題が存在しないようです。
			</p>
		</div>
		<v-btn
			:to="`/test/${testId}`"
			large
			depressed
			class="mt-16"
			width="330"
			v-if="error || !setLength"
		>
			戻る
		</v-btn>
	</div>
</template>

<script>
import smoothscroll from 'smoothscroll-polyfill'
import { mapActions, mapGetters } from 'vuex'
import UserAnswerCard from '@/components/UserAnswerCard'

export default {
	name: 'UserAnswer',
	components: {
			UserAnswerCard
	},
	data () {
		return {
				error: false,
				dialog: false,
				terms: [],
				startAt: null,
			}
	},
	computed: {
		...mapGetters({
			set: 'stateSet',
			score: 'stateScore',
			notAnswered: 'stateNotAnswered',
			answerScore: 'stateAnswerScore',
			answerRatio: 'stateAnswerRatio',
			answerScoreAlt: 'stateAnswerScoreAlt',
			answerRatioAlt: 'stateAnswerRatioAlt',
			loading: 'stateLoading',
			alert: 'stateAlert',
			answeredSets: 'stateAnsweredSets',
			currentAnswerTermBank: 'stateCurrentAnswerTermBank',
			allAnswers: 'stateAllAnswers',
			userId: 'stateUserId',
			testAnswer: 'stateTestAnswer',
		}),
		testId() {
			return this.$route.params.test_id
		},
		setId() {
			return this.$route.params.set_id
		},
		setLength() {
			return this.set.terms.length
		},
		notAnsweredId() {
			return this.getNotAnsweredId()
		}
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.clearAnswerScore()
				this.setAlert(false)
			}
		}
	},
	async created () {
		this.setLoading(true)
		await this.getSet(this.setId).then(() => {
			if (this.currentAnswerTermBank != this.set.termbank.id) {
				this.clearAllAnswers()
				this.clearAnsweredSets()
				this.setCurrentAnswerTermBank(this.set.termbank.id)
			}
			this.clearAnswers()
			this.setNotAnswered(this.set.terms)
			this.clearAnswerScore()
			this.setLoading(false)
			this.terms = this.set.terms
			this.startAt = Date.now()
		}).catch(err => {
			this.clearSet()
			this.error = true
			this.setLoading(false)
			console.error(err)
		})
	},
	methods: {
		...mapActions([
			'clearSet',
			'getSet',
			'clearAnswers',
			'createScore',
			'createScoreSingleSet',
			'appendAnsweredSets',
			'setNotAnswered',
			'postAnswers',
			'postAnswersSingleSet',
			'clearAnswerScore',
			'setLoading',
			'setAlert',
			'setCurrentAnswerTermBank',
			'setAnsweredSets',
			'setAllAnswers',
			'clearAllAnswers',
			'clearAnsweredSets',
			'appendTestSetAnswered',
		]),
		async estimate() {
			this.setLoading(true)
			const newAnsweredSets = this.answeredSets.filter(n => n !== this.set.id)
			this.setAnsweredSets(newAnsweredSets)
			this.appendAnsweredSets(this.set.id)
			if (this.answeredSets.length > 1) {
				const payloadSingleSet = {
					sets: [this.set.id],
					user_id: this.userId,
					test_id: this.testId,
					testanswer_id: this.testAnswer.id,
					start_at: this.startAt,
				}
				let singleSetScore = await this.createScoreSingleSet(payloadSingleSet)
				const requestSingleSet = {
					set: this.set,
					score_id: singleSetScore.id
				}
				await this.postAnswersSingleSet(requestSingleSet)
			}
			const payload = {
				sets: this.answeredSets,
				user_id: this.userId,
				test_id: this.testId,
				testanswer_id: this.testAnswer.id,
				start_at: this.startAt,
			}
			await this.createScore(payload).catch(err => {
				this.setAlert(true)
				this.setLoading(false)
				console.error(err)
			})
			const request = {
				set: this.set,
				score_id: this.score.id
			}
			await this.postAnswers(request).then(() => {
				this.setLoading(false)
				this.appendTestSetAnswered(this.set.id)
			}).catch(err => {
				this.setAlert(true)
				this.setLoading(false)
				console.error(err)
			})
		},
		close() {
			this.dialog = false
			this.setAlert(false)
			this.$router.push({ name: 'Test', params: {id: this.testId }})
		},
		getNotAnsweredId() {
			return this.notAnswered
		},
		scrollTo(id) {
			smoothscroll.polyfill()
			document.getElementById(id).scrollIntoView({
				behavior: 'smooth'
			})
		}
	}
}
</script>
