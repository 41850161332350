var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1400","scrollable":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue-grey","dark":""},on:{"click":function($event){return _vm.clickGetAnswers(_vm.id)}}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.term))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.term))]),_c('v-card-text',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.termAnswers,"search":_vm.search,"items-per-page":10,"footer-props":{
					showFirstLastPage: true
				},"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.answer",fn:function(ref){
				var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.answer),"dark":""}},[_vm._v(" "+_vm._s(_vm.getAnswerText(item.answer))+" ")])]}},{key:"item.created_at",fn:function(ref){
				var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.close}},[_vm._v(" OK ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }