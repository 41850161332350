<template>
	<v-container>
		<v-row>
			<v-col class="mx-auto">
				<v-card
					class="mx-auto"
					max-width="500"
					tile
					flat
				>
					<p class="mt-5 mb-5 text-center">
						司書課程履修動機尺度（Motivation Scale for Taking Librarian Course）をお試しいただけます。
						言語を選んで回答を始めてください。<br/>
						<span class="caption text--secondary">（現在は日本語版と英語版のみ提供しています）</span>
					</p>
				</v-card>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-card
					class="mx-auto"
					max-width="400"
					flat
				>
					<v-btn
						color="indigo accent-3"
						dark
						x-large
						elevation="0"
						class="mx-auto col-10 mb-5"
						to="scale/ja"
						key="scale-ja"
					>
						MSLC（日本語）
					</v-btn>
					<v-btn
						color="indigo accent-3"
						dark
						x-large
						elevation="0"
						class="mx-auto col-10 mb-5"
						to="scale/en"
						key="scale-en"
					>
						MSLC（English）
					</v-btn>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'UserHome',
	created () {
		this.clearSet()
		this.clearSets()
		this.clearTerms()
		this.clearPublishedSets()
		this.clearAnswers()
		this.clearAnswerScore()
		this.setNotAnswered(this.set.terms)
		this.clearScore()
		this.clearScores()
		this.clearSurveyAnswered()
		this.clearLibSurveyAnswered()
		this.clearMotSurveyAnswered()
		this.clearSurveyAnswers()
		this.clearLibSurveyAnswers()
		this.clearMotSurveyAnswers()
		this.clearUsers()
		this.clearMyUsers()
		this.clearUserUsers()
		this.clearPlainAdmins()
		this.clearGroupUsers()
		this.clearCurriculum()
		this.clearCurriculums()
		this.clearTests()
		this.clearPublishedTests()
		this.clearTermBanks()
		this.clearAnsweredScales()
		this.clearScaleItemAnswers()
		this.clearScaleAnswers()
		this.clearScaleItemNotAnswered()
		this.setLoading(false)
		this.setAlert(false)
	},
	computed: {
		...mapGetters({ set: 'stateSet' }),
		isExperimenting() {
			const isExperimenting = process.env.VUE_APP_IS_EXPERIMENTING
			if (isExperimenting === 'true') {
				return true
			} else {
				return false
			}
		}
	},
	methods: {
		...mapActions(['clearSets', 'clearSet', 'clearTerms', 'clearPublishedSets', 'setLoading', 'setAlert', 'clearAnswers', 'setNotAnswered', 'clearScore', 'clearScores', 'clearAnswerScore', 'clearSurveyAnswered', 'clearLibSurveyAnswered', 'clearUsers', 'clearMyUsers', 'clearUserUsers', 'clearPlainAdmins', 'clearSurveyAnswers', 'clearLibSurveyAnswers', 'clearMotSurveyAnswered', 'clearMotSurveyAnswers', 'clearGroupUsers', 'clearCurriculum', 'clearCurriculums', 'clearTests', 'clearPublishedTests', 'clearTermBanks', 'clearAnsweredScales', 'clearScaleItemAnswers', 'clearScaleAnswers', 'clearScaleItemNotAnswered'])
	}
}
</script>

<style scoped>
.v-divider {
	border-width: 1px;
}
</style>