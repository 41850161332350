<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="items"
			:search="search"
			:items-per-page="10"
			:sort-by.sync="columnName"
			:sort-desc.sync="isDescending"
			:footer-props="{
				showFirstLastPage: true
			}"
			:loading="loading"
		>
			<template v-slot:top>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search"
					single-line
					hide-details
				>
				</v-text-field>
			</template>
			<template v-slot:item.scaleitem.created_at="{ item }">
				{{ formatDate(item.scaleitem.created_at) }}
			</template>
		</v-data-table>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
	name: 'ScaleItemsTable',
	props: ['items'],
	data () {
		return {
			columnName: 'id',
			isDescending: false,
			search: '',
			headers: [
				{ text: 'Order', value: 'order' },
				{ text: 'ID', value: 'scaleitem.id' },
				{ text: 'Text', value: 'scaleitem.text' },
				{ text: 'Created at', value: 'scaleitem.created_at' },
			],
		}
	},
	computed: {
		...mapGetters({ loading: 'stateLoading' }),
	},
	methods: {
		formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
	}
}
</script>