<template>
	<v-dialog
		v-model="dialog"
		width="1000"
		scrollable
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="blue-grey"
				:dark="!loading && canClick"
				:light="loading || !canClick"
				v-bind="attrs"
				v-on="on"
                :disabled="loading || !canClick"
                @click="getGroupTests(item.id)"
			>{{ item.tests.length }}</v-btn>
		</template>
		<v-card>
			<v-card-title>
				{{ item.name }}のテスト
			</v-card-title>
			<v-card-text>
				<v-data-table
                    :headers="headers"
                    :items="tests"
                    :search="search"
                    :items-per-page="10"
                    :sort-by.sync="columnName"
                    :sort-desc.sync="isDescending"
                    :footer-props="{
                        showFirstLastPage: true
                    }"
                    :loading="loading"
                >
                    <template v-slot:top>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        >
                        </v-text-field>
                    </template>
                    <template v-slot:item.name="props">
                        <v-edit-dialog
                            large
                            save-text="保存"
                            cancel-text="キャンセル"
                            @open="updateNameOpen(props.item.name)"
                            @cancel="setAlert(false)"
                            @save="updateName(props.item.id, editedName)"
                            v-if="canEdit(props.item)"
                        >
                            <div>{{ props.item.name }}</div>
                            <template v-slot:input>
                                <v-form ref="updateNameForm">
                                    <v-text-field
                                        v-model="editedName"
                                        :rules="[rules.required, rules.max20chars]"
                                        label="Edit"
                                        single-line
                                        counter
                                        maxlength="20"
                                    ></v-text-field>
                                </v-form>
                            </template>
                        </v-edit-dialog>
                        <div v-else>{{ props.item.name }}</div>
                    </template>
                    <template v-slot:item.number_of_sets="{ item }">
                        <AdminViewTestSets :item="item"/>
                    </template>
                    <template v-slot:item.survey_type="{ item }">
                        {{ getSurveyTypeText(item.survey_type) }}
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ formatDate(item.created_at) }}
                    </template>
                    <template v-slot:item.is_published="{ item }">
                        <v-simple-checkbox
                            v-model="item.is_published"
                            v-ripple
                            :disabled="loading || !canEdit(item)"
                            @input="updatePublished(item)"
                        ></v-simple-checkbox>
                    </template>
                </v-data-table>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="close"
				>
					OK
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import AdminViewTestSets from '@/components/AdminViewTestSets'

export default {
	name: 'AdminViewTests',
	props: ['item'],
	components: {
		AdminViewTestSets,
	},
	data () {
		return {
            dialog: false,
			columnName: 'id',
			isDescending: false,
			search: '',
			headers: [
				{ text: 'ID', value: 'id' },
				{ text: 'Name', value: 'name' },
				{ text: 'Number of sets', value: 'number_of_sets' },
				{ text: 'Survey type', value: 'survey_type'},
				{ text: 'Created at', value: 'created_at' },
				{ text: 'Published', value: 'is_published' },
			],
			rules: {
				required: value => !!value || 'Required',
				max20chars: value => value.length <= 20 || 'Max 20 characters'
			},
			editedName: ''
		}
	},
	computed: {
		...mapGetters({
			tests: 'stateGroupTests',
			test: 'stateTest',
			res: 'stateTestRes',
			alert: 'stateAlert',
			loading: 'stateLoading',
			admin: 'stateAdmin',
			plainadmin: 'statePlainAdmin',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated'
		}),
        canClick() {
			if (this.admin) {
				return true
			} else {
				if (this.isAll) {
					return false
				} else {
					if (this.item.admin_id == this.plainadmin.id) {
						return true
					} else {
						return false
					}
				}
			}
		},
	},
    watch: {
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 1000)
			}
		}
	},
	methods: {
        ...mapActions(['updateTestName', 'updateTestPublished', 'setLoading', 'setAlert', 'getTests', 'deleteTest', 'checkAdmin', 'checkPlainAdmin', 'adminLogout', 'plainAdminLogout', 'getGroupTests']),
		close() {
			this.dialog = false
		},
        formatDate(date) {
			return new Date(date).toLocaleString('ja-JP')
		},
		getSurveyTypeText(value) {
			if (value === 1) return '学生用'
			else if (value === 2) return '司書用'
			else if (value === 3) return '学生動機調査用'
			else if (value === 4) return '卒業生動機調査用'
			else if (value === 5) return '卒業生学習内容調査用'
			else return '不明'
		},
        updateNameOpen(name) {
			if (this.admin) {
				this.ensureAdmin()
			} else {
				this.ensurePlainAdmin()
			}
			this.setAlert(false)
			this.editedName = name
		},
		canEdit(test) {
			if (this.admin) {
				return true
			} else if (this.plainadmin.id == test.admin_id) {
				return true
			} else {
				return false
			}
		},
		async updateName(id, name) {
			if (this.$refs.updateNameForm.validate()) {
				this.setLoading(true)
				const payload = {
					"id": id,
					"name": name
					}
				await this.updateTestName(payload).then(() => {
					this.setLoading(false)
					this.setAlert(false)
				}).catch(err => {
					this.setLoading(false)
					this.setAlert(true)
					console.error(err)
				})
			} else {
				this.setAlert(true)
			}
		},
		async updatePublished(item) {
			const payload = {
				"id": item.id,
				"is_published": item.is_published,
			}
			this.setLoading(true)
			await this.updateTestPublished(payload).then(() => {
				this.setLoading(false)
				this.setAlert(false)
			}).catch(err => {
				this.setLoading(false)
				this.setAlert(true)
				console.error(err)
			})
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		},
		async ensurePlainAdmin() {
			if (this.isPlainAdminAuthenticated) {
				await this.checkPlainAdmin().catch(() => {
					this.plainAdminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}
</script>