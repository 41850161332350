import axios from 'axios'


const state = {
    alumniSurveyAnswered: [],
    alumniSurveyAnswers: []
}

const getters = {
    stateAlumniSurveyAnswered: state => state.alumniSurveyAnswered,
    stateAlumniSurveyAnswers: state => state.alumniSurveyAnswers
}

const actions = {
    async postAlumniSurveyAnswer({commit}, payload) {
        await axios.post('/alumnisurveyanswers/', payload)
        commit('appendAlumniSurveyAnswered', payload.test_id)
    },
    async getAlumniSurveyAnswers({commit}) {
        let {data} = await axios.get('/alumnisurveyanswers/')
        commit('setAlumniSurveyAnswers', data)
    },
    async deleteAlumniSurveyAnswer({dispatch}, id) {
        await axios.delete(`/alumnisurveyanswers/${id}`)
        await dispatch('getAlumniSurveyAnswers')
    },
    async downloadAlumniSurveyAnswers() {
        return await axios.get('/alumnisurveyanswers/download/', { responseType: 'blob' })
    },
    clearAlumniSurveyAnswered({commit}) {
        commit('setAlumniSurveyAnswered', [])
    },
    clearAlumniSurveyAnswers({commit}) {
        commit('setAlumniSurveyAnswers', [])
    }
}

const mutations = {
    setAlumniSurveyAnswers(state, survey) {
        state.alumniSurveyAnswers = survey
    },
    setAlumniSurveyAnswered(state, survey) {
        state.alumniSurveyAnswered = survey
    },
    appendAlumniSurveyAnswered(state, testId) {
        state.alumniSurveyAnswered.push(testId)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}