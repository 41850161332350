import axios from 'axios'


const state = {
	courses: []
}

const getters = {
	stateCourses: state => state.courses
}

const actions = {
	async createCourse({dispatch}, course) {
		await axios.post('/courses/', course)
		await dispatch('getCourses')
	},
	async getCourses({commit}) {
		let {data} = await axios.get('/courses/')
		commit('setCourses', data)
	},
	async registerCourses({dispatch}) {
		await axios.post('/register_all_courses/')
		await dispatch('getCourses')
	},
	clearCourses({commit}) {
		commit('setCourses', [])
	}
}

const mutations = {
	setCourses(state, courses) {
		state.courses = courses
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}