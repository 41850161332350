import axios from 'axios'


const state = {
	libSurveyAnswered: [],
	libSurveyAnswers: []
}

const getters = {
	stateLibSurveyAnswered: state => state.libSurveyAnswered,
	stateLibSurveyAnswers: state => state.libSurveyAnswers
}

const actions = {
	async postLibSurveyAnswer({commit}, payload) {
		await axios.post('/libsurveyanswers/', payload)
		commit('appendLibSurveyAnswered', payload.test_id)
	},
	async getLibSurveyAnswers({commit}) {
		let {data} = await axios.get('/libsurveyanswers/')
		commit('setLibSurveyAnswers', data)
	},
	async deleteLibSurveyAnswer({dispatch}, id) {
		await axios.delete(`/libsurveyanswers/${id}`)
		await dispatch('getLibSurveyAnswers')
	},
	async downloadLibSurveyAnswers() {
		return await axios.get('/libsurveyanswers/download/', { responseType: 'blob' })
	},
	clearLibSurveyAnswered({commit}) {
		commit('setLibSurveyAnswered', [])
	},
	clearLibSurveyAnswers({commit}) {
		commit('setLibSurveyAnswers', [])
	}
}

const mutations = {
	setLibSurveyAnswers(state, survey) {
		state.libSurveyAnswers = survey
	},
	setLibSurveyAnswered(state, survey) {
		state.libSurveyAnswered = survey
	},
	appendLibSurveyAnswered(state, testId) {
		state.libSurveyAnswered.push(testId)
	}
}

export default {
	state,
	getters,
	actions,
	mutations,
}