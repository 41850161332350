var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-alert',{attrs:{"value":_vm.alert,"dense":"","outlined":"","text":"","prominent":"","type":"error","transition":"scale-transition"}},[_vm._v(" エラーが発生しました。ログアウト後再度ログインしてください。 ")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.termBanks,"search":_vm.search,"items-per-page":10,"sort-by":_vm.columnName,"sort-desc":_vm.isDescending,"footer-props":{
			showFirstLastPage: true
		},"loading":_vm.loading},on:{"update:sortBy":function($event){_vm.columnName=$event},"update:sort-by":function($event){_vm.columnName=$event},"update:sortDesc":function($event){_vm.isDescending=$event},"update:sort-desc":function($event){_vm.isDescending=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('AdminNewTermBank')],1),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.name",fn:function(props){return [(_vm.admin)?_c('v-edit-dialog',{attrs:{"large":"","save-text":"保存","cancel-text":"キャンセル"},on:{"open":function($event){return _vm.updateNameOpen(props.item.name)},"cancel":function($event){return _vm.setAlert(false)},"save":function($event){return _vm.updateName(props.item.id, _vm.editedName)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-form',{ref:"updateNameForm"},[_c('v-text-field',{attrs:{"rules":[_vm.rules.required, _vm.rules.max20chars],"label":"Edit","single-line":"","counter":"","maxlength":"20"},model:{value:(_vm.editedName),callback:function ($$v) {_vm.editedName=$$v},expression:"editedName"}})],1)]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(props.item.name))])]):_c('div',[_vm._v(_vm._s(props.item.name))])]}},{key:"item.number_of_terms",fn:function(ref){
		var item = ref.item;
return [_c('AdminViewTermBank',{attrs:{"item":item}})]}},{key:"item.created_at",fn:function(ref){
		var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.delete",fn:function(ref){
		var item = ref.item;
return [_c('AdminDeleteButton',{attrs:{"deleteFunction":_vm.deleteTermBank,"item":item,"isAll":true,"isCritical":true}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }