<template>
	<v-dialog
		v-model="dialog"
		width="800"
		scrollable
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="blue-grey"
				dark
				v-bind="attrs"
				v-on="on"
				@click="clickGetSet(item.id)"
			>{{ item.number_of_terms }}</v-btn>
		</template>
		<v-card>
			<v-card-title>
				{{ item.name }}
				<v-spacer></v-spacer>
				<div v-if="clipboard">
					<v-tooltip
						left
						:color="tooltipColor"
						v-model="tooltipShow"
					>
						<template v-slot:activator="{ attrs }">
							<v-btn
								icon
								color="blue"
								v-bind="attrs"
								@click="copyTerms"
							>
								<v-icon>mdi-content-copy</v-icon>
							</v-btn>
						</template>
						<span>{{ tooltipMessage }}</span>
					</v-tooltip>
				</div>
			</v-card-title>
			<v-card-text>
				<TermsTable :terms="set.terms"/>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="primary"
					text
					@click="close"
				>
					OK
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TermsTable from '@/components/TermsTable'

export default {
	name: 'AdminViewSet',
	props: ['item'],
	components: {
		TermsTable
	},
	data () {
		return {
			dialog: false,
			clipboard: false,
			tooltipShow: false,
			tooltipColor: 'success',
			tooltipMessage: 'Terms copied!'
		}
	},
	created () {
		if (navigator.clipboard) {
			this.clipboard = true
		}
	},
	computed: {
		...mapGetters({ set: 'stateSet'})
	},
	methods: {
		...mapActions(['getSet']),
		close() {
			this.dialog = false
		},
		async clickGetSet(id) {
			await this.getSet(id)
		},
		async copyTerms() {
			const terms_list = this.set.terms.map(({ term }) => term)
			const terms = terms_list.join('\n')
			if (!this.clipboard) {
				return
			} else {
				await navigator.clipboard.writeText(terms).then(() => {
					this.tooltipColor = 'success'
					this.tooltipMessage = 'Terms copied!'
					this.tooltipShow = true
					setTimeout(() => {this.tooltipShow = false}, 1000)
				}).catch(err => {
					this.tooltipColor = 'error'
					this.tooltipMessage = 'Terms not copied'
					this.tooltipShow = true
					setTimeout(() => {this.tooltipShow = false}, 1000)
					console.error(err)
				})
			}
		}
	}
}
</script>
