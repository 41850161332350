<template>
	<v-dialog
		v-model="dialog"
		width="600"
	>
		<template v-slot:activator="{ on, attrs }">
			<v-btn
				color="primary"
				:dark="!loading"
				:light="loading"
				class="mb-2"
				v-bind="attrs"
				v-on="on"
				:disabled="loading"
				@click.stop="ensureAdmin"
			>
				新しい管理者
			</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="text-h5">新しい管理者</span>
			</v-card-title>
			<v-card-text>
				<v-form ref="newUserForm">
					<v-container>
						<v-row><v-col>
							<v-text-field
								v-model="editedUser.username"
								label="ユーザー名（必須）"
								:rules="[rules.required, rules.max20chars]"
								:error-messages="errors"
								:error-count="1"
								counter
								maxlength="20"
							></v-text-field>
						</v-col></v-row>
						<v-row><v-col>
							<v-text-field
								v-model="editedUser.password"
								label="パスワード（空白にすると自動生成されます）"
								:rules="[rules.max20chars]"
								counter
								maxlength="20"
							></v-text-field>
						</v-col></v-row>
						<v-row><v-col>
							<v-text-field
								v-model="editedUser.memo"
								label="備考"
								:rules="[rules.max100chars]"
								counter
								maxlength="100"
							></v-text-field>
						</v-col></v-row>
						<v-row><v-col>
							<v-checkbox
								v-model="editedUser.is_active"
								label="有効"
							></v-checkbox>
						</v-col></v-row>
					</v-container>
				</v-form>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn
					color="blue darken-1"
					text
					@click="close"
				>
					キャンセル
				</v-btn>
				<v-btn
					color="blue darken-1"
					text
					:loading="loading"
					@click="save"
				>
					保存
				</v-btn>
			</v-card-actions>
		</v-card>
		<v-dialog
			v-model="resDialog"
			max-width="300"
			scrollable
		>
			<v-card>
				<v-card-title>新しい管理者</v-card-title>
				<v-card-text>
					{{ addedAdmin }} を追加しました。<br/><br/>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
						color="primary"
						text
						@click="closeResDialog"
					>
						OK
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'AdminNewAdmin',
	data () {
		return {
			dialog: false,
			resDialog: false,
			newUser: {
				username: '',
				password: '',
				memo: '',
				is_active: false
			},
			editedUser: {
				username: '',
				password: '',
				memo: '',
				is_active: false
			},
			rules: {
				required: value => !!value || 'Required',
				max20chars: value => value.length <= 20 || 'Max 20 characters',
				max100chars: value => value.length <= 100 || 'Max 100 characters'
			},
			errors: [],
		}
	},
	computed: {
		...mapGetters({
			loading: 'stateLoading',
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated',
			addedAdmin: 'stateAddedAdmin'
		})
	},
	watch: {
		dialog(value) {
			if (value === false) {
				this.$refs.newUserForm.resetValidation()
				this.$nextTick(() => {
					this.editedUser = Object.assign({}, this.newUser)
				})
				this.setLoading(false)
			}
			if (value === true) {
				this.setAlert(false)
				this.errors = []
			}
		},
		alert(value) {
			if (value === true) {
				setTimeout(() => this.setAlert(false), 2000)
			}
		}
	},
	methods: {
		...mapActions(['setLoading', 'setAlert', 'checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout', 'addUser', 'clearAddedAdmin']),
		close() {
			this.dialog = false
			this.setLoading(false)
		},
		async save() {
			if (this.$refs.newUserForm.validate()) {
				this.setLoading(true)
				this.username = await this.addUser(this.editedUser).then(() => {
					this.setAlert(false)
					this.close()
					this.resDialog = true
				}).catch(err => {
					this.setLoading(false)
					this.errors = [err.response.data.detail]
					this.clearAddedAdmin()
					console.error(err)
				})
			}
		},
		closeResDialog() {
			this.resDialog = false
		},
		async ensureAdmin() {
			if (this.isAdminAuthenticated) {
				await this.checkAdmin().catch(() => {
					this.adminLogout()
					this.$router.push({
						path: '/admin/login',
						query: { redirect: this.$route.fullPath }
					})
				})
			} else {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.fullPath }
				})
			}
		}
	}
}
</script>