<template>
	<v-col class="mx-auto">
		<div class="admin">
			<router-view></router-view>
		</div>
	</v-col>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
	name: 'Admin',
	created () {
		if (this.isAdminAuthenticated) {
			this.checkAdmin().catch(() => {
				this.adminLogout()
			})
		} else if (this.isPlainAdminAuthenticated) {
			this.checkPlainAdmin().catch(() => {
				this.plainAdminLogout()
			})
		}
		if (this.isAdminAuthenticated) {
			if (this.$route.name === 'Admin') {
				this.$router.push('/admin/dashboard')
			}
		} else if (this.isPlainAdminAuthenticated) {
			if (this.$route.name == 'Admin') {
				this.$router.push('/admin/dashboard')
			}
		} else {
			if (this.$route.name !== 'AdminLogin') {
				this.$router.push({
					path: '/admin/login',
					query: { redirect: this.$route.path }
				})
			}
		}
	},
	computed: {
		...mapGetters({
			isAdminAuthenticated: 'isAdminAuthenticated',
			isPlainAdminAuthenticated: 'isPlainAdminAuthenticated'
		})
	},
	methods: {
		...mapActions(['checkAdmin', 'adminLogout', 'checkPlainAdmin', 'plainAdminLogout'])
	}
}
</script>
